import {
    SHOW_PASSWORDS,
    GET_REFERENCE_DATA,
    GET_REFERENCE_DATA_LOADING,
} from '../types';

const initState = {
    referenceData: null,
    isPasswordShown: false,
    referenceLoading: false,
}

export default function reducer(state=initState, action) {
    let payload = action.payload;

    switch (action.type) {
        case GET_REFERENCE_DATA:
            return {
                ...state,
                referenceData: payload,
                referenceLoading: false,
            }

        case GET_REFERENCE_DATA_LOADING:
            return {
                ...state,
                referenceLoading: payload
            }

        case SHOW_PASSWORDS:
            return {
                ...state,
                isPasswordShown: true,
            }
    
        default:
            return state;
    }
}