import MyModal from 'components/Modal';
import React, { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { deleteProductKeyword } from 'redux/actions/studentActions';
import { getKeywordImages } from 'utils/extractProducts';
import ImageView from './ImageView';
import './styles.css';

export default function ProductKeywordsTable({
    show,
    loading,
    productKeywords,
    setProductKeywords
}) {
    const dispatch = useDispatch()
    const [img, setImage] = useState('')
    const [isModal, setModal] = useState(false)
    const {user} = useSelector(({auth}) => auth)
    const {product} = useSelector(({studentRed}) => studentRed)




    const removeItem = (id) => {
        dispatch(deleteProductKeyword(id))
    }

    const updateKeyword = (id, data) => {
        let keywords = []

        keywords = productKeywords.map((keyword, i) => {
            if(keyword.id == id) {
                return {
                    ...keyword,
                    ...data
                }
            } else {
                return keyword
            }
        })

        setProductKeywords(keywords)
    }

    return (
        <div>
              <section className="dash-content-wrapper all-pro-evaulation">
                      <div className="container">
                          <div className="card mb-5">
                              <div className="card-body p-3 p-md-4">
                                  <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                      <h2 className="mb-3 text-uppercase">keywords</h2>

                                      {
                                          (loading && !show) ?
                                              <div className="text-center">
                                                  <ScaleLoader color="#ee6734" />
                                              </div>
                                          :
                                              productKeywords.length ?
                                                  <table className="table " id="dataTable">

                                                      <thead>
                                                          <tr>
                                                          <th className="keyword-td">Available Budget</th>
                                                          <th className="keyword-td">Currecncy</th>
                                                          <th className="keyword-td">H10 Main KW SV Screenshot</th>
                                                          <th className="keyword-td">H10 Main KW SV WITH AMZ SV Screenshot</th>
                                                          <th className="keyword-td">H10 Extension Complete Screenshot</th>
                                                          <th className="keyword-td">Google sheet Screenshot that has Top 10 Seller Average Reviews, Sales,Revenue of Data Spark</th>
                                                          <th className="keyword-td">Google Trend Screenshot</th>
                                                          <th className="keyword-td"></th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          {
                                                              productKeywords.map((key, i) => (
                                                                  <tr key={i}>
                                                                     <td className="keyword-td">{key.available_budget || 'N/A'}</td>
                                                                     <td className="keyword-td">{key.currency || 'N/A'}</td>
                                                                      <td className="keyword-td">
                                                                          <ImageView
                                                                              index={8}
                                                                              id={key.id}
                                                                              setImage={setImage}
                                                                              setModal={setModal}
                                                                              updateHandler={updateKeyword}
                                                                              label='H10 Main KW SV Screenshot'
                                                                              objectKey='wm_helium10_min_kw_sv_screenshot'
                                                                              src={key.wm_helium10_min_kw_sv_screenshot}
                                                                              srcs={getKeywordImages(key)}
                                                                          />
                                                                      </td>
                                                                      <td className="keyword-td">
                                                                          <ImageView
                                                                              index={9}
                                                                              id={key.id}
                                                                              setImage={setImage}
                                                                              setModal={setModal}
                                                                              updateHandler={updateKeyword}
                                                                              label='H10 Main KW SV WITH AMZ SV Screenshot'
                                                                              objectKey='wm_helium10_main_kv_sv_with_amz_sv_screenshot'
                                                                              src={key.wm_helium10_main_kv_sv_with_amz_sv_screenshot}
                                                                              srcs={getKeywordImages(key)}
                                                                          />
                                                                      </td>

                                                                      <td className="keyword-td">
                                                                          <ImageView
                                                                              index={10}
                                                                              id={key.id}
                                                                              setImage={setImage}
                                                                              setModal={setModal}
                                                                              updateHandler={updateKeyword}
                                                                              label='H10 Extension Complete Screenshot'
                                                                              objectKey='wm_helium10_extension_complete_screenshot'
                                                                              src={key.wm_helium10_extension_complete_screenshot}
                                                                              srcs={getKeywordImages(key)}
                                                                          />
                                                                      </td>
                                                                      <td className="keyword-td">
                                                                          <ImageView
                                                                              index={11}
                                                                              id={key.id}
                                                                              setImage={setImage}
                                                                              setModal={setModal}
                                                                              updateHandler={updateKeyword}
                                                                              label='Google sheet Screenshot that has Top 10 Seller Average Reviews, Sales Revenue of Data Spark'
                                                                              objectKey='wm_average_sales_sheet'
                                                                              src={key.wm_average_sales_sheet}
                                                                              srcs={getKeywordImages(key)}
                                                                          />
                                                                      </td>
                                                                      <td className="keyword-td">
                                                                          <ImageView
                                                                              index={12}
                                                                              id={key.id}
                                                                              setImage={setImage}
                                                                              setModal={setModal}
                                                                              updateHandler={updateKeyword}
                                                                              label='Google Trend Screenshot'
                                                                              objectKey='wm_google_trend_screenshot'
                                                                              src={key.wm_google_trend_screenshot}
                                                                              srcs={getKeywordImages(key)}
                                                                          />
                                                                      </td>
                                                                      <td className="keyword-td">
                                                                          {
                                                                              show ?
                                                                                  loading ?
                                                                                      <button type="button" className="approve-btn">
                                                                                          <ScaleLoader color="#fff" height={20} />
                                                                                      </button>
                                                                                  :
                                                                                      <button type="button" className="approve-btn" onClick={()=>removeItem(key.id)}>Remove</button>
                                                                              :
                                                                                  null
                                                                          }
                                                                      </td>
                                                                  </tr>
                                                              ))
                                                          }
                                                      </tbody>
                                                  </table>
                                              :
                                                  <h5 style={{ textAlign: 'center' }}>No Keywords Found.</h5>
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                      </section>
            <MyModal show={isModal} close={()=>setModal(false)}>
                <div className='image-view'>
                    <img src={img} style={{width: '100%', height: 'auto' }} />
                </div>
            </MyModal>
        </div>
    )
}
