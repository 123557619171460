import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { ScaleLoader } from 'react-spinners'
import { login } from 'redux/actions/authActions';
import { validateEmail } from 'utils/validation';
import EMBSW from '../../assets/images/ebms-white.png'
import LOGIN_LOGO from '../../assets/images/enablers-login-logo.png'
import Message from 'components/validationMessage/Message';
import "./style.css";
import { useEffect } from 'react';

export default function Login() {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(()=>{
        if(location?.search === "?true") {
            history.push("/login")
            window.location.reload();
        }
    }, [])

    const {
        authLoading: isLoading
    } = useSelector(({ auth }) => auth)

    const formik = useFormik({
        initialValues: {
          email: '',
          password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            password: Yup.string()
            //   .min(8, 'Must be more than 8 characters')
              .required('Password is required'),
        }),
        onSubmit: values => {
            onSubmit(values)
        },
    });

    const onSubmit = ({ email, password }) => {
        if(email === '' || !validateEmail(email)) {
            toast.warn('Please enter valid email.')
        } else if(password === '') {
            toast.warn('Please enter user password.')
        } else {
            dispatch(
                login({
                    email,
                    password
                }, (role) => {
                    if(role === 'Student') {
                        history.push('/student-home')
                    } else if(role === 'Admin' || role === 'Super Admin') {
                        history.push('/admin-home')
                    } else if(role === 'Editor') {
                        history.push('/editor-home')
                    } else if(role === 'Trainer') {
                        history.push('/trainer-home')
                    }
                })
            )    
        }
    }

    return (
        <div id='login-rap'>
            <header>
                <div className="container">
                    <div className="logo-wrap py-3 cursor" onClick={()=>history.push('/')}>
                        <img src={LOGIN_LOGO} />
                    </div>
                </div>
            </header>
            <section className="main-login-wrapper vh-100 d-flex align-items-center flex-row">
                <div className="container">
                    {/* <!-- Outer Row --> */}
                    <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0">
                            <div className="card-body">
                                {/* <!-- Nested Row within Card Body --> */}
                                <div className="row">
                                <div className="col-lg-6 d-none d-flex flex-column justify-content-center pull-left  mb-4 mb-md-0">
                                    <div className="login-content ">
                                        <img src={EMBSW} />
                                        <h3>Enablers Product<br /> Approval  System</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="login-wrap p-5">
                                        <div className="pull-left">
                                            <h1 className="h4 font-weight-bold bl-text">LOGIN</h1>
                                            <hr />
                                        </div>
                                        <form className="user" onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label className="font-weight-bold bl-text">User Name:</label>
                                                <input 
                                                    id="email"
                                                    type="email"
                                                    name='email-input'
                                                    autoComplete="off"
                                                    aria-describedby="emailHelp" 
                                                    placeholder="Enter Username"
                                                    {...formik.getFieldProps('email')} 
                                                    className="form-control form-control-user rounded" 
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <Message value={formik.errors.email} />
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-bold bl-text">Password:</label>
                                                <input 
                                                    id="password" 
                                                    type="password"
                                                    autoComplete="off"
                                                    placeholder="Enter Password"
                                                    {...formik.getFieldProps('password')}  
                                                    className="form-control form-control-user rounded"
                                                />
                                                {formik.touched.password && formik.errors.password ? (
                                                    <Message value={formik.errors.password} />
                                                ) : null}
                                            </div>
                                            <div className="pb-5">
                                                <div className="form-group remember-box" >
                                                    <div className="custom-control custom-checkbox small">
                                                        {/* <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" htmlFor="customCheck">Remember Me</label> */}
                                                    </div>
                                                </div>
                                                {
                                                    isLoading ?
                                                        <button type="submit" className="btn btn-primary btn-user btn-block login-btn">
                                                            <ScaleLoader color="#fff" width={2} height={14} />
                                                        </button>
                                                    :   
                                                        <button type="submit" className="btn btn-primary btn-user btn-block login-btn">
                                                            Login
                                                        </button>
                                                }
                                            </div>
                                        </form>
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-around' }} className="actions-view">
                                            <div className="forgot-password">
                                                <a className="small custom-link" onClick={()=>history.push('/forget')}>Forgot Password?</a>
                                            </div>
                                            <div className="forgot-password">
                                                <a className="small custom-link" onClick={()=>history.push('/signup')}>New here? Register</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
