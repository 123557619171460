import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import InputPicker from 'components/Input/InputPicker'
import PickerInput from './PickerInput'
import Message from 'components/validationMessage/Message'

export default function AddProductDetails({
    formik,
    loading,
    jsScreenWS, setJSScreenWS,
    jsScreenWRS, setJSScreenWRS,
    helium10SWS, setHelium10SWS,
    cereboRKSWSVS, setCereboRKSWSVS,
    helium10MKSH, setHelium10MKSH,
    past5YGT, setPast5YGT,
    profitMargin, setProfitMargin,
    helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
    helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
    helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
    averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
    availableBudget, setAvailableBudget,

    h10MainKWSVScreenshot, setH10MainKWSVScreenshot,
    h10MainKWSVWITHAMZSVScreenshot, setH10MainKWSVWITHAMZSVScreenshot,
    h10ExtensionCompleteScreenshot, setH10ExtensionCompleteScreenshot,
    googlesheetSrcST10SellerAvgReviewsSales, setGooglesheetSrcST10SellerAvgReviewsSales,
    googleTrendScreenshot, setGoogleTrendScreenshot,

    currency, setCurrency,
    productKeywords,
    myRef,
    setSubmitted,
}) {
    const [key, setKey1] = useState(Math.random())


    return (

        <div className="main-information-wrapper">

            <section className="dash-content-wrapper pro-keywords pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase" ref={myRef}>keyword details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5 ">
                                <div className="form-row ">
                                    <div className={`form-group col-md-12 mb-4`}>
                                        <label htmlFor='keywords'>Keywords*:</label>
                                        <input
                                            id="keywords"
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('keywords')}
                                            placeholder="Enter Keywords"
                                        />
                                        {formik.touched.keywords && formik.errors.keywords ? (
                                            <Message value={formik.errors.keywords} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='jsExactSV'>JS Exact Search Volume*:</label>
                                        <input
                                            id="jsExactSV"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('jsExactSV')}
                                            placeholder="Enter JS Exact Search Volume"
                                        />
                                        {formik.touched.jsExactSV && formik.errors.jsExactSV ? (
                                            <Message value={formik.errors.jsExactSV} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='jsBroadSV'>JS Broad Search Volume*:</label>
                                        <input
                                            id="jsBroadSV"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('jsBroadSV')}
                                            placeholder="Enter JS Broad Search Volume"
                                        />
                                        {formik.touched.jsBroadSV && formik.errors.jsBroadSV ? (
                                            <Message value={formik.errors.jsBroadSV} />
                                        ) : null}
                                    </div>
                                    {/* <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='inputHelium10Exactsearch'>Helium 10 Exact Search Volume*:</label>
                                        <input
                                            id="inputHelium10Exactsearch"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('inputHelium10Exactsearch')}
                                            placeholder="Enter Helium 10 Exact Search Volume"
                                        />
                                        {formik.touched.inputHelium10Exactsearch && formik.errors.inputHelium10Exactsearch ? (
                                            <Message value={formik.errors.inputHelium10Exactsearch} />
                                        ) : null}
                                    </div> */}
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='helium10SV'>Helium 10 Search Volume*:</label>
                                        <input
                                            id="helium10SV"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('helium10SV')}
                                            placeholder="Enter Helium 10 Search Volume"
                                        />
                                        {formik.touched.helium10SV && formik.errors.helium10SV ? (
                                            <Message value={formik.errors.helium10SV} />
                                        ) : null}
                                    </div>
                                    {/* <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='jsGiveAPD'>JS Giveaways per day*:</label>
                                        <input
                                            id="jsGiveAPD"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('jsGiveAPD')}
                                            placeholder="Enter JS Giveaways per day"
                                        />
                                        {formik.touched.jsGiveAPD && formik.errors.jsGiveAPD ? (
                                            <Message value={formik.errors.jsGiveAPD} />
                                        ) : null}
                                    </div> */}
                                    {/* <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='helium10NOGA'>Helium10 no. of giveaways*:</label>
                                        <input
                                            id="helium10NOGA"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('helium10NOGA')}
                                            placeholder="Enter Helium10 no. of giveaways"
                                        />
                                        {formik.touched.helium10NOGA && formik.errors.helium10NOGA ? (
                                            <Message value={formik.errors.helium10NOGA} />
                                        ) : null}
                                    </div> */}
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='averageReviewsOfTop10'>Average Reviews of Top 10*:</label>
                                        <input
                                            id="averageReviewsOfTop10"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('averageReviewsOfTop10')}
                                            placeholder="Enter Average Reviews of Top 10"
                                        />
                                        {formik.touched.averageReviewsOfTop10 && formik.errors.averageReviewsOfTop10 ? (
                                            <Message value={formik.errors.averageReviewsOfTop10} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='averageSellingPriceOfTop10'>Average Selling Price of Top 10*:</label>
                                        <input
                                            id="averageSellingPriceOfTop10"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('averageSellingPriceOfTop10')}
                                            placeholder="Average Selling Price of Top 10"
                                        />
                                        {formik.touched.averageSellingPriceOfTop10 && formik.errors.averageSellingPriceOfTop10 ? (
                                            <Message value={formik.errors.averageSellingPriceOfTop10} />
                                        ) : null}
                                    </div>

                                    <InputPicker
                                        key={key+Math.random()}
                                        id="jsScreenWS"
                                        label="JS Screenshot without sorting"
                                        setValue={setJSScreenWS}
                                        name={jsScreenWS ? jsScreenWS.name : 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="jsScreenWRS"
                                        label="JS screenshot with revenue sorting"
                                        setValue={setJSScreenWRS}
                                        name={jsScreenWRS ? jsScreenWRS.name : 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10SWS"
                                        label="Helium 10 Screenshot without sorting"
                                        setValue={setHelium10SWS}
                                        name={helium10SWS ? helium10SWS.name : 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10ExtScreenshotWithRevenueSorting"
                                        setValue={setHelium10ExtScreenshotWithRevenueSorting}
                                        label="Helium 10 Ext Screenshot with revenue sorting"
                                        name={helium10ExtScreenshotWithRevenueSorting?.name || 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10MagnetSearchVolumeHistory1Year"
                                        label="Helium 10 Magnet Search Volume History 1 Year"
                                        setValue={setHelium10MagnetSearchVolumeHistory1Year}
                                        name={helium10MagnetSearchVolumeHistory1Year?.name || 'Choose file'}
                                    />
                                    {/* <InputPicker
                                        key={key+Math.random()}
                                        id="helium10MagnetSearchVolumeHistoryAllTime"
                                        label="Helium 10 Magnet Search Volume History All Time*"
                                        setValue={setHelium10MagnetSearchVolumeHistoryAllTime}
                                        name={helium10MagnetSearchVolumeHistoryAllTime?.name || 'Choose file'}
                                    /> */}
                                    {/* <InputPicker
                                        key={key+Math.random()}
                                        id="inputGroupFile05"
                                        label="Cerebo relevant keywords screenshot with search volume sorting*"
                                        setValue={setCereboRKSWSVS}
                                        name={cereboRKSWSVS ? cereboRKSWSVS.name : 'Choose file'}
                                    /> */}
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10MKSH"
                                        setValue={setHelium10MKSH}
                                        label="Helium 10 main keyword search history screenshots"
                                        name={helium10MKSH ? helium10MKSH.name : 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="past5YGT"
                                        setValue={setPast5YGT}
                                        label="Past 5 years google trends"
                                        name={past5YGT ? past5YGT.name : 'Choose file'}
                                    />
                                    {/* <InputPicker
                                        key={key+Math.random()}
                                        id="averageReviewsOfTop10"
                                        label="Average Reviews of Top 10*"
                                        setValue={setAverageReviewsOfTop10SS}
                                        name={averageReviewsOfTop10SS?.name || 'Choose file'}
                                    /> */}
                                    <PickerInput
                                        formik={formik}
                                        type="number"
                                        option={currency}
                                        setOption={setCurrency}
                                        options={["USD", "UK"]}
                                        label="Budget Available*"
                                        id="availableBudget"
                                        value={availableBudget}
                                        onChange={setAvailableBudget}
                                        placeholder="Enter Available Budget"
                                    />
                                    <PickerInput
                                        symbol="%"
                                        formik={formik}
                                        type="number"
                                        label="Profit Margin*"
                                        id="profitMargin"
                                        value={profitMargin}
                                        onChange={setProfitMargin}
                                        placeholder="Enter Profit Margin"
                                    />
                                </div>
                                <div className="pt-5 text-center">
                                    {
                                        loading ?
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-org btn-lg text-uppercase">
                                                    <ScaleLoader color="#fff" height={20} />
                                                </button>
                                            </span>
                                        :
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" onClick={()=>{
                                                    setSubmitted(Math.random)
                                                    setKey1(Math.random())
                                                    formik.handleSubmit()
                                                }}>
                                                    { productKeywords?.length ?
                                                        'Add More'
                                                    :
                                                        'Add'
                                                    }
                                                </button>
                                            </span>
                                    }
                                </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
