import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { ScaleLoader } from 'react-spinners'
import DropMenu from "components/dropMenu";
import MENU_IMG from 'assets/images/menu.png';
import MaterialTable from 'components/materialTable'
import { useDispatch, useSelector } from 'react-redux'
import { updateStatus } from 'redux/actions/trainerActions'
import { deleteUser } from 'redux/actions/adminActions';
import BreadCrumbs from './BreadCrumbs';
import { useState, useRef } from 'react';
import MyModal from 'components/Modal';
import StudentDetails from 'components/StudentDetails'
import { changeAllPendingStatus } from 'redux/actions/trainerActions'
import { toast } from 'react-toastify';
import DeactivationReason from 'components/deactivationReason';
import StudentFilterView from 'components/studentFilterView';

let data = null;

export default function ViewStudents() {
    const childRef = useRef();
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false)
    const [student, setStudent] = useState(null)
    const [selected, setSelected] = useState([])
    const [isReasonModal, setReasonModal] = useState(false)
    const [isDeleteReason, setDeleteReason] = useState(false)
    const [reason, setReason] = useState('')
    const {loading} = useSelector(({trainerRed})=>trainerRed)
    const {loading: deleteLoading} = useSelector(({adminRed})=>adminRed)

    const [filter, setFilter] = useState({
        trainer: '',
        training: '',
        batch: '',
        status: ''
    })

    const updateHandler = (id, refresh) => {
        dispatch(updateStatus({
            user_id: id,
            role: 'student'
        }))
        refresh()
    }

    const viewDetails = (student) => {
        // history.push('/student-details', {...student, isTrainer: true,})
        setStudent(student);
        setModal(true);
    }

    const actionView = (student, refresh) => {
        return(
            <DropMenu
                button={<img src={MENU_IMG} className="cursor" />}
                options={
                    student.status === 'active' ?
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Deactivate', onClick: ()=>updateHandler(student.id, refresh, 'expired') },
                            { title: 'Delete', onClick: ()=>{data={student, refresh}; setDeleteReason(true)}},
                        ]
                    : student.status === 'expired' ?
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Activate', onClick: ()=>updateHandler(student.id, refresh, 'active') },
                            { title: 'Delete', onClick: ()=>{data={student, refresh}; setDeleteReason(true)}},
                        ]
                    :
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Activate', onClick: ()=>updateHandler(student.id, refresh, 'active') },
                            { title: 'Reject', onClick: ()=>updateHandler(student.id, refresh, 'reject') },
                            { title: 'Delete', onClick: ()=>{data={student, refresh}; setDeleteReason(true)}},
                        ]
                }
            />
        )
    }

    const pushOne = (id) => {
        let stds = [...selected]
        stds.push(id)
        setSelected(stds)
    }

    const popOne = (id) => {
        let stds = [...selected]
        stds = stds.filter(s => s != id)
        setSelected(stds)
    }

    const popAll = () => {
        setSelected([])
    }

    const selectOne = (id, student) => {
        if(selected.length) {
            if(selected.includes(id)) {
                popOne(id)
            } else {
                if(student.status === 'active') {
                    pushOne(id)
                } else {
                    toast.warn('Please select only active students')
                }
            }
        } else {
            if(student.status === 'active') {
                pushOne(id)
            } else {
                toast.warn('Please select only active students')
            }
        }
    }

    const selectAll = (checked, students) => {
        if(!checked) {
            setSelected([])
        } else {
            let stds = students ? students.filter(s => s.status == 'active') : [];
            stds = stds ? stds.map(s => s.id) : []
            stds = [...stds]

            if(stds.length)
                setSelected(stds)
            else
            toast.warn('Please select at least one active student.')
        }
    }

    const changeStatus = () => {
        if(selected.length) {
            setReasonModal(true)
        } else {
            toast.warn('Please select at least one active student.')
        }
    }

    const deactivateHandler = () => {
        if(reason === '') {
            toast.warn('Please specify reason.')
        } else {
            dispatch(changeAllPendingStatus(
                selected,
                'expired',
                reason,
                () => {
                    setReasonModal(false)
                    setReason('')
                    childRef.current.refreshData()
                    popAll()
                }
            ))
        }
    }

    const deleteHandler = () => {
        if(reason === '') {
            toast.warn('Please specify reason.')
        } else {
            dispatch(
                deleteUser(
                    {
                        id: data?.student?.id || '',
                        comments: reason
                    },
                    () => {
                        data?.refresh()
                        setDeleteReason(false)
                        setReason('')
                    }
                )
            )
        }
    }

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs />

                        <div className="pt-3" />

                        <StudentFilterView
                            hideTrainer
                            filter={filter}
                            setFilter={setFilter}
                            applyFilter={()=>childRef.current.refreshData()}
                        />

                        <div className='row'>
                            <h3 className="mb-0 text-uppercase text-white pb-4 col-sm-6">All Students</h3>
                            <div className="col-sm-6" align='right'>
                                {
                                    selected.length ?
                                        loading ?
                                            <button
                                                type="button"
                                                onClick={()=>{}}
                                                disabled
                                                style={{ marginBottom: 13 }}
                                                className="btn-org text-uppercase"
                                            >
                                                <ScaleLoader color="white" height={15} />
                                            </button>
                                        :
                                            <button
                                                type="button"
                                                onClick={changeStatus}
                                                style={{ marginBottom: 13 }}
                                                className="btn-org text-uppercase"
                                            >
                                                Deactivate
                                            </button>
                                    :
                                        <button
                                            type="button"
                                            onClick={()=>toast.warn('Please select student(s) first.')}
                                            style={{ marginBottom: 13 }}
                                            className="btn-org text-uppercase"
                                        >
                                            Deactivate
                                        </button>
                                }
                            </div>
                        </div>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <MaterialTable
                                    method='post'
                                    ref={childRef}
                                    filter={filter}
                                    loading={loading || deleteLoading}
                                    selected={selected}
                                    handleOneCheck={selectOne}
                                    placeholder="Search by Name, Email, CNIC"
                                    url={process.env.REACT_APP_ENDPOINT+'/api/user/Student'}
                                    handleCheckChange={(is, students)=>selectAll(is, students)}
                                    columns={[
                                        { id: 'checkbox', checkbox: true, label: '', maxWidth: 30 },
                                        { id: 'name', label: 'Name', maxWidth: 80, searchable: true, sortable: true, index: 0 },
                                        { id: 'email', label: 'Email', maxWidth: 150, searchable: true, sortable: true, index: 1 },
                                        // { id: 'phone', label: 'Phone', minWidth: 150, searchable: true, sortable: true, index: 2 },
                                        { id: 'cnic', label: 'CNIC', minWidth: 150, searchable: true, sortable: true, index: 2 },
                                        // { id: 'address', label: 'Address', minWidth: 150, searchable: true, sortable: true, index: 4 },
                                        // { id: 'cnic_front_image', image: true, label: 'CNIC Front', width: 100 },
                                        // { id: 'cnic_back_image', image: true, label: 'CNIC Back', width: 100 },
                                        { id: 'training_name', name: 'trainings.name', label: 'Training', minWidth: 100, searchable: true, index: 3 },
                                        { id: 'batch_name', name: 'batches.name', label: 'Batch', minWidth: 100, searchable: true, sortable: true, index: 4 },
                                        // { id: 'password_s_t_r', label: 'Password', minWidth: 100 },
                                        { id: 'status', label: 'Status', minWidth: 100, searchable: true, index: 5, sortable: true },
                                        { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />

            <MyModal fullWidth show={isModal} close={()=>{ setModal(false); setStudent(null); }}>
                <StudentDetails student={student} />
            </MyModal>


            <MyModal show={isReasonModal} close={()=>{ setReasonModal(false); setReason(''); }}>
                <DeactivationReason
                    reason={reason}
                    loading={loading}
                    setReason={setReason}
                    submitHandler={deactivateHandler}
                />
            </MyModal>

            <MyModal show={isDeleteReason} close={()=>{ setDeleteReason(false); setReason(''); }}>
                <DeactivationReason
                    reason={reason}
                    loading={deleteLoading}
                    setReason={setReason}
                    submitHandler={deleteHandler}
                />
            </MyModal>
        </div>
    )
}
