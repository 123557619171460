import {
    TRAINER_LOADING,
    TRAINER_ADD_STUDENT,
    TRAINER_ADD_COMMENTS, 
    TRAINER_MORE_LOADING,
    TRAINER_UPDATE_STATUS,
    TRAINER_GET_ALL_STUDENTS,
    TRAINER_GET_ALL_PRODUCTS,
    TRAINER_GET_MORE_PRODUCTS,
    TRAINER_GET_MORE_STUDENTS,
    TRAINER_GET_PENDINGS_COUNT,
    CHANGE_TRAINER_ALL_STATUSES,
    TRAINER_GET_PENDING_STUDENTS,
    TRAINER_GET_PENDING_PRODUCTS, 
    TRAINER_UPDATE_STUDENT_STATUS,
    TRAINER_GET_MORE_PENDING_PRODUCTS,
    TRAINER_GET_MORE_PENDING_STUDENTS,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { unauthenticationHandler } from './authActions';

export const getPendingsCount = (cb) => async (dispatch) => {
    try {
            dispatch({ type: TRAINER_LOADING, payload: true });
            
            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/total-pending-products`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: TRAINER_GET_PENDINGS_COUNT,
                        payload: {
                            productsTotal: response.data.pending_products_count ? response.data.pending_products_count : 0,
                            studentsTotal: response.data.pending_students_count ? response.data.pending_students_count : 0
                        }
                    });
    
                    cb && cb();
                } else {
                    alert(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: TRAINER_LOADING, payload: false });
                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: TRAINER_LOADING, payload: false });
                }
            })
        
    } catch (e) {
        dispatch({ type: TRAINER_LOADING, payload: false });
    }
};

export const addStudent = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: TRAINER_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-student`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: TRAINER_ADD_STUDENT,
                        payload: response.data.student,
                    });

                    toast.success('Student added successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: TRAINER_LOADING, payload: false });

                }
            }).catch(error => {
                toast.error(
                    (error.response && error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email.length) ? 
                            error.response.data.errors.email[0] 
                        : 
                            'Something went wrong!'
                )
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: TRAINER_LOADING, payload: false });
                }
            })
        
    } catch (e) {
        toast.error(
            e.message ? 
                e.message 
            : 
                'Something went wrong!'
        )
        dispatch({ type: TRAINER_LOADING, payload: false });
    }
};

export const updateStatus = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: TRAINER_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/update-status`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data: { user_id: data.user_id, status: data.status }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    if(data.role === 'trainer') {
                        dispatch({
                            type: TRAINER_UPDATE_STATUS,
                            payload: { id: data.user_id, status: response.data.status},
                        });
                    } else if(data.role === 'student') {
                        dispatch({
                            type: TRAINER_UPDATE_STUDENT_STATUS,
                            payload: { id: data.user_id, status: response.data.status},
                        });
                    }

                    toast.success('Status updated successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: TRAINER_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: TRAINER_LOADING, payload: false });
                }
            })
        
    } catch (e) {
        dispatch({ type: TRAINER_LOADING, payload: false });
    }
};

export const addComments = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: TRAINER_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'put',
                url: `${process.env.REACT_APP_ENDPOINT}/api/product/${data.id}`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data: { message: data.message, status: data.status }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: TRAINER_ADD_COMMENTS,
                        payload: { product_id: data.id, id: data.user_id, status: response.data.status},
                    });

                    toast.success('Product status updated successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: TRAINER_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: TRAINER_LOADING, payload: false });
                }
            })
        
    } catch (e) {
        dispatch({ type: TRAINER_LOADING, payload: false });
    }
};

export const changeAllPendingStatus = (data, status, deactivation_reason, cb) => async (dispatch) => {
    try {
            dispatch({ type: TRAINER_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/update-statuses`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data: { user_ids: data, status, deactivation_reason }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: CHANGE_TRAINER_ALL_STATUSES,
                        payload: data,
                    });

                    toast.success('Statuses updated successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: TRAINER_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: TRAINER_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.message) ? 
                            error.response.data.message 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: TRAINER_LOADING, payload: false });
    }
};