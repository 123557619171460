import ImageUploadModal from 'components/imageUploadModal'
import { useSelector } from 'react-redux';
import Lightbox from "react-awesome-lightbox"
import { useState } from 'react';

export default function ImageView({ src, srcs, endURL, objectKey, label, setImage, index, setModal, absolutePath, id, updateHandler }) {
    const { role } = useSelector(({ auth }) => auth)
    const [img, setImg] = useState(false)
    
    return (
        src && src !== "" ?
            img ?
                srcs?.length ?
                    <Lightbox onClose={()=>setImg(false)} images={[...srcs.slice(index), ...srcs.slice(0, index)]} />
                :
                    <Lightbox onClose={()=>setImg(false)} image={absolutePath ? src : `${process.env.REACT_APP_IMAGE_BASE_URL}/${src}`} />
            :
                <img
                    className="keyword-img cursor"
                    src={absolutePath ? src : `${process.env.REACT_APP_IMAGE_BASE_URL}/${src}`}
                    onClick={()=>{setImage(`${process.env.REACT_APP_IMAGE_BASE_URL}/${src}`);setImg(true)}}
                />
        :
            role === "Student" ?
                <ImageUploadModal 
                    id={id}
                    label={label}
                    endURL={endURL}
                    objectKey={objectKey} 
                    updateHandler={updateHandler} 
                />    
            :
                <i className="far fa-image fa-5x"></i>
    )
}
