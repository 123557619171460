import {
    EDITOR_LOADING,
    GET_STUDENT_EVS,
    EDIT_STUDENT_EVS,
    EDITOR_MORE_LOADING,
    GET_EDITOR_STUDENTS,
    GET_EDITOR_MORE_STUDENTS,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { unauthenticationHandler } from './authActions';

export const editStudentEVS = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: EDITOR_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/update-evs-detail`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: EDIT_STUDENT_EVS,
                        payload: data
                    });

                    toast.success('Student EVS Details updated successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: EDITOR_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: EDITOR_LOADING, payload: false });
                }
            })
        
    } catch (e) {
        dispatch({ type: EDITOR_LOADING, payload: false });
    }
};

export const getStudentEVS = (id, cb) => async (dispatch) => {
    try {
            dispatch({ type: EDITOR_MORE_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/evs-detail/${id}`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: GET_STUDENT_EVS,
                        payload: response?.data?.data
                    });
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: EDITOR_MORE_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: EDITOR_MORE_LOADING, payload: false });
                }
            })
        
    } catch (e) {
        dispatch({ type: EDITOR_MORE_LOADING, payload: false });
    }
};