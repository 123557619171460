import React from 'react'

export default function RadioGroup({ label, setValue }) {
    return (
        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
            <label className="flex-base">{label}</label>
            <div className="d-flex flex-row flex-base justify-content-between">
                <div className="form-check d-flex flex-row align-items-center">
                    <input className="form-check-input" type="radio" name={label} id={label} value="yes" onChange={e=>setValue(e.target.value)} />
                    <label className="form-check-label" htmlFor={label}>
                        Yes
                    </label>
                </div>
                <div className="form-check d-flex flex-row align-items-center">
                    <input className="form-check-input" type="radio" name={label} id={label+'no'} value="no" onChange={e=>setValue(e.target.value)} />
                    <label className="form-check-label" htmlFor={label+'no'}>
                        No
                    </label>
                </div>
            </div>
        </div>
    )
}
