import {
    LOGIN,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    LOGIN_LOADING_ON,
    LOGIN_LOADING_OFF,
    GET_CURRENT_USER,
    CHANGE_PASSWORD_LOADING,
    GET_CURRENT_USER_LOADING_ON,
    GET_CURRENT_USER_LOADING_OFF,
    LOGOUT
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getReferenceData } from './utilActions';

export const login = (data, cb) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_LOADING_ON });

        axios.post(
            process.env.REACT_APP_ENDPOINT+"/api/login",
            data
        )
        .then(response => {
            if(response.status === 200)
            {
                let token = response.data.token;
                localStorage.setItem('@token', token);

                dispatch({
                    type: LOGIN,
                    payload: {
                        user: response.data.user,
                        role: response.data.role
                    },
                });

                dispatch(getReferenceData());

                cb && cb(response.data.role);
            }
            else {
                toast.error( response.data.message || 'Something went wrong!');
                dispatch({ type: LOGIN_LOADING_OFF });
            }
        })
        .catch(error => {
            toast.error(
                (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error && error.response.data.errors.error.length) ?
                        error.response.data.errors.error[0]
                    :
                        (error.message || 'Something went wrong!')
            )
            dispatch({
                type: LOGIN_LOADING_OFF,
            });
        })

    } catch (e) {
        dispatch({ type: LOGIN_LOADING_OFF });
        toast.error('Error: ' + e.message)
    }
};

export const getCurrentUser = (cb) => async (dispatch) => {
    try {
        dispatch({ type: GET_CURRENT_USER_LOADING_ON });

        let token = localStorage.getItem('@token', '');

        if(token && token !== "") {

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/user`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: GET_CURRENT_USER,
                        payload: {
                            user: response.data.user,
                            role: response.data.role
                        },
                    });

                    dispatch(getReferenceData())

                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({
                        type: GET_CURRENT_USER_LOADING_OFF,
                    });
                    cb && cb();
                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    localStorage.clear()
                    dispatch({
                        type: GET_CURRENT_USER_LOADING_OFF,
                    });
                    cb && cb();
                } else {
                    dispatch({
                        type: GET_CURRENT_USER_LOADING_OFF,
                    });
                    cb && cb();
                }

            })
        } else {
            dispatch({ type: GET_CURRENT_USER_LOADING_OFF });
            cb && cb();
        }
    } catch (e) {
        dispatch({ type: GET_CURRENT_USER_LOADING_OFF });
        toast.error('Error: ' + e.message)
    }
};

export const logout = (cb) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_LOADING_ON });

        dispatch({
            type: LOGOUT
        });
        dispatch({ type: LOGIN_LOADING_OFF });

        cb && cb();

    } catch (e) {
        dispatch({ type: LOGIN_LOADING_OFF });
    }
};

export const unauthenticationHandler = () => (dispatch) => {
    dispatch({
        type: LOGOUT
    })
}

export const changePassword = (data, cb) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_PASSWORD_LOADING, payload: true });

        let token = localStorage.getItem('@token', '');

        if(token && token !== "") {

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/reset-password`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: CHANGE_PASSWORD,
                        payload: data.password,
                    });

                    toast.success('Password updated successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({
                        type: CHANGE_PASSWORD_LOADING,
                        payload: false
                    });
                }
            }).catch(error => {
                if(error.response && error.response.data && error.response.data.message) {
                    toast.error('Error: ' + error.response.data.message)
                } else {
                    toast.error('Error: ' + error.message)
                }

                dispatch({
                    type: CHANGE_PASSWORD_LOADING,
                    payload: false
                });
            })
        } else {
            dispatch({ type: CHANGE_PASSWORD_LOADING, payload: false });
        }

    } catch (e) {
        dispatch({ type: CHANGE_PASSWORD_LOADING, payload: false });
        toast.error('Error: ' + e.message)
    }
};

export const forgotPassword = (data, cb) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_LOADING_ON });

        axios.post(
            process.env.REACT_APP_ENDPOINT+"/api/login",
            data
        ).then(response => {

            if(response.status === 200) {
                let token = response.data.token;
                localStorage.setItem('@token', token);

                dispatch({
                    type: FORGOT_PASSWORD
                });

                cb && cb();
            } else {
                toast.error(response.data.message || 'Something went wrong!')

                dispatch({
                    type: LOGIN_LOADING_OFF,
                });
            }
        }).catch(error => {
            if(error.response && error.response.data && error.response.data.message) {
                toast.error('Error: ' + error.response.data.message)
            } else {
                toast.error('Error: ' + error.message)
            }

            dispatch({
                type: LOGIN_LOADING_OFF,
            });
        })

    } catch (e) {
        dispatch({ type: LOGIN_LOADING_OFF });
        toast.error('Error: ' + e.message)
    }
};
