import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import Input from 'components/Input'
import Memoized from './Memoized'
import InputPicker from 'components/Input/InputPicker'
import PickerInput from './PickerInput'
import Message from 'components/validationMessage/Message'

export default function AddProductDetails({
    formik,
    loading,

    keywords, setKeywords,
    competingProducts, setCompetingProducts,
    egrowSV, setEgrowSV,
    avgReviewsOfTop10, setAvgReviewsOfTop10,
    avgSellingPriceOfTop10, setAvgSellingPriceOfTop10,
    profitMargin, setProfitMargin,
    availableBudget, setAvailableBudget,
    currency, setCurrency,
    egrowRevenueSorted, setEgrowRevenueSorted,
    egrowRevenueUnSorted, setEgrowRevenueUnSorted,
    avgMonthlyRevenueOfTop10, setAvgMonthlyRevenueOfTop10,
    sixtyPercentOfTheTotalMonthlyRevenueOfTop10, setsixtyPercentOfTheTotalMonthlyRevenueOfTop10,

    setSubmitted,
}) {
    const [key, setKey1] = useState(Math.random())


    return (
        <div className="main-information-wrapper">
            <section className="dash-content-wrapper pro-keywords pt-5 pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase">keyword details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5 ">
                            {/* <form> */}
                                <div className="form-row ">
                                    <div className={`form-group col-md-12 mb-4`}>
                                        <label htmlFor='keywords'>Keyword*:</label>
                                        <input
                                            id="keywords"
                                            type="text"
                                            // maxLength={60}
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('keywords')}
                                            placeholder="Enter Keywords"
                                        />
                                        {formik.touched.keywords && formik.errors.keywords ? (
                                            <Message value={formik.errors.keywords} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='competingProducts'>Competing Products*:</label>
                                        <input
                                            id="competingProducts"
                                            type="text"
                                            // maxLength={60}
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('competingProducts')}
                                            placeholder="Enter Competing Products"
                                        />
                                        {formik.touched.competingProducts && formik.errors.competingProducts ? (
                                            <Message value={formik.errors.competingProducts} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='egrowSV'>Egrow SV*:</label>
                                        <input
                                            id="egrowSV"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('egrowSV')}
                                            placeholder="Enter Egrow SV"
                                        />
                                        {formik.touched.egrowSV && formik.errors.egrowSV ? (
                                            <Message value={formik.errors.egrowSV} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='avgReviewsOfTop10'>Average Reviews of Top 10*:</label>
                                        <input
                                            id="avgReviewsOfTop10"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('avgReviewsOfTop10')}
                                            placeholder="Enter Average Reviews of Top 10"
                                        />
                                        {formik.touched.avgReviewsOfTop10 && formik.errors.avgReviewsOfTop10 ? (
                                            <Message value={formik.errors.avgReviewsOfTop10} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='avgSellingPriceOfTop10'>Average Selling Price of Top 10*:</label>
                                        <input
                                            id="avgSellingPriceOfTop10"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('avgSellingPriceOfTop10')}
                                            placeholder="Average Selling Price of Top 10"
                                        />
                                        {formik.touched.avgSellingPriceOfTop10 && formik.errors.avgSellingPriceOfTop10 ? (
                                            <Message value={formik.errors.avgSellingPriceOfTop10} />
                                        ) : null}
                                    </div>
                                    <PickerInput
                                        formik={formik}
                                        type="number"
                                        option={currency}
                                        setOption={setCurrency}
                                        options={["USD", "UK"]}
                                        label="Available Budget*"
                                        id="availableBudget"
                                        value={availableBudget}
                                        onChange={setAvailableBudget}
                                        placeholder="Enter Available Budget"
                                    />
                                    {/* Added 9 */}
                                    <PickerInput
                                        symbol="%"
                                        formik={formik}
                                        type="number"
                                        label="Profit Margin*"
                                        id="profitMargin"
                                        value={profitMargin}
                                        onChange={setProfitMargin}
                                        placeholder="Enter Profit Margin"
                                    />

                                    <InputPicker
                                        key={key+Math.random()}
                                        id="egrowRevenueSorted"
                                        label="Egrow Revenue Sorted"
                                        setValue={setEgrowRevenueSorted}
                                        name={egrowRevenueSorted ? egrowRevenueSorted.name : 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="egrowRevenueUnSorted"
                                        label="Egrow Revenue UnSorted"
                                        setValue={setEgrowRevenueUnSorted}
                                        name={egrowRevenueUnSorted ? egrowRevenueUnSorted.name : 'Choose file'}
                                    />

                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='avgMonthlyRevenueOfTop10'>Average Monthly Revenue of Top 10*:</label>
                                        <input
                                            id="avgMonthlyRevenueOfTop10"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('avgMonthlyRevenueOfTop10')}
                                            placeholder="Average Monthly Revenue of Top 10"
                                        />
                                        {formik.touched.avgMonthlyRevenueOfTop10 && formik.errors.avgMonthlyRevenueOfTop10 ? (
                                            <Message value={formik.errors.avgMonthlyRevenueOfTop10} />
                                        ) : null}
                                    </div>
                                    <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='sixtyPercentOfTheTotalMonthlyRevenueOfTop10'>60% of the Total Monthly Revenue of Top 10*:</label>
                                        <input
                                            id="sixtyPercentOfTheTotalMonthlyRevenueOfTop10"
                                            type="number"
                                            autoComplete="off"
                                            className="form-control"
                                            {...formik.getFieldProps('sixtyPercentOfTheTotalMonthlyRevenueOfTop10')}
                                            placeholder="60% of the Total Monthly Revenue of Top 10"
                                        />
                                        {formik.touched.sixtyPercentOfTheTotalMonthlyRevenueOfTop10 && formik.errors.sixtyPercentOfTheTotalMonthlyRevenueOfTop10 ? (
                                            <Message value={formik.errors.sixtyPercentOfTheTotalMonthlyRevenueOfTop10} />
                                        ) : null}
                                    </div>


                                </div>
                                <div className="pt-5 text-center">
                                    {
                                        loading ?
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-org btn-lg text-uppercase">
                                                    <ScaleLoader color="#fff" height={20} />
                                                </button>
                                            </span>
                                        :
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" onClick={()=>{
                                                    setSubmitted(Math.random())
                                                    setKey1(Math.random())
                                                    formik.handleSubmit()
                                                }}>Add</button>
                                                {/* {
                                                    productKeywords.length ?
                                                        <button type="button" className="btn btn-org btn-lg text-uppercase" onClick={doneHandler}>Save</button>
                                                    :
                                                        <label>Please add at least one item to save</label>
                                                } */}
                                            </span>
                                    }
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
