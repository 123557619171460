import MaterialTable from 'components/materialTable'
import Footer from 'components/footer'
import NavBar from 'components/navbar'
import BreadCrumbs from './BreadCrumbs'

export default function ViewBatches() {
    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs />
                        <h3 className="mb-0 text-uppercase text-white pb-4">All Batches</h3>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <MaterialTable
                                    method='get'
                                    url={process.env.REACT_APP_ENDPOINT+'/api/batches'}
                                    columns={[
                                        { id: 'name', label: 'Name', minWidth: 100, searchable: true, sortable: true, index: 0 },
                                        { id: 'description', label: 'description', minWidth: 150, searchable: true, sortable: true, index: 1 },
                                        { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: true, index: 2 },
                                        { id: 'created_at', date: true, label: 'Created At', minWidth: 100, searchable: true, sortable: true, index: 3 },
                                        // { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />
        </div>
    )
}
