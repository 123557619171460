import React from 'react'
import Input from './Input'

export default function Profit({ market, margin, profit, sellingPrice, productCost, fbaFee, shippingCost, bundlingCost, vat }) {
    let price = sellingPrice ? parseFloat(sellingPrice) : 0;
    let cost = productCost ? parseFloat(productCost) : 0;
    let fee = fbaFee ? parseFloat(fbaFee) : 0;
    let shipping = shippingCost ? parseFloat(shippingCost) : 0;
    let bundling = bundlingCost ? parseFloat(bundlingCost) : 0;
    let vTax = vat ? parseFloat(vat) : 0;

    let totalCost = cost + fee + shipping + bundling;
    if(market === 'uk')
        totalCost += vTax;
        
    let revenue = price-totalCost;

    return (
        <div className="row" style={{ width: '100%', marginLeft: 'auto' }}>

            <div className="col-md-5 mr-auto">
                <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                    <label className="flex-base">Your Profit Margin</label>
                    <Input
                        disabled
                        helper={'%'}
                        setValue={() => { }}
                        value={margin.toFixed(2)}
                        placeholder='Profit Margin'
                        error={(margin < 10.0) ? `Profit Margin must be at least 10%.` : null} 
                    />
                </div>
            </div>
            <div className="col-md-5 ml-auto">
                <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                    <label className="flex-base">Your Profit is</label>
                    <Input
                        disabled
                        value={revenue}
                        setValue={() => { }}
                        placeholder='Profit'
                        helper={market === 'us' ? '$' : '£'}
                    />
                </div>
            </div>
        </div>
    )
}
