export const extractProducts = (products) => {
    if(products) {
        let ps = []
        products.map(k => {
            // if(!isNaN(k)) {
                if(k.products.length) {
                    ps.push(k)
                }
            // }
        })

        return ps;
    } else {
        return [];
    }
}

export const productsGroupbyStatus = (products, id) => {
    let count = {
        Pending: 0,
        Submitted: 0,
        'Approved by trainer': 0,
        'Reject by trainer': 0,
        'Approved by admin': 0,
        'Reject by admin': 0
    }

    let newProducts = [...products];
    if(id) {
        newProducts = products.filter(p => p.id == id);
    }

    newProducts.map(product => {
        count[product['status']]++;
    })

    return (
        <>
            <strong>Total Products: </strong>
            {count["Submitted"] + count["Approved by trainer"] + count["Reject by trainer"] + count["Approved by admin"] + count["Reject by admin"]}

            <br/><br/><strong>Approved: </strong>
            {count["Approved by admin"]}

            <br/><br/><strong>Pending: </strong>
            {count["Submitted"] + count["Approved by trainer"]}

            <br /><br /><strong>Rejected: </strong>
            {count["Reject by trainer"] + count["Reject by admin"]}
        </>
    )
}

export const evaluateProduct = (
    above50Reviews,
    bsrStable,
    priceStable,
    fbmControl,
    brandRestrict,
    buyBox,
    fragileItem,
    hazmatProduct,
    onlyBrandSeller,
    salesVolume,
    noCompSellers,
    bsr
) => {
    let p = 0;
    let t1=10, t2=6, t3=3;

    if(bsr >= 1 && bsr < 20000) {
        p += 10;
        t1 = 8; t2 = 6; t3 = 3;
    } else if(bsr >= 20000 && bsr < 40000) {
        p += 5;
        t1 = 9; t2 = 6; t3 = 3;
    } else if(bsr >= 40000 && bsr < 60000) {
        p += 3;
        t1 = 9; t2 = 6; t3 = 4;
    }

    if(above50Reviews === 'yes')
        p += t2;

    if(bsrStable === 'yes')
        p += t2;

    if(priceStable === 'yes')
        p += t3;

    if(fbmControl === 'no')
        p += t1;

    if(brandRestrict === 'no')
        p += t3;

    if(salesVolume >= 5000)
        p += t2;

    if(noCompSellers >= 2)
        p += t2;

    if(buyBox === 'no')
        p += t1;

    if(fragileItem === 'no')
        p += t1;

    if(hazmatProduct === 'no')
        p += t1;

    if(onlyBrandSeller === 'no')
        p += t1;

    return p;
}

export const checkKeywordImages = (keyword,user ,cb) => {

    if(user.training_slug == 'become-a-seller-on-walmart-pl-ds-wholesale'){
        if(keyword) {
            if(!keyword?.wm_helium10_min_kw_sv_screenshot) {
                cb(true)
                return
            } if(!keyword?.wm_helium10_main_kv_sv_with_amz_sv_screenshot) {
                cb(true)
                return
            } if(!keyword?.wm_helium10_extension_complete_screenshot) {
                cb(true)
                return
            } if(!keyword?.wm_average_sales_sheet) {
                cb(true)
                return
            }
            if(!keyword?.wm_google_trend_screenshot) {
                cb(true)
                return
            }
            cb(false)
        }
    }
    else if(user.training_slug == 'become-a-seller-on-amazon-gulf-ksa-uae-noon-marketplaces'){
        if(keyword) {
            if(!keyword?.egrow_revenue_sorted) {
                cb(true)
                return
            }
            if(!keyword?.egrow_revenue_unsorted) {
                cb(true)
                return
            }
            cb(false)
        }
    }
    else{
        if(keyword) {
            if(!keyword?.google_trend_past_5_years) {
                cb(true)
                return
            }
            if(!keyword?.helium10_main_keyword) {
                cb(true)
                return
            }
            if(!keyword?.helium10_screenshot_without_sorting) {
                cb(true)
                return
            }
            if(!keyword?.helium_10_ext_screenshot_with_revenue_sorting) {
                cb(true)
                return
            }
            if(!keyword?.helium_10_magnet_search_volume_history_1_year) {
                cb(true)
                return
            }
            if(!keyword?.js_screenshot_with_revenue_sorting) {
                cb(true)
                return
            }
            if(!keyword?.js_screenshot_without_sorting) {
                cb(true)
                return
            }

            cb(false)
        }
    }

   cb(false)
}



export const getKeywordImages = (keywrod) => {
    let images = [];
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.js_screenshot_without_sorting}` )
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.js_screenshot_with_revenue_sorting}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.helium10_screenshot_without_sorting}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.google_trend_past_5_years}`)
    // images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.cerebro_relevant_keywords}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.helium10_main_keyword}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.helium_10_ext_screenshot_with_revenue_sorting}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.helium_10_magnet_search_volume_history_1_year}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.helium_10_magnet_search_volume_history_all_time}`)
    // images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.average_reviews_of_top_10_ss}`)

    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.wm_helium10_min_kw_sv_screenshot   }`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.wm_helium10_main_kv_sv_with_amz_sv_screenshot }`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.wm_helium10_extension_complete_screenshot}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.wm_average_sales_sheet}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.wm_google_trend_screenshot}`)

    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.egrow_revenue_sorted}`)
    images.push(`${process.env.REACT_APP_IMAGE_BASE_URL}/${keywrod.egrow_revenue_unsorted}`)

    return images;
}
