import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'components/materialTable'
import EVSDetails from '../evsDetails'
import { useState } from 'react'
import MyModal from 'components/Modal'

let data = null;

export default function ViewStudents() {
    const history = useHistory()
    const [isModal, setModal] = useState(false)

    const actionView = (student) => {
        return(
            <button 
                type="button" 
                className="btn-org text-uppercase"
                onClick={()=>{ data=student; setModal(true) }} 
            >
                View
            </button>
        )  
    }

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <h3 className="mb-0 text-uppercase text-white pb-4">All Students</h3>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    <MaterialTable
                                        method='get'
                                        placeholder="Search by Name, Email, Phone"
                                        url={process.env.REACT_APP_ENDPOINT+'/api/user-for-editor'}
                                        columns={[
                                            { id: 'name', label: 'Name', minWidth: 100, searchable: true, index: 0 },
                                            { id: 'email', label: 'Email', minWidth: 150, searchable: true, index: 1 },
                                            { id: 'phone', label: 'Phone', minWidth: 150, searchable: true, index: 2 },
                                            { id: 'status', label: 'Account Status', minWidth: 100 },
                                            // { id: 'evs_email', name: 'evs_details.email', label: 'EVS Email', minWidth: 100 },
                                            // { id: 'evs_password', name: 'evs_details.password', Component: PasswordView, label: 'EVS Password', minWidth: 100 },
                                            { id: 'evs_status', name: 'evs_details.status', label: 'EVS Status', minWidth: 100 },
                                            { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />

            <MyModal fullWidth show={isModal} close={()=>{setModal(false); data = null}}>
                <EVSDetails student={data} close={()=>setModal(false)} />
            </MyModal>
        </div>
    )
}
