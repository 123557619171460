import { useHistory } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'

import DROPBOX from 'assets/images/dropbox-icon.png'
import PENDING from 'assets/images/pending-for-icon.png'
import PRO from 'assets/images/pro-icon.png'

import Card from 'components/card'
import NavBar from 'components/navbar'
import Footer from 'components/footer'
import useHomeData from './useHomeData'
import Memoized from 'components/Memoized'

export default function AdminHome() {

    const history = useHistory();
    const [ loading, total ] = useHomeData();

    return (
        <div>
            <Memoized Component={NavBar}/>
            <div className="main-information-wrapper">
                <section className="dash-content-wrapper pt-5 pb-md-4">
                    <div className="container">
                        {
                            <div className="row">
                                <Card value={total} image={PENDING} onClick={()=>history.push('/pending-products')} title={`Pending for approval`} loading={loading} special />

                                <Card value='' image={DROPBOX} onClick={()=>history.push('/all-products')} title='All Products' />

                                <Card value='' image={PRO} onClick={()=>history.push('/add-trainer')} title='Add Trainer' />

                                <Card value='' image={DROPBOX} onClick={()=>history.push('/view-trainers')} title='View Trainers' />

                                <Card value='' image={PRO} onClick={()=>history.push('/add-student')} title='Add Student' />

                                <Card value='' image={DROPBOX} onClick={()=>history.push('/view-students')} title='View Students' />

                                <Card value='' image={PRO} onClick={()=>history.push('/add-editor')} title='Add Editor' />

                                <Card value='' image={DROPBOX} onClick={()=>history.push('/view-editors')} title='View Editors' />

                                <Card value='' image={PRO} onClick={()=>history.push('/add-training')} title='Add Training' />

                                <Card value='' image={DROPBOX} onClick={()=>history.push('/view-trainings')} title='View Trainings' />

                                {/* <Card value='' image={PRO} onClick={()=>history.push('/add-batch')} title='Add Batch' /> */}

                                {/* <Card value='' image={DROPBOX} onClick={()=>history.push('/view-batches')} title='View Batches' /> */}

                                <Card value='' image={DROPBOX} onClick={()=>history.push('/view-activity-logs')} title='View Activity Logs' />
                            </div>
                        }
                    </div>
                </section>
            </div>
            <Memoized Component={Footer} />
        </div>
    )
}
