import Modal from 'components/Modal/index.js'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { addComments } from 'redux/actions/adminActions'
import { addComments as trainerComments } from 'redux/actions/trainerActions'


export default function ActionButtons({ id, close }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const {loading} = useSelector(({adminRed}) => adminRed)
    const {loading: isLoading} = useSelector(({trainerRed}) => trainerRed)
    const {role} = useSelector(({auth}) => auth)

    const [isModal, setModal] = useState(false)
    const [comments, setComments] = useState('')
    const [isApprove, setApprove] = useState(false)

    const submitHandler = () => {
        if(comments === '') {
            toast.warn('Please add comments to continue.')
        } else {
            if(role === 'Admin' || role === "Super Admin") {
                dispatch(
                    addComments(
                        {
                            id,
                            message: comments,
                            status: isApprove ? 'Approved by admin' : 'Reject by admin'
                        },
                        () => {
                            setModal(false)
                            setComments('')
                            close()
                        }
                    )
                )
            } else {
                dispatch(
                    trainerComments(
                        {
                            id,
                            message: comments,
                            status: isApprove ? 'Approved by trainer' : 'Reject by trainer'
                        },
                        () => {
                            setModal(false)
                            setComments('')
                            close()
                        }
                    )
                )
            }
        }
    }

    return (
        <>
            <div style={{
                display: 'flex',
                marginBottom: 15,
                justifyContent: 'center'
            }}>
                <button 
                    type="button" 
                    onClick={()=>{setApprove(true); setModal(true)}} 
                    className="btn-org text-uppercase"
                    style={{ marginRight: 5 }}
                >
                    Approve
                </button>
                <button 
                    type="button" 
                    onClick={()=>{setApprove(false); setModal(true)}} 
                    className="btn-org text-uppercase"
                    style={{ marginLeft: 5 }}
                >
                    Reject
                </button>
            </div>

            <Modal close={()=>{setComments(''); setModal(false)}} show={isModal}>
                <div  style={{ minWidth: '50vw' }}>
                    <div className="form-group">
                        <label htmlFor="comments">Comments</label>
                        <textarea value={comments} onChange={e=>setComments(e.target.value)} name="message" id="comments" className="form-control" rows="10" required="" style={{marginTop: 0, marginBottom: 0, height: 241}}></textarea>
                    </div>
                    
                    {
                        loading || isLoading ?
                            <button type="button" className="btn aree-btn btn-secondary  text-uppercase text-white rdu-50 px-4 mb-3">
                                <ScaleLoader color='#fff' height={15} />
                            </button>
                        : 
                            <button onClick={submitHandler} type="button" className="btn aree-btn btn-secondary  text-uppercase text-white rdu-50 px-4 mb-3">
                                Submit
                            </button>
                    }
                </div>
            </Modal>                
        </>
    )
}
