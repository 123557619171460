import { toast } from "react-toastify"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { evaluateProduct } from "utils/extractProducts"
import { addEvaluatedProduct } from 'redux/actions/studentActions'
import { useHistory, useLocation } from "react-router-dom"

const useProductEvaluation = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const location = useLocation()

    const [step, setStep] = useState(0)
    const [progress, setProgress] = useState(0)
    const [percentage, setPercentage] = useState(0)

    const {
        loading
    } = useSelector(({ studentRed }) => studentRed)
    const { user } = useSelector(({ auth }) => auth)

    // First Page
    const [isAmazonSelling, setIsAmazonSelling] = useState(null)
    const [above50Reviews, setAbove50Reviews] = useState(null)
    const [bsrStable, setBSRStable] = useState(null)
    const [priceStable, setPriceStable] = useState(null)
    const [fbmControl, setFBMControl] = useState(null)
    const [brandRestrict, setBrandRestrict] = useState(null)
    const [buyBox, setBuyBox] = useState(null)
    const [fragileItem, setFragileItem] = useState(null)
    const [hazmatProduct, setHazmatProduct] = useState(null)
    const [onlyBrandSeller, setOnlyBrandSeller] = useState(null)
    const [category, setCategory] = useState(null)
    const [asin, setASIN] = useState(null)
    const [bsr, setBSR] = useState(null)

    // Second Page
    const [market, setMarket] = useState('us')
    const [sellingPrice, setSellingPrice] = useState(null)
    const [salesVolume, setSalesVolume] = useState(null)
    const [noCompSellers, setNoCompSellers] = useState(null)
    const [productCost, setProductCost] = useState(null)
    const [unitsPack, setUnitsPack] = useState(null)
    const [fbaFee, setFBAFee] = useState(null)
    const [shippingCost, setShippingCost] = useState(null)
    const [bundlingCost, setBundlingCost] = useState(null)
    const [vat, setVAT] = useState(null)

    useEffect(() => {
        if(!location?.state?.product) {
            history.push('/student-evaluated-products')
        } else {
            let product = location.state.product;

            setAbove50Reviews(product.above_50_reviews || null);
            setIsAmazonSelling(product.amazon_selling_itself || null);
            setASIN(product.asin || null);
            setOnlyBrandSeller(product.brand_is_only_seller || null);
            setBSR(product.bsr || null);
            setBSRStable(product.bsr_stable_over_90days || null);
            setBuyBox(product.buy_box_suppressed || null);
            setCategory(product.category.replace(/&amp;/g, '&') || null);
            setFBMControl(product.fbm_controls_buy_box || null);
            setFragileItem(product.fragile_items || null);
            setHazmatProduct(product.hazmat_product || null);
            setSalesVolume(product.monthly_revenue || null);
            setNoCompSellers(product.number_of_competitive_fba_sellers || null);
            setPriceStable(product.price_stable_over_90days || null);
            setBrandRestrict(product.product_brand_restricted || null);
            setUnitsPack(product.units_in_pack || null);
        }
    }, [])

    const next = () => {
        if(!fbaFee) {
            toast.warn('Please describe FBA Fee')
        } else if(!shippingCost) {
            toast.warn('Please describe Shipping To Amazon FC')
        } else if(!bundlingCost) {
            toast.warn('Please describe Repacking And Bundling')
        } else {
            history.push('/student-evaluated-products')
            toast.success('Product is evaluated successfully')
        }
    }
    
    const done = () => {
        if(progress < 70) {
            toast.error('Your product is not according to our preferences.')
        } else {
            setStep(1)
        }
        return;
        if(!isAmazonSelling) {
            toast.warn('Please select is Amazon selling itself?')
        } else if(isAmazonSelling === 'yes') {
            toast.error("Amazon is selling this product itself. We don't reccomend it.")
        } else if(!salesVolume) {
            toast.warn('Please describe Monthly Sales')
        } else if(!noCompSellers) {
            toast.warn('Please describe Number of competitive sellers')
        } else if(!bsr) {
            toast.warn('Please describe BSR')
        } else if(!category) {
            toast.warn('Please select Category')
        } else if(!asin) {
            toast.warn('Please describe ASIN')
        } else if(progress < 70) {
            toast.error('Your product is not according to our preferences.')
        } else {
            let data = {
                bsr,
                asin,
                category,
                user_id: user.id,
                units_in_pack: unitsPack,
                fragile_items: fragileItem,
                buy_box_suppressed: buyBox,
                monthly_revenue: salesVolume,
                training_id: user.training_id,
                hazmat_product: hazmatProduct,
                above_50_reviews: above50Reviews,
                fbm_controls_buy_box: fbmControl,
                bsr_stable_over_90days: bsrStable,
                price_stable_over_90days: priceStable,
                brand_is_only_seller: onlyBrandSeller, 
                amazon_selling_itself: isAmazonSelling, 
                product_brand_restricted: brandRestrict,
                number_of_competitive_fba_sellers: noCompSellers,
            }

            dispatch(
                addEvaluatedProduct(
                    data,
                    () => setStep(1)
                )
            )
        }
    }

    // Handle Profit calculation
    useEffect(() => {
        if(sellingPrice) {
            let price = sellingPrice ? parseFloat(sellingPrice) : 0;
            let cost = productCost ? parseFloat(productCost) : 0;
            let fee = fbaFee ? parseFloat(fbaFee) : 0;
            let shipping = shippingCost ? parseFloat(shippingCost) : 0;
            let bundling = bundlingCost ? parseFloat(bundlingCost) : 0;
            let vTax = vat ? parseFloat(vat) : 0;
    
            let totalCost = cost + fee + shipping + bundling;
            if(market === 'uk')
                totalCost += vTax;
            let revenue = price-totalCost;
            
            if(price && cost) {
                let percentage = (revenue*100)/totalCost;
        
                if(percentage >= 10)
                    setProgress(50)
                else
                    setProgress(0)
                    
                setPercentage(percentage)
            }
        }
    }, [sellingPrice, productCost, fbaFee, shippingCost, bundlingCost, vat])

    // Handle Product Evaluation
    useEffect( () => {
        if(step === 0) {
            let per = evaluateProduct(
                above50Reviews,
                bsrStable,
                priceStable,
                fbmControl,
                brandRestrict,
                buyBox,
                fragileItem,
                hazmatProduct,
                onlyBrandSeller,
                salesVolume,
                noCompSellers,
                bsr
            );
    
            setProgress(+per)
        }
    }, [
        isAmazonSelling,
        above50Reviews,
        bsrStable,
        priceStable,
        fbmControl,
        brandRestrict,
        buyBox,
        fragileItem,
        hazmatProduct,
        onlyBrandSeller,
        category,
        asin,
        salesVolume,
        noCompSellers,
        bsr,
    ])

    return [
        step,
        next,
        done,
        progress,
        percentage,

        market, setMarket,
        sellingPrice, setSellingPrice,
        salesVolume, setSalesVolume,
        noCompSellers, setNoCompSellers,
        productCost, setProductCost,
        unitsPack, setUnitsPack,
        fbaFee, setFBAFee,
        shippingCost, setShippingCost,
        bundlingCost, setBundlingCost,
        vat, setVAT,

        isAmazonSelling, setIsAmazonSelling,
        above50Reviews, setAbove50Reviews,
        bsrStable, setBSRStable,
        priceStable, setPriceStable,
        fbmControl, setFBMControl,
        brandRestrict, setBrandRestrict,
        buyBox, setBuyBox,
        fragileItem, setFragileItem,
        hazmatProduct, setHazmatProduct,
        onlyBrandSeller, setOnlyBrandSeller,
        category, setCategory,
        asin, setASIN,

        loading,
        bsr, setBSR,
    ]
}

export default useProductEvaluation