import APPROVED from 'assets/images/approved-product-icon.png'

import NavBar from 'components/navbar'
import Footer from 'components/footer'

export default function ApprovedProduct() {
    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper pro-approved pt-5 pb-md-4">
                    <div className="container">
                        <div className="pro-approved-content text-center py-5">
                            <h2 className="text-white mb-5">You have successfully Approved your Product</h2>
                            <img src={APPROVED} />
                        </div>
                    </div>
                </section>
            
            </div>
            
            <Footer />
        </div>
    )
}
