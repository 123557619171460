import NavBar from 'components/navbar'
import Footer from 'components/footer'
import MaterialTable from 'components/materialTable'
import { updateStatus } from "redux/actions/adminActions";
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from './BreadCrumbs';
import PasswordView from 'components/passwordView';
import { useState } from 'react';
import LogDetails from './LogDetails';
import MyModal from 'components/Modal';

let data = null;

export default function ViewTrainers() {
    const dispatch = useDispatch()
    const {loading}=useSelector(({adminRed})=>adminRed)
    const [isModal, setModal] = useState(false)

    const actionView = (activity) => {
        return(
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <button 
                    type="button" 
                    onClick={()=>{ data=activity; setModal(true)}} 
                    className="btn-org text-uppercase"
                >
                    Detail
                </button>
            </div>
        )  
    }

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs />
                        <h3 className="mb-0 text-uppercase text-white pb-4">Activity Logs</h3>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    {
                                            <MaterialTable
                                                method='get'
                                                loading={loading}
                                                searchColumns={['description', 'comments']}
                                                url={process.env.REACT_APP_ENDPOINT+'/api/activity-log'}
                                                columns={[
                                                    { id: 'name', name: 'users.name', label: 'Performed By', minWidth: 100, searchable: true, sortable: true, index: 0 },
                                                    { id: 'event', label: 'Action', minWidth: 150, searchable: true, sortable: true, index: 1 },
                                                    { id: 'updated_at', label: 'Date', minWidth: 120, searchable: true, sortable: true, index: 4 },
                                                    { id: 'action', label: 'Action', align: 'center', minWidth: 100, action: actionView },
                                                ]}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />

            {
                isModal ?
                    <MyModal fullWidth show={isModal} close={()=>{ setModal(false); data = null; }}>
                        <LogDetails activity={data} />
                    </MyModal>
                :
                    null
            }
        </div>
    )
}
