import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { toast } from "react-toastify"
import { ScaleLoader } from 'react-spinners'
import MaterialTable from 'components/materialTable'
import { useSelector, useDispatch } from 'react-redux'
import { updateStatus, changeAllPendingStatus } from 'redux/actions/trainerActions'
import useAllStudents from './useAllStudents'
import BreadCrumbs from './BreadCrumbs'
import MyModal from 'components/Modal'
import StudentDetails from 'components/StudentDetails'
import StudentFilterView from 'components/studentFilterView'

export default function ViewStudents() {
    const [
        loading,
        selected,
        selectOne,
        selectAll,
        changeStatus,
        actionView,
        isModal,
        setModal,
        student,
        setStudent,
        childRef,
        filter,
        setFilter
    ] = useAllStudents()

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs />

                        <div className="pt-3" />

                        <StudentFilterView
                            hideTrainer
                            filter={filter}
                            setFilter={setFilter}
                            applyFilter={()=>childRef.current.refreshData()}
                        />

                        {/* <h3 className="mb-0 text-uppercase text-white pb-4 col-sm-6">Pending Students</h3> */}
                        <div className='row'>
                            <h3 className="mb-0 text-uppercase text-white pb-4 col-sm-6">Pending Students</h3>
                            <div className="col-sm-6" align='right'>
                                {
                                    selected.length ?
                                        <button 
                                            type="button" 
                                            onClick={changeStatus} 
                                            style={{ marginBottom: 13 }}
                                            className="btn-org text-uppercase"
                                        >
                                            Activate
                                        </button>
                                    :
                                        <button
                                            type="button" 
                                            onClick={()=>toast.warn('Please select student(s) first.')} 
                                            style={{ marginBottom: 13 }}
                                            className="btn-org text-uppercase"
                                        >
                                            Activate
                                        </button>
                                }
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">   
                                <MaterialTable
                                    method='get'
                                    ref={childRef}
                                    filter={filter}
                                    loading={loading}
                                    selected={selected}
                                    handleOneCheck={selectOne}
                                    placeholder="Search by Name, Email, CNIC"
                                    url={process.env.REACT_APP_ENDPOINT+'/api/pending-students'}
                                    handleCheckChange={(is, students)=>selectAll(is, students)}
                                    columns={[
                                        { id: 'checkbox', checkbox: true, label: '', minWidth: 50 },
                                        { id: 'name', label: 'Name', minWidth: 100, searchable: true, sortable: true, index: 0 },
                                        { id: 'email', label: 'Email', minWidth: 150, searchable: true, sortable: true, index: 1 },
                                        // { id: 'phone', label: 'Phone', minWidth: 150, searchable: true, sortable: true, index: 2 },
                                        { id: 'cnic', label: 'CNIC', minWidth: 150, searchable: true, sortable: true, index: 2 },
                                        // { id: 'address', label: 'Address', minWidth: 150, searchable: true, sortable: true, index: 4 },
                                        // { id: 'cnic_front_image', image: true, label: 'CNIC Front', width: 100 },
                                        // { id: 'cnic_back_image', image: true, label: 'CNIC Back', width: 100 },
                                        // { id: 'trainer_name', name: 'trainer.name', label: 'Trainer', minWidth: 100 },
                                        { id: 'training_name', name: 'trainings.name', label: 'Training', minWidth: 100, searchable: true, index: 3 },
                                        { id: 'batch_name', name: 'batches.name', label: 'Batch', minWidth: 100, searchable: true,  sortable: true, index: 4 },
                                        // { id: 'password_s_t_r', label: 'Password', minWidth: 100 },
                                        { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: true, index: 5 },
                                        { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />


            <MyModal show={isModal} close={()=>{ setModal(false); setStudent(null); }}>
                <StudentDetails student={student} />
            </MyModal>
        </div>
    )
}
