import {
    TRAINER_LOADING,
    TRAINER_ADD_STUDENT,
    TRAINER_MORE_LOADING,
    TRAINER_ADD_COMMENTS, 
    TRAINER_UPDATE_STATUS,
    TRAINER_GET_ALL_STUDENTS,
    TRAINER_GET_ALL_PRODUCTS,
    TRAINER_GET_MORE_STUDENTS,
    TRAINER_GET_PENDINGS_COUNT,
    TRAINER_GET_MORE_PRODUCTS,
    CHANGE_TRAINER_ALL_STATUSES,
    TRAINER_GET_PENDING_PRODUCTS, 
    TRAINER_UPDATE_STUDENT_STATUS,
    TRAINER_GET_PENDING_STUDENTS,
    TRAINER_GET_MORE_ALL_PRODUCTS,
    TRAINER_GET_MORE_PENDING_PRODUCTS,
    TRAINER_GET_MORE_PENDING_STUDENTS,
} from '../types';

const initState = {
    isProductRequested: false,
    moreLoading: false,
    pendingStudentsCount: 0,
    pendingStudents: [],
    totalPending: 0,
    loading: false,
    allStudents: 0,
    students: [],
    products: [],
    pending: [],
    allTotal: 0,
}

export default function reducer(state=initState, action) {
    let { payload } = action;

    switch (action.type) {
        case TRAINER_GET_PENDINGS_COUNT:
            return {
                ...state,
                loading: false,
                totalPending: payload.productsTotal,
                pendingStudentsCount: payload.studentsTotal,
            }

        case TRAINER_GET_ALL_PRODUCTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                allTotal: payload.total,
                products: payload.products,
            }

        case TRAINER_GET_MORE_PRODUCTS: {
            let products = [...state.products]
            products = products.concat(payload.products)

            return {
                ...state,
                products,
                loading: false,
                moreLoading: false,
                allTotal: payload.total,
            }
        }

        case TRAINER_GET_PENDING_PRODUCTS: 
            return {
                ...state,
                loading: false,
                moreLoading: false,
                isProductRequested: true,
                pending: payload.products,
                totalPending: payload.total,
            }

        case TRAINER_GET_MORE_PENDING_PRODUCTS: {
            let products = [...state.pending]
            products = products.concat(payload.products)

            return {
                ...state,
                loading: false,
                pending: products,
                moreLoading: false,
                isProductRequested: true,
                totalPending: payload.total,
            }
        }

        case TRAINER_ADD_STUDENT: {
            let students = [...state.students]
            students.push(payload)
            let allStudents = state.allStudents+1

            return {
                ...state,
                students,
                allStudents,
                loading: false,
            }
        }

        case TRAINER_GET_ALL_STUDENTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                students: payload.users,
                allStudents: payload.total,
            }

        case TRAINER_GET_MORE_STUDENTS: {
            let students = [...state.students]
            students = students.concat(payload.users)

            return {
                ...state,
                students,
                loading: false,
                moreLoading: false,
                allStudents: payload.total,
            }
        }

        case TRAINER_GET_PENDING_STUDENTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                pendingStudents: payload.users,
                pendingStudentsCount: payload.total,
            }

        case TRAINER_GET_MORE_PENDING_STUDENTS: {
            let students = [...state.pendingStudents]
            students = students.concat(payload.users)

            return {
                ...state,
                loading: false,
                moreLoading: false,
                pendingStudents: students,
                pendingStudentsCount: payload.total,
            }
        }

        case TRAINER_UPDATE_STATUS: {
            let trainers = state.trainers.map(t => {
                if(t.id === payload.id)
                    return {
                        ...t,
                        status: payload.status
                    }
                else return t;
            })

            return {
                ...state,
                trainers,
                loading: false,
            }
        }

        case TRAINER_UPDATE_STUDENT_STATUS: {
            let students = state.students.map(s => {
                if(s.id === payload.id)
                    return {
                        ...s,
                        status: payload.status
                    }
                else return s;
            })

            return {
                ...state,
                students,
                loading: false,
            }
        }

        case TRAINER_ADD_COMMENTS:
            return {
                ...state,
                loading: false,
            }

        case CHANGE_TRAINER_ALL_STATUSES: {
            let students = [] 
            students = state.students.map(std => {
                if(payload.includes(std.id)) {
                    return {
                        ...std,
                        status: 'active'
                    }
                } else {
                    return std
                }
            })

            return {
                ...state,
                students,
                loading: false
            }
        }

        case TRAINER_GET_MORE_ALL_PRODUCTS: {
            let products = [...state.products];
            products = products.concat(payload.products)

            return {
                ...state,
                products,
                loading: false,
                moreLoading: false,
                allTotal: payload.total,
            }
        }

        case TRAINER_MORE_LOADING:
            return {
                ...state,
                moreLoading: payload
            }

        case TRAINER_LOADING:
            return {
                ...state,
                loading: payload,
            }
    
        default:
            return state;
    }
}