import NavBar from 'components/navbar'
import Footer from 'components/footer'
import AddProductBasics from './components/AddProductBasics'
import AddProductDetails from './components/AddProductDetails'
import useAddProduct from './useAddProduct'
import ProductKeywordsTable from './components/ProductKeywordsTable'
import Memoized from './components/Memoized'
import AddProductComment from 'screens/editPrivateLabelProduct/components/AddProductComment'
import { Prompt } from 'react-router-dom'
import Modal from 'components/Modal/index.js'
import Disclaimer from './components/Disclaimer'
import { toast } from 'react-toastify'
import BreadCrumbs from './components/BreadCrumbs'
import ConfirmationAlert from 'components/confimationAlert'

export default function AddProduct() {
    const  [
        formik,
        loading,
        index,
        setIndex,
        name,
        setName,
        market,
        setMarket,
        image,
        setImage,
        nextHandler,

        keywords, setKeywords,
        jsExactSV, setJSExactSV,
        jsBroadSV, setJSBroadSV,
        helium10SV, setHelium10SV,
        jsGiveAPD, setJSGiveAPD,
        helium10NOGA, setHelium10NOGA,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        helium10SWRS, setHelium10SWRS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        addKeywordHandler,
        doneHandler,

        comment, setComment,
        addComment,
        prohibited, setProhibited,
        isModal, setModal,

        inputHelium10Exactsearch, setInputHelium10Exactsearch,
        averageReviewsOfTop10, setAverageReviewsOfTop10,
        averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
        helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
        helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
        helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
        averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
        availableBudget, setAvailableBudget,
        currency, setCurrency,
        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ] = useAddProduct()

    return (
        <div style={{ scrollBehavior: 'smooth' }}>
            <Memoized
                Component={NavBar}
            />

            <BreadCrumbs className="mt-5" />

            {
                index === 0 ?
                    <AddProductBasics
                        formik={formik}
                        onNext={nextHandler}
                        name={name}
                        setName={setName}
                        market={market}
                        setMarket={setMarket}
                        image={image}
                        setImage={setImage} 
                    />
                :
                    <>
                        <AddProductDetails
                            formik={formik}
                            loading={loading}
                            // keywords={keywords} 
                            // setKeywords={setKeywords}
                            // jsExactSV={jsExactSV} 
                            // setJSExactSV={setJSExactSV}
                            // jsBroadSV={jsBroadSV} 
                            // setJSBroadSV={setJSBroadSV}
                            // helium10SV={helium10SV}
                            // setHelium10SV={setHelium10SV}
                            // jsGiveAPD={jsGiveAPD} 
                            // setJSGiveAPD={setJSGiveAPD}
                            // helium10NOGA={helium10NOGA}
                            // setHelium10NOGA={setHelium10NOGA}
                            jsScreenWS={jsScreenWS}
                            setJSScreenWS={setJSScreenWS}
                            jsScreenWRS={jsScreenWRS} 
                            setJSScreenWRS={setJSScreenWRS}
                            helium10SWS={helium10SWS} 
                            setHelium10SWS={setHelium10SWS}
                            // helium10SWRS={helium10SWRS} 
                            // setHelium10SWRS={setHelium10SWRS}
                            cereboRKSWSVS={cereboRKSWSVS} 
                            setCereboRKSWSVS={setCereboRKSWSVS}
                            helium10MKSH={helium10MKSH} 
                            setHelium10MKSH={setHelium10MKSH}
                            past5YGT={past5YGT} 
                            setPast5YGT={setPast5YGT}
                            profitMargin={profitMargin} 
                            setProfitMargin={setProfitMargin}
                            // productKeywords={productKeywords} 
                            // setProductKeywords={setProductKeywords}
                            // addKeyword={addKeywordHandler}
                            // doneHandler={doneHandler}

                            // inputHelium10Exactsearch={inputHelium10Exactsearch}
                            // setInputHelium10Exactsearch={setInputHelium10Exactsearch}
                            // averageReviewsOfTop10={averageReviewsOfTop10}
                            // setAverageReviewsOfTop10={setAverageReviewsOfTop10}
                            averageSellingPriceOfTop10={averageSellingPriceOfTop10}
                            setAverageSellingPriceOfTop10={setAverageSellingPriceOfTop10}
                            helium10ExtScreenshotWithRevenueSorting={helium10ExtScreenshotWithRevenueSorting}
                            setHelium10ExtScreenshotWithRevenueSorting={setHelium10ExtScreenshotWithRevenueSorting}
                            helium10MagnetSearchVolumeHistory1Year={helium10MagnetSearchVolumeHistory1Year}
                            setHelium10MagnetSearchVolumeHistory1Year={setHelium10MagnetSearchVolumeHistory1Year}
                            helium10MagnetSearchVolumeHistoryAllTime={helium10MagnetSearchVolumeHistoryAllTime}
                            setHelium10MagnetSearchVolumeHistoryAllTime={setHelium10MagnetSearchVolumeHistoryAllTime}
                            averageReviewsOfTop10SS={averageReviewsOfTop10SS}
                            setAverageReviewsOfTop10SS={setAverageReviewsOfTop10SS}
                            availableBudget={availableBudget}
                            setAvailableBudget={setAvailableBudget}
                            currency={currency}
                            setCurrency={setCurrency}
                            setSubmitted={setSubmitted}
                        />

                        {
                            productKeywords && productKeywords.length ? 
                                <>
                                    <ProductKeywordsTable
                                        productKeywords={productKeywords} 
                                        setProductKeywords={setProductKeywords} 
                                    />
                                    <AddProductComment
                                        loading={loading}
                                        comment={comment} 
                                        addComment={()=>{
                                            if(comment === '') {
                                                toast.warn('Please add Product Details.')
                                            } else {
                                                setModal(true)
                                            }
                                        }}
                                        setComment={setComment}
                                    />
                                </>
                            :
                                null    
                        }
                    </>    
            }

            

            <Modal fullWidth show={isModal} close={()=>setModal(false)} hideClose={true}>
                <Disclaimer done={()=>{
                    setModal(false)
                    addComment()
                }} />
            </Modal>
            
            <Modal fullWidth show={isKeywordConfirmation} close={()=>{}} hideClose={true}>
                <ConfirmationAlert 
                    title="Do you want to add more keywords to this product?"
                    description=""
                    onConfirm={onKeywordConfirmation}
                    onCancel={onKeywordCanelation}
                />
            </Modal>

            <Prompt
                when={prohibited}
                message={location =>
                    `Changes you made may not be saved.`
                }
            />

            
            <Footer />
        </div>
    )
}
