import NavBar from 'components/navbar'
import Footer from 'components/footer'
import MaterialTable from 'components/materialTable'
import { getProductStatus, getStudentProductStatusByTrainer, getStudentProductStatusByAdmin } from 'utils/getProductStatus'
import MyModal from 'components/Modal'
import EditPrivateLabelProduct from 'screens/editPrivateLabelProduct'
import AddProductKeywords from 'screens/student/addProductKeywords'
import { useState } from 'react'

import PENDING from 'assets/images/pending.png'
import APPROVED from 'assets/images/approve-icon.png'
import REJECTED from 'assets/images/not-approve-icon.png'
import BreadCrumbs from './BreadCrumbs'
import DropMenu from "components/dropMenu"
import MENU_IMG from 'assets/images/menu.png'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProduct } from 'redux/actions/studentActions'

let cb = null;

export default function AllProduct() {
    const dispatch = useDispatch()

    const [isModal, setModal] = useState(false)
    const [product, setProduct] = useState(null)

    const { loading } = useSelector(({ studentRed }) => studentRed)

    const close = () => {
        cb && cb();
        setModal(false);
        setProduct(null);
    }

    const viewDetails =(refresh, product) => {
        cb = refresh;
        setProduct(product)
        setModal(true)
    }

    const deleteHandler = (id, refresh) => {
        dispatch(deleteProduct(
            id,
            refresh
        ))
    }

    const actionView = (product, refresh) => {
        return(
            product.status === "Pending" ?
                <DropMenu
                    button={<img src={MENU_IMG} className="cursor" />}
                    options={[
                        { title: 'Delete', onClick: ()=>deleteHandler(product.id, refresh) },
                        { title: 'Add Keywords', onClick: ()=>viewDetails(refresh, product) },
                    ]}
                />
            :
                <button
                    type="button"
                    className="btn-org text-uppercase"
                    onClick={()=> {
                        cb = refresh;
                        setProduct(product)
                        setModal(true)
                    }}
                >
                    View
                </button>
        )
    }

    const trainerStatusView = (products, id) => {
        let product = products.filter(p => p.id == id)
        let sts = getStudentProductStatusByTrainer(product[0].status);

        if(sts === 'approved') {
            return  <>
                        <img src={APPROVED} />
                        <strong> Approved</strong>
                    </>
        } else if(sts === 'rejected') {
            return  <>
                        <img src={REJECTED} />
                        <strong> Rejected</strong>
                    </>
        } else {
            return  <>
                        <img src={PENDING} />
                        <strong> Pending</strong>
                    </>
        }
    }

    const adminStatusView = (products, id) => {
        let product = products.filter(p => p.id == id)
        let sts = getStudentProductStatusByAdmin(product[0].status);

        if(sts === 'approved') {
            return  <>
                        <img src={APPROVED} />
                        <strong> Approved</strong>
                    </>
        } else if(sts === 'rejected') {
            return  <>
                        <img src={REJECTED} />
                        <strong> Rejected</strong>
                    </>
        } else {
            return  <>
                        <img src={PENDING} />
                        <strong> Pending</strong>
                    </>
        }
    }

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">

                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs />
                        <h3 className="mb-0 text-uppercase text-white pb-4">All  Products</h3>
                        <div className="alert alert-success">
                            <strong>Disclaimer:</strong> Please be advised that this product approval system is intended solely for learning purposes and is not intended for commercial use or sale.
                                Enablers hereby disclaims any responsibility for any guaranteed success that may result from the launch of this project.
                        </div>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                 <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    <MaterialTable
                                        method='post'
                                        sortingIndex={1}
                                        loading={loading}
                                        sortingDirection='desc'
                                        url={process.env.REACT_APP_ENDPOINT+'/api/products'}
                                        columns={[
                                            { id: 'name', label: 'Name', minWidth: 100, searchable: true, sortable: true, index: 0 },
                                            { id: 'image', image: true, label: 'Image', minWidth: 150 },
                                            { id: 'trainerStatus', label: 'Approved By Trainer', custom: trainerStatusView, minWidth: 150 },
                                            { id: 'adminStatus', label: 'Approved By Product Evaluation Team (Not in Use for your Program)', custom: adminStatusView, minWidth: 150 },
                                            { id: 'submitted_date', date: true, label: 'Submitted Date', minWidth: 100, searchable: true, sortable: true, index: 1 },
                                            { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: true, index: 2 },
                                            { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />

            {
                isModal ?
                    <MyModal fullWidth show={isModal} close={()=>{ setModal(false); setProduct(null); }}>
                        {
                            product?.status === "Pending" ?
                                <AddProductKeywords
                                    close={close}
                                    id={product.id}
                                    isStudent={true}
                                    show={getProductStatus(product.status)[2]}
                                />
                            :
                                <EditPrivateLabelProduct
                                    close={close}
                                    id={product.id}
                                    isStudent={true}
                                    show={getProductStatus(product.status)[2]}
                                />
                        }
                    </MyModal>
                :
                    null
            }
        </div>
    )
}
