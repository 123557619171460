import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import ReactDOM from 'react-dom';
import { addProductForApproval, getProductsCount } from 'redux/actions/studentActions';
import { PLTrainigID, trainigID, VATrainingID, OneToOneTrainingID } from "constants/constants";

let isFirst = 0;

const useAddProduct = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        loading,
        pending,
        approved,
    } = useSelector(({ studentRed }) => studentRed)
    const {user} = useSelector(({auth}) => auth)

    useEffect(() => {
        dispatch(getProductsCount())
        setSubmitted(Math.random())
    }, [])

    useEffect(()=>{
        if(user.training_id == trainigID) {
            history.push('/student-home')
        } else if(pending > 0) {
            toast.warn('You already have pending product in your queue')
            history.push('/student-home')
        } else if(user.training_id == PLTrainigID || user.training_id == VATrainingID || user.training_id == OneToOneTrainingID) {
            if(approved >= 2) {
                toast.warn('You have already 2 approved products in the system')
                history.push('/student-home')
            }
        } else if(approved >= 1) {
            toast.warn('You have already 1 approved product in the system')
            history.push('/student-home')
        }
    }, [pending, approved])

    const [productKeywords, setProductKeywords] = useState([])

    const [index, setIndex] = useState(0)
    const [name, setName] = useState('')
    const [market, setMarket] = useState('')
    const [image, setImage] = useState(null)

    const [keywords, setKeywords] = useState('')
    const [competingProducts, setCompetingProducts] =useState('')
    const [egrowSV, setEgrowSV] =useState('')
    const [avgReviewsOfTop10, setAvgReviewsOfTop10] = useState('')
    const [avgSellingPriceOfTop10, setAvgSellingPriceOfTop10] = useState('')
    const [profitMargin, setProfitMargin] = useState('')
    const [availableBudget, setAvailableBudget] = useState('')
    const [currency, setCurrency] = useState('usd')
    const [egrowRevenueSorted, setEgrowRevenueSorted] = useState(null)
    const [egrowRevenueUnSorted, setEgrowRevenueUnSorted] = useState(null)
    const [avgMonthlyRevenueOfTop10, setAvgMonthlyRevenueOfTop10] = useState('')
    const [sixtyPercentOfTheTotalMonthlyRevenueOfTop10, setsixtyPercentOfTheTotalMonthlyRevenueOfTop10] = useState('')

    const [comment, setComment] = useState('')
    const [prohibited, setProhibited] = useState(false)
    const [isModal, setModal] = useState(false)
    const [isKeywordConfirmation, setKeywordConfirmation] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)

    const formik = useFormik({
        initialValues: {
            keywords:'',
            competingProducts:'',
            egrowSV:'',
            avgReviewsOfTop10:'',
            avgSellingPriceOfTop10:'',
            profitMargin:'',
            availableBudget:'',
            avgMonthlyRevenueOfTop10:'',
            sixtyPercentOfTheTotalMonthlyRevenueOfTop10:'',
        },
        validationSchema: Yup.object({
            keywords: Yup.string().max(255, 'Must be 255 characters or less').required('Product Keywrords are required'),
            egrowSV: Yup.string().required('Egrow SV are required'),
            competingProducts: Yup.string().required('Competing Products SV are required'),
            avgReviewsOfTop10: Yup.string().max(15, 'Must be 15 characters or less').required('Average Reviews of Top 10 is required'),
            avgSellingPriceOfTop10: Yup.string().max(15, 'Must be 15 characters or less').required('Average Selling Price of Top 10 is required'),
            availableBudget: Yup.string().max(15, 'Must be 15 characters or less').required('Available Budget is required'),
            profitMargin: Yup.string().required('Profit Margin is required').test('Must be 100% or less', 'Must be 100% or less', value => value <= 100)
        }),
        onSubmit: values => {
            addKeywordHandler(values)
        },
    });

    window.onbeforeunload = function () {return false}

    useEffect(() => {
        if(isFirst < 2) {
            isFirst++;
            setSubmitted(Math.random())
        }
    }, [Object.keys(formik.errors).length])

    useEffect(() => {
        let elem = ReactDOM.findDOMNode(document.getElementById(Object.keys(formik.errors)[0]));

        if(elem) {
            elem.focus()
        }
    }, [isSubmitted])

    const nextHandler = () => {
        if(name === '') {
            toast.warn('Please enter Product Name.')
        } else if(market === '') {
            toast.warn('Please enter Product Market.')
        } else if(!image) {
            toast.warn('Please select Product Image.')
        } else {
            setIndex(1)
            setProhibited(true)
        }
    }

    const onKeywordConfirmation = () => {
        setKeywordConfirmation(false)
        setTimeout(() => {
            window.scrollTo(0, 300)
        }, 500);
    }

    const onKeywordCanelation = () => {
        setKeywordConfirmation(false)
        setTimeout(() => {
            window.scrollTo(0, 2500)
        }, 500);
    }

    const addToArray = ({
        keywords,competingProducts,egrowSV, avgReviewsOfTop10, avgSellingPriceOfTop10, profitMargin, availableBudget,
        avgMonthlyRevenueOfTop10, sixtyPercentOfTheTotalMonthlyRevenueOfTop10,
    }) => {
        if(keywords === '') {
            toast.warn('Please enter Product Keywords.')
        } else if(competingProducts === '') {
            toast.warn('Please enter Competing Products.')
        } else if(egrowSV === '') {
            toast.warn('Please enter Egrow SV.')
        } else if(avgReviewsOfTop10 === '') {
            toast.warn('Please enter Average  Reviews of top 10.')
        } else if(!avgSellingPriceOfTop10) {
            toast.warn('Please enter Average  Selling Price of Top 10.')
        } else if(!profitMargin) {
            toast.warn('Please enter Profit Margin.')
        } else if(!availableBudget) {
            toast.warn('Please enter Available Budget.')
        } else if(!egrowRevenueSorted) {
            toast.warn("Please select Egrow Revenue Sorted")
            ReactDOM.findDOMNode(document.getElementById('egrowRevenueSorted')).focus()
        } else if(!egrowRevenueUnSorted) {
            toast.warn("Please selectEgrow Revenue Unsorted")
            ReactDOM.findDOMNode(document.getElementById('egrowRevenueUnSorted')).focus()
        }
        else if(avgMonthlyRevenueOfTop10 === '') {
            toast.warn('Please enter Average Monthly Revenue of Top 10')
        } else if(sixtyPercentOfTheTotalMonthlyRevenueOfTop10 === '') {
            toast.warn('Please enter 60% of the Total Monthly Revenue of Top 10')
        }
         else {
            let productKWs = [...productKeywords];

            let kwrd = {
                keyword:keywords,
                competing_products:competingProducts,
                egrow_sv:egrowSV,
                average_reviews_of_top_10:avgReviewsOfTop10,
                average_selling_price_of_top_10:avgSellingPriceOfTop10,
                profit_margin:profitMargin,
                available_budget:availableBudget,
                currency:currency,
                avg_monthly_revenue_of_top_10:avgMonthlyRevenueOfTop10,
                sixty_percent_of_the_total_monthly_revenue_of_top10:sixtyPercentOfTheTotalMonthlyRevenueOfTop10,
                egrow_revenue_sorted:egrowRevenueSorted,
                egrow_revenue_unsorted:egrowRevenueUnSorted,

            }

            productKWs.push(kwrd);
            setProductKeywords(productKWs);
            return true;
        }

        return false;
    }

    const addKeywordHandler = (values) => {
        let added = addToArray(values);
        if(added) {
            setEmptyDetails()
            setKeywordConfirmation(true)
        }
    }

    const setEmptyBasics = () => {
        setName('')
        setMarket('')
        setImage(null)
    }

    const setEmptyDetails = () => {
        formik.resetForm();
        setKeywords('')
        setCompetingProducts('')
        setEgrowSV('')
        setAvgReviewsOfTop10('')
        setAvgSellingPriceOfTop10('')
        setProfitMargin('')
        setAvailableBudget('')
        setCurrency('usd')
        setEgrowRevenueSorted(null)
        setEgrowRevenueUnSorted(null)
        setAvgMonthlyRevenueOfTop10('')
        setsixtyPercentOfTheTotalMonthlyRevenueOfTop10('')
    }

    const doneHandler = () => {
        if(loading) return;

        if(productKeywords && productKeywords.length) {
            if(comment === '') {
                toast.warn('Please add Product Details.')
            } else {
                let formData = new FormData();

                formData.append('name', name)
                formData.append('image', image)
                formData.append('market', market)
                formData.append('message', comment)
                formData.append('len', productKeywords.length)

                // productKeywords.map((pK, i) => {
                //     Object.keys(pK).map(key => {
                //         formData.append(`keywords${i}.${key}`, pK[key])
                //     })
                // })

                productKeywords.map((pK, i) => {
                    Object.keys(pK).map(key => {
                        formData.append(`keywords[${i}][${key}]`, pK[key])
                    })
                })

                setProhibited(false)
                dispatch(addProductForApproval(formData, () => {
                    setEmptyBasics();
                    setEmptyDetails();
                    history.push('/student-all-products')
                }))
            }
        } else {
            toast.warn('Please add at least one keyword.')
        }
    }

    return [
        formik,
        loading,
        index,
        setIndex,
        name,
        setName,
        market,
        setMarket,
        image,
        setImage,
        nextHandler,

        keywords, setKeywords,
        competingProducts, setCompetingProducts,
        egrowSV, setEgrowSV,
        avgReviewsOfTop10, setAvgReviewsOfTop10,
        avgSellingPriceOfTop10, setAvgSellingPriceOfTop10,
        profitMargin, setProfitMargin,
        availableBudget, setAvailableBudget,
        currency, setCurrency,
        egrowRevenueSorted, setEgrowRevenueSorted,
        egrowRevenueUnSorted, setEgrowRevenueUnSorted,
        avgMonthlyRevenueOfTop10, setAvgMonthlyRevenueOfTop10,
        sixtyPercentOfTheTotalMonthlyRevenueOfTop10, setsixtyPercentOfTheTotalMonthlyRevenueOfTop10,

        productKeywords, setProductKeywords,
        addKeywordHandler,
        doneHandler,
        comment, setComment,
        doneHandler,
        prohibited, setProhibited.products,
        isModal, setModal,



        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ]
}

export default useAddProduct;
