import {
    GET_EVS_DETAILS,
    STUDENT_LOADING,
    STUDENT_SIGNUP,
    UPDATE_STUDENT_PRODUCT,
    ADD_PRODUCT_COMMENT,
    ADD_PRODUCT_KEYWROD,
    GET_PRODUCT_KEYWORD,
    GET_STUDENT_PRODUCTS,
    STUDENT_MORE_LOADING,
    ADD_EVALUATED_PRODUCT,
    DELETE_PRODUCT_KEYWORD,
    DELETE_STUDENT_PRODUCT,
    ADD_PRODUCT_FOR_APPROVAL,
    EVALUATE_STUDENT_PRODUCT,
    GET_STUDENT_MORE_PRODUCTS,
    GET_STUDENT_PRODUCT_COUNT,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { unauthenticationHandler } from './authActions';


export const addEvaluatedProduct = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-student-training-products`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: ADD_EVALUATED_PRODUCT,
                        payload: response.data.product,
                    });

                    toast.success('Product evaluated successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.message) ?
                            error.response.data.message
                        :
                            (error.message || 'Something went wrong!')
                )
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const getProductsCount = (isEvaluated, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: isEvaluated ? `${process.env.REACT_APP_ENDPOINT}/api/student-total-products-count` : `${process.env.REACT_APP_ENDPOINT}/api/total-pending-products`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {

                if(response.status === 200) {
                    dispatch({
                        type: GET_STUDENT_PRODUCT_COUNT,
                        payload: {
                            approved: isEvaluated ?
                                    response.data.all_product_counts ? response.data.all_product_counts : 0
                                :
                                    response.data.approved_products ? response.data.approved_products : 0,
                            pending: isEvaluated ?
                                    response.data.evaluated_products ? response.data.evaluated_products : 0
                                :
                                    response.data.pending_products ? response.data.pending_products : 0,
                            archived:
                                    response.data.archive_products || 0
                        }
                    });

                    cb && cb();
                } else {
                    alert(response.data.message || 'Something went wrong!')

                    dispatch({
                        type: STUDENT_LOADING,
                        payload: false
                    });
                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({
                        type: STUDENT_LOADING,
                        payload: false
                    });
                }
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const addProductForApproval = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-product`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: ADD_PRODUCT_FOR_APPROVAL,
                        payload: response.data.product,
                    });

                    toast.success('Product added successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};
export const addWalmartProductForApproval = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-walmart-product`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: ADD_PRODUCT_FOR_APPROVAL,
                        payload: response.data.product,
                    });

                    toast.success('Product added successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const deleteProduct = (id, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/product/delete/${id}`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: DELETE_STUDENT_PRODUCT,
                        payload: id,
                    });

                    toast.success('Product deleted successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const getProductKeywords = (id) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/product-keywords/${id}`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {

                if(response.status === 200) {
                    dispatch({
                        type: GET_PRODUCT_KEYWORD,
                        payload: {
                            keywords: response.data.keywords ? response.data.keywords : [],
                            comments: response.data.comments ? response.data.comments : [],
                            product: response?.data?.product || null,
                            training_id: response?.data?.user_training_id || null,
                            training_slug: response?.data?.training_slug || null
                        },
                    });

                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const addProductKeyword = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-keyword`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: ADD_PRODUCT_KEYWROD,
                        payload: response.data.keyword,
                    });

                    toast.success('Product keyword added successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const deleteProductKeyword = (id) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/keyword/delete/${id}`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: DELETE_PRODUCT_KEYWORD,
                        payload: id,
                    });

                    toast.success('Keyword deleted successfully.')
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const addProductComment = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'put',
                url: `${process.env.REACT_APP_ENDPOINT}/api/product/${data.id}`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: ADD_PRODUCT_COMMENT,
                        payload: response.data.keyword,
                    });

                    toast.success('Product details added successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const getEVSDetails = (id) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/student/${id}`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: GET_EVS_DETAILS,
                        payload: response.data.user ? response.data.user.evsdetail : null,
                    });

                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const signUp = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/signup`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: STUDENT_SIGNUP,
                    });

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                toast.error(
                    (error.response && error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email.length) ?
                            error.response.data.errors.email[0]
                        :
                            'Something went wrong!'
                )
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
            })

    } catch (e) {
        toast.error(e.message || 'Something went wrong!')
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const evaluateStudentProduct = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: STUDENT_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-product`,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {

                if(response.status === 200) {

                    dispatch({
                        type: EVALUATE_STUDENT_PRODUCT,
                        payload: response.data.product,
                    });

                    toast.success('Product added successfully.')

                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')

                    dispatch({ type: STUDENT_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else {
                    dispatch({ type: STUDENT_LOADING, payload: false });
                }
            })

    } catch (e) {
        dispatch({ type: STUDENT_LOADING, payload: false });
    }
};

export const updateProduct = (product) => dispatch => {
    dispatch({
        type: UPDATE_STUDENT_PRODUCT,
        payload: product
    })
}
