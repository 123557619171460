import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import Input from 'components/Input'
import Memoized from './Memoized'
import InputPicker from 'components/Input/InputPicker'
import PickerInput from './PickerInput'
import Message from 'components/validationMessage/Message'

export default function AddProductDetails({
    formik,
    loading,
    // addKeyword,
    // doneHandler,
    // productKeywords,
    past5YGT, setPast5YGT,
    // keywords, setKeywords,
    jsScreenWS, setJSScreenWS,
    // jsGiveAPD, setJSGiveAPD,
    // jsExactSV, setJSExactSV,
    // jsBroadSV, setJSBroadSV,
    jsScreenWRS, setJSScreenWRS,
    helium10SWS, setHelium10SWS,
    // helium10SV, setHelium10SV,
    profitMargin, setProfitMargin,
    helium10MKSH, setHelium10MKSH,
    cereboRKSWSVS, setCereboRKSWSVS,
    // helium10SWRS, setHelium10SWRS,
    // helium10NOGA, setHelium10NOGA,

    setSubmitted,
    currency, setCurrency,
    availableBudget, setAvailableBudget,
    // averageReviewsOfTop10, setAverageReviewsOfTop10,
    averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
    // inputHelium10Exactsearch, setInputHelium10Exactsearch,
    averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
    helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
    helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
    helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
}) {
    const [key, setKey1] = useState(Math.random())

    return (
        <div className="main-information-wrapper">
            <section className="dash-content-wrapper pro-keywords pt-5 pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase">keyword details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5 ">
                            <div className="form-row ">

                                <div className={`form-group col-md-12 mb-4`}>
                                    <label htmlFor='keywords'>Keyword <span style={{color: "red"}}>*</span></label>
                                    <input
                                        id="keywords"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Enter Keywords"
                                        {...formik.getFieldProps('keywords')}
                                    />
                                    {formik.touched.keywords && formik.errors.keywords ? (<Message value={formik.errors.keywords} />) : null}
                                </div>

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='competing_products'>Competing Products <span style={{color: "red"}}>*</span></label>
                                    <input
                                        id="competing_products"
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Enter Competing Products"
                                        {...formik.getFieldProps('competing_products')}
                                    />
                                    {formik.touched.competing_products && formik.errors.competing_products ? (<Message value={formik.errors.competing_products} />) : null}
                                </div>

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='jsExactSV'>JS Exact SV <span style={{color: "red"}}>*</span></label>
                                    <input
                                        id="jsExactSV"
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        {...formik.getFieldProps('jsExactSV')}
                                        placeholder="Enter JS Exact SV"
                                    />
                                    {formik.touched.jsExactSV && formik.errors.jsExactSV ? (<Message value={formik.errors.jsExactSV} />) : null}
                                </div>

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='jsBroadSV'>JS Broad Search Volume <span style={{color: "red"}}>*</span></label>
                                    <input
                                        id="jsBroadSV"
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        {...formik.getFieldProps('jsBroadSV')}
                                        placeholder="Enter JS Broad Search Volume"
                                    />
                                    {formik.touched.jsBroadSV && formik.errors.jsBroadSV ? (<Message value={formik.errors.jsBroadSV} />) : null}
                                </div>

                                {/*
                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='inputHelium10Exactsearch'>Helium 10 Exact SV <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        id="inputHelium10Exactsearch"
                                        placeholder="Enter Helium 10 Exact SV"
                                        {...formik.getFieldProps('inputHelium10Exactsearch')}
                                    />
                                    {formik.touched.inputHelium10Exactsearch && formik.errors.inputHelium10Exactsearch ? (<Message value={formik.errors.inputHelium10Exactsearch} />) : null}
                                </div>
                                */}

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='helium10SV'>Helium 10 Search Volume <span style={{color: "red"}}>*</span></label>
                                    <input
                                        id="helium10SV"
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        {...formik.getFieldProps('helium10SV')}
                                        placeholder="Enter Helium 10 Search Volume"
                                    />
                                    {formik.touched.helium10SV && formik.errors.helium10SV ? (<Message value={formik.errors.helium10SV} />) : null}
                                </div>

                                {/*
                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='jsGiveAPD'>JS Giveaways per day <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type="number"
                                        id="jsGiveAPD"
                                        autoComplete="off"
                                        className="form-control"
                                        {...formik.getFieldProps('jsGiveAPD')}
                                        placeholder="Enter JS Giveaways per day"
                                    />
                                    {formik.touched.jsGiveAPD && formik.errors.jsGiveAPD ? (<Message value={formik.errors.jsGiveAPD} />) : null}
                                </div>

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='helium10NOGA'>Helium10 no. of giveaways <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type="number"
                                        id="helium10NOGA"
                                        autoComplete="off"
                                        className="form-control"
                                        {...formik.getFieldProps('helium10NOGA')}
                                        placeholder="Enter Helium10 no. of giveaways"
                                    />
                                    {formik.touched.helium10NOGA && formik.errors.helium10NOGA ? (<Message value={formik.errors.helium10NOGA} />) : null}
                                </div>
                                */}

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='averageReviewsOfTop10'>Average Reviews of Top 20 <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        id="averageReviewsOfTop10"
                                        placeholder="Enter Average Reviews of Top 20"
                                        {...formik.getFieldProps('averageReviewsOfTop10')}
                                    />
                                    {formik.touched.averageReviewsOfTop10 && formik.errors.averageReviewsOfTop10 ? (<Message value={formik.errors.averageReviewsOfTop10} />) : null}
                                </div>

                                <div className={`form-group col-md-6 mb-4`}>
                                        <label htmlFor='averageSellingPriceOfTop10'>Average Selling Price of Top 20 <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        id="averageSellingPriceOfTop10"
                                        placeholder="Average Selling Price of Top 20"
                                        {...formik.getFieldProps('averageSellingPriceOfTop10')}
                                    />
                                    {formik.touched.averageSellingPriceOfTop10 && formik.errors.averageSellingPriceOfTop10 ? (<Message value={formik.errors.averageSellingPriceOfTop10} />) : null}
                                </div>

                                <InputPicker
                                    id="jsScreenWS"
                                    key={key+Math.random()}
                                    setValue={setJSScreenWS}
                                    label="JS Screenshot of top 10"
                                    name={jsScreenWS ? jsScreenWS.name : 'Choose file'}
                                />

                                <InputPicker
                                    id="jsScreenWRS"
                                    key={key+Math.random()}
                                    setValue={setJSScreenWRS}
                                    label="JS Screenshot for 11th to 20th seller"
                                    name={jsScreenWRS ? jsScreenWRS.name : 'Choose file'}
                                />

                                <InputPicker
                                    id="helium10SWS"
                                    key={key+Math.random()}
                                    setValue={setHelium10SWS}
                                    label="H10 Screenshot of top 10"
                                    name={helium10SWS ? helium10SWS.name : 'Choose file'}
                                />

                                <InputPicker
                                    key={key+Math.random()}
                                    id="helium10ExtScreenshotWithRevenueSorting"
                                    label="H10 Screenshot for 11th to 20th seller"
                                    setValue={setHelium10ExtScreenshotWithRevenueSorting}
                                    name={helium10ExtScreenshotWithRevenueSorting?.name || 'Choose file'}
                                />

                                <InputPicker
                                    key={key+Math.random()}
                                    id="helium10MagnetSearchVolumeHistory1Year"
                                    label="H10 Keyword Search History (1 Year)"
                                    setValue={setHelium10MagnetSearchVolumeHistory1Year}
                                    name={helium10MagnetSearchVolumeHistory1Year?.name || 'Choose file'}
                                />

                                <InputPicker
                                    key={key+Math.random()}
                                    id="helium10MagnetSearchVolumeHistoryAllTime"
                                    label="H10 Keyword Search History (All Time)"
                                    setValue={setHelium10MagnetSearchVolumeHistoryAllTime}
                                    name={helium10MagnetSearchVolumeHistoryAllTime?.name || 'Choose file'}
                                />

                                {/*
                                <InputPicker
                                    id="inputGroupFile05"
                                    key={key+Math.random()}
                                    setValue={setCereboRKSWSVS}
                                    name={cereboRKSWSVS ? cereboRKSWSVS.name : 'Choose file'}
                                    label="Cerebo relevant keywords screenshot with search volume sorting"
                                />
                                */}

                                <InputPicker
                                    id="helium10MKSH"
                                    key={key+Math.random()}
                                    setValue={setHelium10MKSH}
                                    label="Helium 10 main keyword search history screenshots"
                                    name={helium10MKSH ? helium10MKSH.name : 'Choose file'}
                                />
                                <InputPicker
                                    id="past5YGT"
                                    key={key+Math.random()}
                                    setValue={setPast5YGT}
                                    label="Lifetime BSR screenshot top 3"
                                    name={past5YGT ? past5YGT.name : 'Choose file'}
                                />

                                {/*
                                <InputPicker
                                    key={key+Math.random()}
                                    id="averageReviewsOfTop10"
                                    label="Average Reviews of Top 10"
                                    setValue={setAverageReviewsOfTop10SS}
                                    name={averageReviewsOfTop10SS?.name || 'Choose file'}
                                />
                                */}
                                <PickerInput
                                    formik={formik}
                                    type="number"
                                    option={currency}
                                    setOption={setCurrency}
                                    options={["USD", "UK"]}
                                    label="Available Budget*"
                                    id="availableBudget"
                                    value={availableBudget}
                                    onChange={setAvailableBudget}
                                    placeholder="Enter Available Budget"
                                />

                                <PickerInput
                                    symbol="%"
                                    formik={formik}
                                    type="number"
                                    label="Profit Margin*"
                                    id="profitMargin"
                                    value={profitMargin}
                                    onChange={setProfitMargin}
                                    placeholder="Enter Profit Margin"
                                />

                            </div>
                            <div className="pt-5 text-center">
                                {
                                    loading ?
                                        <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                            <button type="button" className="btn btn-org btn-lg text-uppercase">
                                                <ScaleLoader color="#fff" height={20} />
                                            </button>
                                        </span>
                                    :
                                        <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                            <button type="button" className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" onClick={()=>{
                                                setSubmitted(Math.random())
                                                setKey1(Math.random())
                                                formik.handleSubmit()
                                            }}>Add</button>
                                            {/* {
                                                productKeywords.length ?
                                                    <button type="button" className="btn btn-org btn-lg text-uppercase" onClick={doneHandler}>Save</button>
                                                :
                                                    <label>Please add at least one item to save</label>
                                            } */}
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
