import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchBar from "material-ui-search-bar";
import moment from 'moment'
import Lightbox from "react-awesome-lightbox"

import query from './services/query';
import Image from 'components/image';
import MyModal from 'components/Modal';
import { Grid, Checkbox, TableSortLabel } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';

const requestSearch = (value, regex = false) => ({
  value,
  regex
});

const DESC = 'desc';
const ASC = 'asc';
const DEBOUNCE_DELAY = 500;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

let isFirst = true;

export default forwardRef((props, ref) => {
  let { url, method, filter, placeholder, columns, searchColumns, loading, sortingIndex, sortingDirection, debounceDelay, dateFormat, selected, handleOneCheck, handleCheckChange } = props;

  let queryColumns = []
  let index = 0;
  columns = columns.map(col => {
    if(col.searchable) {
      index = index+1;
      return {
        ...col,
        index: index-1,
      }
    } else {
      return col;
    }
  })
  columns.map(col => {
    if(col.searchable) {
      queryColumns.push({ name: col['name'] ? col['name'] : col['id'], data: col['id'], index: col['index'] })
    }
  })
  if(searchColumns && searchColumns.length) {
    queryColumns = [...queryColumns, ...searchColumns.map((col, i) => ({ name: col, data: col, index: queryColumns.length + i }))]
  }

  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [entriesToShow, setEntriesToShow] = React.useState(10);

  const [data, setData] = useState([])
  const [drawCount, setDrawCount] = useState(1)
  const [refreshing, setRefreshing] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [recordsTotal, setRecordsTotal] = useState(0)
  const [recordsFiltered, setRecordsFiltered] = useState(0)
  const [orderColumnIndex, setOrderColumnIndex] = useState(sortingIndex || 0)
  const [orderColumnDirection, setOrderColumnDirection] = useState(sortingDirection || ASC)

  const [img, setImage] = useState('')
  const [isModal, setModal] = useState(false)

  useImperativeHandle(ref, () => ({
    refreshData: () => {
      draw()
    },
    fetchData: () => {
      alert('Fetch Data')
    }
  }));

  // Get data initially
  useEffect(() => {
    draw()
  } ,[currentPage, entriesToShow, orderColumnDirection, orderColumnIndex])

  useEffect(() => {
    if(isFirst) {
      isFirst = false;
    } else {
      debouncedDraw.current(searchInput)
    }
  }, [searchInput])

  // Set loading and request data
  const draw = (val) => {
    setRefreshing(true)
    refresh(val) 
  }

  const debouncedDraw = useRef(debounce((val) => draw(val), debounceDelay || DEBOUNCE_DELAY));

  // Requesting data
  const refresh = (val) => {
    query({
      url,
      method: method || 'get',
      data: getRequestData(val),
    })
    .then(updateTableState)
    .catch(()=>setRefreshing(false));
  }

  const getRequestData = (val) => {
    return {
        columns: getColumns(),
        start: getStartIndex(),
        length: entriesToShow,
        search: requestSearch(val ? val.trim() : searchInput.trim()),
        order: getOrderForRequest(),
        draw: drawCount,
        filter
    };
  }

  const getStartIndex = () => {
    return currentPage * entriesToShow;
  }

  const getOrderForRequest = () => {
    return [{
      column: orderColumnIndex,
      dir: orderColumnDirection
    }];
  }

  const getColumns = () => {
    return queryColumns.slice(0).map((column, index) => (Object.assign({
      searchable: true,
      orderable: true,
      index: index+1,
      search: requestSearch(''),
    }, column)));
  }

  
  
  const updateTableState = (response) => {
    const { recordsTotal, recordsFiltered, data, draw } = getResponseData(response.data);
    setRecordsTotal(recordsTotal)
    setRecordsFiltered(recordsFiltered)
    setData(data)
    setDrawCount(draw+1)
    setRefreshing(false)
  }

  const getResponseData = (data) => {
    return data;
  }

  const handleChangePage = (event, newPage) => {
    if (currentPage != newPage) {
      setCurrentPage(newPage)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setEntriesToShow(+event.target.value);
    setCurrentPage(0);
  };

  return (
    <>
      <Paper className={classes.root}>

        <Grid container style={{ marginBottom: 15 }}>
          <Grid item sm={6} className="arrow-back">
            <ArrowBackIcon onClick={() => history.goBack()} />
          </Grid>
          <Grid item sm={6} style={{ border: '1px #DCDCDC solid', borderRadius: 5 }}>
            <SearchBar placeholder={placeholder || 'Search'} value={searchInput} onCancelSearch={()=>setSearchInput('')} onChange={value=>setSearchInput(value)} />
          </Grid>
        </Grid>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  column.checkbox ?
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Checkbox
                        onChange={e=>handleCheckChange(e.currentTarget.checked, data)} 
                        checked={(selected.length > 0 && selected.length === data.length)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                  :
                  column.sortable ?
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderColumnIndex === column.index}
                        direction={orderColumnIndex === column.index ? orderColumnDirection : DESC}
                        onClick={()=>{setOrderColumnIndex(column.index); setOrderColumnDirection(orderColumnDirection === ASC ? DESC : ASC)}}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  :  
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                refreshing || loading ? 
                  null
                :
                  data && data.length ?
                    data.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >
                          {columns.map((column) => {
                            const key = column.id.split('.');
                            let value;
                            key.forEach(element => {
                              if(value) {
                                value = value[element];
                                return;
                              }
                              value = row[element];
                            });
                          return (
                            column.checkbox ?
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                              >
                                <Checkbox
                                  checked={selected.includes(row.id)} 
                                  onChange={()=>handleOneCheck(row.id, row)}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                            :
                            column.action ?
                              <TableCell key={column.id}>
                                {column.action(row, draw)}
                              </TableCell>
                            :  
                              column.custom ? 
                                <TableCell key={column.id} align={column.align}>
                                  {column.custom(data, row.id)}
                                </TableCell>  
                            :
                              column.Component ? 
                                <TableCell key={column.id} align={column.align}>
                                  <column.Component item={row} />
                                </TableCell>
                            :    
                              column.date ?
                                <TableCell key={column.id} align={column.align}>
                                  {value ? moment(value).format(dateFormat ? dateFormat : 'YYYY-MM-DD') : 'N/A'}
                                </TableCell>
                              :  
                              column.image ?
                                <TableCell key={column.id} align={column.align}>
                                  {
                                    value ?
                                      <Image style={{ marginTop: 20 }} src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${value}`} />
                                    :
                                      <i className="far fa-image fa-3x"></i>
                                  }
                                </TableCell>
                              :  
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value || 'N/A'}
                                </TableCell>
                          );
                          })}
                        </TableRow>
                      );
                    })
                  :
                    null  
              }
            </TableBody>
          </Table>
          {
            refreshing || loading ?
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ margin: 20 }} />
              </div>
            : data && data.length === 0 ?
              <span style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>No record found!</span>
            : null
          }
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={
            (searchInput !== '' || orderColumnIndex !== 0 || orderColumnDirection !== ASC) ? 
              recordsFiltered 
            : 
              recordsTotal
          }
          rowsPerPage={entriesToShow}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <MyModal show={isModal} close={()=>setModal(false)}>
        <div className='image-view'>
          <img src={img} style={{width: '100%', height: 'auto' }} />
        </div>
      </MyModal>
    </>
  );
});