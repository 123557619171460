import {
    SHOW_PASSWORDS,
    GET_REFERENCE_DATA,
    GET_REFERENCE_DATA_LOADING,
} from '../types';
import axios from 'axios';
import { unauthenticationHandler } from './authActions';

export const getReferenceData = (cb) => async (dispatch) => {
    try {
            dispatch({ type: GET_REFERENCE_DATA_LOADING, payload: true });

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ENDPOINT}/api/reference-data`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: GET_REFERENCE_DATA,
                        payload: response.data,
                    });
    
                    cb && cb();
                } else {
                    alert(response.data.message || 'Something went wrong!')
                
                    dispatch({
                        type: GET_REFERENCE_DATA_LOADING,
                        payload: false
                    });
                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({
                        type: GET_REFERENCE_DATA_LOADING,
                        payload: false
                    });
                }
            })
        
    } catch (e) {
        dispatch({ type: GET_REFERENCE_DATA_LOADING, payload: false });
    }
};

export const showPasswords = () => dispatch => {
    dispatch({
        type: SHOW_PASSWORDS
    })
}