import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { useDispatch, useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import Memoized from 'components/Memoized'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { addTrainer } from 'redux/actions/adminActions'
import { useHistory } from 'react-router-dom'
import { validateEmail } from 'utils/validation'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input'
import Message from 'components/validationMessage/Message';
import BreadCrumbs from './BreadCrumbs'

export default function AddTrainer() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { loading } = useSelector(({ adminRed }) => adminRed)

    const [phone, setPhone] = useState('')

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
              .max(60, 'Must be 60 characters or less')
              .required('Trainer Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Trainer Email is required'),
        }),
        onSubmit: values => {
            addHandler(values)
        },
    });

    const addHandler = ({ name, email }) => {
        if(name === "") {
            toast.warn('Please enter Trainer name.')
        } else if(email === '') {
            toast.warn('Please enter Trainer email.')
        } else if(!validateEmail(email)) {
            toast.warn('Please enter valid Trainer email.')
        } else if(phone === '') {
            toast.warn("Please enter Trainer phone number.")
        } else {
            dispatch(
                addTrainer(
                    {
                        name,
                        email,
                        phone
                    },
                    () => {
                        formik.resetForm()
                        setPhone('')
                        history.push('/admin-home')
                    }
                )
            )
        }
    }

    return (
        <div>
            <Memoized
                Component={NavBar}
            />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">

                        <BreadCrumbs style={{ marginBottom: 20 }} />
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <h2 className="text-center mb-4" >Add Trainer</h2>
                                                
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <div className={`form-group col-md-6 mb-4`}>
                                                        <label htmlFor='name'>Trainer Name*:</label>
                                                        <input 
                                                            id="name"
                                                            type="text" 
                                                            maxLength={60}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            {...formik.getFieldProps('name')}
                                                            placeholder="Enter Trainer Name"
                                                        />
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <Message value={formik.errors.name} />
                                                        ) : null}
                                                    </div>
                                                    <div className={`form-group col-md-6 mb-4`}>
                                                        <label htmlFor='email'>Trainer Email*:</label>
                                                        <input 
                                                            id="email"
                                                            type="text" 
                                                            maxLength={60}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            {...formik.getFieldProps('email')}
                                                            placeholder="Enter Trainer Email"
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <Message value={formik.errors.email} />
                                                        ) : null}
                                                    </div>
                                                    <div className={`form-group col-md-6 mb-4`}>
                                                        <label htmlFor={'phone'}>Trainer Phone*:</label>
                                                        <PhoneInput
                                                            value={phone}
                                                            maxLength={16}
                                                            onChange={setPhone}
                                                            className="form-control"
                                                            placeholder="Enter Trainer Phone"
                                                            style={{ borderWidth: '0px !important' }}
                                                        />
                                                    </div>
                                                </div>    

                                                {
                                                    loading ?
                                                        <button 
                                                            type="button" 
                                                            style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                            className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" 
                                                        >
                                                            <ScaleLoader color="#fff" height={15} />
                                                        </button>    
                                                    :
                                                        <button 
                                                            type="button" 
                                                            // onClick={addHandler}
                                                            onClick={formik.handleSubmit}
                                                            style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                            className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" 
                                                        >
                                                            Submit
                                                        </button>
                                                }
                                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            </div>
            
            <Memoized
                Component={Footer}
            />
        </div>
    )
}
