import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import Input from 'components/Input'
import Memoized from './Memoized'
import InputPicker from 'components/Input/InputPicker'
import PickerInput from './PickerInput'
import Message from 'components/validationMessage/Message'

export default function AddProductDetails({
    formik,
    loading,
    // keywords, setKeywords,
    // jsExactSV, setJSExactSV,
    // jsBroadSV, setJSBroadSV,
    // helium10SV, setHelium10SV,
    // jsGiveAPD, setJSGiveAPD,
    // helium10NOGA, setHelium10NOGA,
    jsScreenWS, setJSScreenWS,
    jsScreenWRS, setJSScreenWRS,
    helium10SWS, setHelium10SWS,
    // helium10SWRS, setHelium10SWRS,
    cereboRKSWSVS, setCereboRKSWSVS,
    helium10MKSH, setHelium10MKSH,
    past5YGT, setPast5YGT,
    profitMargin, setProfitMargin,
    // addKeyword,
    // doneHandler,
    // productKeywords,

    // inputHelium10Exactsearch, setInputHelium10Exactsearch,
    // averageReviewsOfTop10, setAverageReviewsOfTop10,
    averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
    helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
    helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
    helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
    averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
    availableBudget, setAvailableBudget,
    currency, setCurrency,
    helium10MainKWSVScreenshot,setHelium10MainKWSVScreenshot,
    helium10MainKWSVWITHAMZSVScreenshot,setHelium10MainKWSVWITHAMZSVScreenshot,
    helium10ExtensionCompleteScreenshot,setHelium10ExtensionCompleteScreenshot,
    averageSalesSheet,setAverageSalesSheet,
    googleTrendScreenshot,setGoogleTrendScreenshot,

    setSubmitted,
}) {
    const [key, setKey1] = useState(Math.random())


    return (
        <div className="main-information-wrapper">
            <section className="dash-content-wrapper pro-keywords pt-5 pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase">keyword details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5 ">
                            {/* <form> */}
                                <div className="form-row ">

                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10MainKWSVScreenshot"
                                        label="H10 Main KW SV Screenshot"
                                        setValue={setHelium10MainKWSVScreenshot}
                                        name={helium10MainKWSVScreenshot?.name || 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10MainKWSVWITHAMZSVScreenshot"
                                        label="H10 Main KW SV WITH AMZ SV Screenshot"
                                        setValue={setHelium10MainKWSVWITHAMZSVScreenshot}
                                        name={helium10MainKWSVWITHAMZSVScreenshot?.name || 'Choose file'}
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="helium10ExtensionCompleteScreenshot"
                                        label="H10 Extension Complete Screenshot"
                                        setValue={setHelium10ExtensionCompleteScreenshot}
                                        name={helium10ExtensionCompleteScreenshot?.name || 'Choose file'}
                                    />
                                     <InputPicker
                                        key={key+Math.random()}
                                        id="averageSalesSheet"
                                        setValue={setAverageSalesSheet}
                                        name={averageSalesSheet?.name || 'Choose file'}
                                        label="Google sheet Screenshot that has Top 10 Seller Average Reviews, Sales,Revenue of Data Spark"
                                    />
                                    <InputPicker
                                        key={key+Math.random()}
                                        id="googleTrendScreenshot"
                                        label="Google Trend Screenshot"
                                        setValue={setGoogleTrendScreenshot}
                                        name={googleTrendScreenshot?.name || 'Choose file'}
                                    />
                                    <PickerInput
                                        formik={formik}
                                        type="number"
                                        option={currency}
                                        setOption={setCurrency}
                                        options={["USD", "UK"]}
                                        label="Budget*"
                                        id="availableBudget"
                                        value={availableBudget}
                                        onChange={setAvailableBudget}
                                        placeholder="Enter Available Budget"
                                    />
                                </div>
                                <div className="pt-5 text-center">
                                    {
                                        loading ?
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-org btn-lg text-uppercase">
                                                    <ScaleLoader color="#fff" height={20} />
                                                </button>
                                            </span>
                                        :
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" onClick={()=>{
                                                    setSubmitted(Math.random())
                                                    setKey1(Math.random())
                                                    formik.handleSubmit()
                                                }}>Add</button>
                                                {/* {
                                                    productKeywords.length ?
                                                        <button type="button" className="btn btn-org btn-lg text-uppercase" onClick={doneHandler}>Save</button>
                                                    :
                                                        <label>Please add at least one item to save</label>
                                                } */}
                                            </span>
                                    }
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
