import Footer from 'components/footer'
import NavBar from 'components/navbar'
import Stepper from 'react-stepper-horizontal'
import FirstWidget from './FirstWidget'
import Profit from './Profit'
import SecondWidget from './SecondWidget'
import useProductEvaluation from './useProductEvaluation'

export default function ProductApproval() {
    const [
        step,
        next,
        done,
        progress,
        percentage,

        market, setMarket,
        sellingPrice, setSellingPrice,
        salesVolume, setSalesVolume,
        noCompSellers, setNoCompSellers,
        productCost, setProductCost,
        unitsPack, setUnitsPack,
        fbaFee, setFBAFee,
        shippingCost, setShippingCost,
        bundlingCost, setBundlingCost,
        vat, setVAT,

        isAmazonSelling, setIsAmazonSelling,
        above50Reviews, setAbove50Reviews,
        bsrStable, setBSRStable,
        priceStable, setPriceStable,
        fbmControl, setFBMControl,
        brandRestrict, setBrandRestrict,
        buyBox, setBuyBox,
        fragileItem, setFragileItem,
        hazmatProduct, setHazmatProduct,
        onlyBrandSeller, setOnlyBrandSeller,
        category, setCategory,
        asin, setASIN,

        loading,
        bsr, setBSR,
    ] = useProductEvaluation()
    
    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper pro-evaulation pt-5 pb-md-4">
                    <div className="container">
                        <div className="card mb-5">
                            <div className="card-body p-5">
                                <h2 className="mb-3 mb-md-5 blc-clr ">
                                    {step === 0 ? 'Product Evaulation' : 'Profit Calculation'}
                                </h2>
                                <Stepper steps={ [{title: 'Step One'}, {title: 'Step Two'}] } activeStep={step} />
                                

                                {
                                    step === 0 ?
                                
                                    <>
                                        <div className="progress" style={{ marginTop: 35 }}>
                                            <div className="progress-bar" style={{width:`${progress}%`}}>{progress}%</div>
                                        </div>
                                        <SecondWidget
                                            done={done}
                                            loading={loading}
                                            market={market}
                                            isAmazonSelling={isAmazonSelling}
                                            setIsAmazonSelling={setIsAmazonSelling}
                                            above50Reviews={above50Reviews}
                                            setAbove50Reviews={setAbove50Reviews}
                                            bsrStable={bsrStable}
                                            setBSRStable={setBSRStable}
                                            priceStable={priceStable}
                                            setPriceStable={setPriceStable}
                                            fbmControl={fbmControl}
                                            setFBMControl={setFBMControl}
                                            brandRestrict={brandRestrict}
                                            setBrandRestrict={setBrandRestrict}
                                            buyBox={buyBox}
                                            setBuyBox={setBuyBox}
                                            fragileItem={fragileItem}
                                            setFragileItem={setFragileItem}
                                            hazmatProduct={hazmatProduct}
                                            setHazmatProduct={setHazmatProduct}
                                            onlyBrandSeller={onlyBrandSeller}
                                            setOnlyBrandSeller={setOnlyBrandSeller}
                                            category={category}
                                            setCategory={setCategory}
                                            asin={asin}
                                            setASIN={setASIN}
                                            bsr={bsr}
                                            setBSR={setBSR}

                                            salesVolume={salesVolume} 
                                            setSalesVolume={setSalesVolume}
                                            noCompSellers={noCompSellers}
                                            setNoCompSellers={setNoCompSellers}
                                            unitsPack={unitsPack} 
                                            setUnitsPack={setUnitsPack}
                                        />
                                    </>

                                    : step === 1 ?

                                        <FirstWidget 
                                            next={next}
                                            progress={progress}
                                            percentage={percentage}
                                            market={market}
                                            setMarket={setMarket}
                                            sellingPrice={sellingPrice}
                                            setSellingPrice={setSellingPrice}
                                            salesVolume={salesVolume} 
                                            setSalesVolume={setSalesVolume}
                                            noCompSellers={noCompSellers}
                                            setNoCompSellers={setNoCompSellers}
                                            productCost={productCost}
                                            setProductCost={setProductCost}
                                            unitsPack={unitsPack} 
                                            setUnitsPack={setUnitsPack}
                                            fbaFee={fbaFee} 
                                            setFBAFee={setFBAFee}
                                            shippingCost={shippingCost} 
                                            setShippingCost={setShippingCost}
                                            bundlingCost={bundlingCost} 
                                            setBundlingCost={setBundlingCost}
                                            vat={vat} 
                                            setVAT={setVAT}
                                        />

                                    :
                                        null
                                }
                            </div>    
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />
        </div>
    )
}
