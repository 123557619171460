import Input from './components/Input'
import Picker from './components/Picker'
import useProductApproval from './useProductApproval'

export default function AddProductDetails() {
    const [
        fullName,
        setFullName,
        email,
        setEmail,
        phoneNo,
        setPhoneNo,
        cnic,
        setCNIC,
        trainer,
        setTrainer,
        training,
        setTraining,
        batch,
        setBatch,
        address,
        setAddress,
        cnicFront,
        setCNICFront,
        cnicBack,
        setCNICBack,
        addProductHandler
    ] = useProductApproval()

    return (

        <section className="main-information-wrapper">
            <div className="container">
                <div className="card my-5 p-2 p-md-5">
                    <div className="card-body">
                        <h2 className="text-uppercase pl-3 pb-3 pb-md-5 mb-0 bl-clr font-weight-bold">Enter Your details</h2>
                        <form onSubmit={e=>e.preventDefault()}>
                            <div className="form-row ">
                                <Input
                                    id="inputName"
                                    value={fullName}
                                    label="Full Name"
                                    setValue={setFullName}
                                    placeholder="Enter Your Full Name"
                                />
                                <Input
                                    value={email}
                                    id="inputEmail"
                                    setValue={setEmail}
                                    label="Email Address"
                                    placeholder="Enter Your Email"
                                />
                                <Input
                                    id="inputPhone"
                                    value={phoneNo}
                                    label="Phone Number"
                                    setValue={setPhoneNo}
                                    placeholder="Enter Your Phone Number"
                                />
                                <Input
                                    value={cnic}
                                    label="CNIC"
                                    id="inputCINC"
                                    setValue={setCNIC}
                                    placeholder="Enter Your CNIC Number"
                                />
                                
                                <Picker
                                    value={trainer}
                                    id="inputTrainer"
                                    setValue={setTrainer}
                                    label="Select Trainer"
                                    placeholder="Select Your Trainer"
                                    options={["Saqib Azhar", "Faisal Azhar", "Khurram Khalid"]}
                                />
                                <Picker
                                    value={training}
                                    id="inputTraining"
                                    setValue={setTraining}
                                    label="Select Training"
                                    placeholder="Select Your Training"
                                    options={["ENABLING VIDEO SERIES", "AMAZON PRIVATE LABEL BOOT CAMP", "VIRTUAL ASSISTANT TRAINING"]}
                                />
                                <Picker
                                    value={batch}
                                    id="inputBatch"
                                    setValue={setBatch}
                                    label="Select Batch"
                                    placeholder="Select Your Batch"
                                    options={["XI", "XII", "XIII"]}
                                />
                                
                                <Input
                                    fillWidth
                                    value={address}
                                    label="Address"
                                    id="inputAddress"
                                    setValue={setAddress}
                                    placeholder="Enter Your Address"
                                />

                                <div className="form-group col-md-6 mb-4 mb-md-0">
                                    <label className="font-weight-bold h6 bl-clr" htmlFor="inputCinc-front">Upload CINC Front Images:</label>
                                    <div className="custom-file">
                                        <input onChange={e=>setCNICFront(e.target.files[0])} accept="image/x-png,image/jpg,image/jpeg" type="file" className="custom-file-input form-control" id="inputCinc-front" />
                                        <span className="custom-file-label form-control" htmlFor="inputCinc-front">{cnicFront ? cnicFront.name : 'Choose file'}</span>
                                    </div>
                                </div>
                                <div className="form-group col-md-6 mb-4 mb-md-0">
                                    <label className="font-weight-bold h6 bl-clr" htmlFor="inputCinc-back">Upload CINC Back Images:</label>
                                    <div className="custom-file">
                                        <input onChange={e=>setCNICBack(e.target.files[0])}accept="image/x-png,image/jpg,image/jpeg" type="file" className="custom-file-input form-control" id="inputCinc-back" />
                                        <span className="custom-file-label form-control" htmlFor="inputCinc-back">{cnicBack ? cnicBack.name : 'Choose file'}</span>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mt-3 mt-md-5">
                                    <button onClick={addProductHandler} type="submit" className="btn btn-org " value="SUBMIT">SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
            
    )
}
