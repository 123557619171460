import PRO from 'assets/images/pro-icon.png'

import NavBar from 'components/navbar'
import Footer from 'components/footer'

export default function ProductApproval() {
    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card card-total h-100 p-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center h-100">
                                        <div className="col-12 d-flex align-items-center h-100">
                                            <div className="card-icon mr-3 d-none d-md-block">
                                                <img src={PRO} />
                                            </div>
                                            <div className="card-content d-flex align-items-center flex-row justify-content-between w-100">
                                                <h4 className="text-uppercase text-white mb-0">
                                                <p className="mb-0">All Evaluated Products</p>
                                                Whole Sale
                                                </h4>
                                                <h2 className=" text-righttext-uppercase mb-0 counting text-white">12</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                <table className="table " id="dataTable">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Category Type</th>
                                        <th>Price</th>
                                        <th>Evaluation Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>
                                        <span className="font-weight-bold">Student User</span><br />
                                        <span>Amazon PL Boot Camp  | Batch 13 </span></td>
                                        <td>Garden & Outdoor</td>
                                        <td>$60</td>
                                        <td>16-Nov-2020</td>
                                        <td><button type="button" className="approve-btn">View Details</button></td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td>
                                        <span className="font-weight-bold">Student User</span><br />
                                        <span>Amazon PL Boot Camp  | Batch 13 </span></td>
                                        <td>Garden & Outdoor</td>
                                        <td>$60</td>
                                        <td>16-Nov-2020</td>
                                        <td><button type="button" className="approve-btn">View Details</button></td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td>
                                        <span className="font-weight-bold">Student User</span><br />
                                        <span>Amazon PL Boot Camp  | Batch 13 </span></td>
                                        <td>Garden & Outdoor</td>
                                        <td>$60</td>
                                        <td>16-Nov-2020</td>
                                        <td><button type="button" className="approve-btn">View Details</button></td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td>
                                        <span className="font-weight-bold">Student User</span><br />
                                        <span>Amazon PL Boot Camp  | Batch 13 </span></td>
                                        <td>Garden & Outdoor</td>
                                        <td>$60</td>
                                        <td>16-Nov-2020</td>
                                        <td><button type="button" className="approve-btn">View Details</button></td>
                                    </tr>
                                    <tr>
                                        <td>05</td>
                                        <td>
                                        <span className="font-weight-bold">Student User</span><br />
                                        <span>Amazon PL Boot Camp  | Batch 13 </span></td>
                                        <td>Garden & Outdoor</td>
                                        <td>$60</td>
                                        <td>16-Nov-2020</td>
                                        <td><button type="button" className="approve-btn">View Details</button></td>
                                    </tr>
                                    <tr>
                                        <td>06</td>
                                        <td>
                                        <span className="font-weight-bold">Student User</span><br />
                                        <span>Amazon PL Boot Camp  | Batch 13 </span></td>
                                        <td>Garden & Outdoor</td>
                                        <td>$60</td>
                                        <td>16-Nov-2020</td>
                                        <td><button type="button" className="approve-btn">View Details</button></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
            
            <Footer />
        </div>
    )
}
