import { ScaleLoader } from "react-spinners";
import Alert from '@material-ui/lab/Alert';
import './styles.css';
import { updateProduct } from 'redux/actions/studentActions';
import ImageView from "./ImageView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

export default function ProductDetails({ product, comments, loading, isImageAlert }) {
    const dispatch = useDispatch()
    const {role}=useSelector(({auth})=>auth)

    const updateProductHandler = (product) => {
        dispatch(updateProduct(product))
    }

    return (
        <div>
            <section className="dash-content-wrapper all-pro-evaulation pt-5">
                {
                    (role === "Student" && isImageAlert && !loading) ?
                        <Alert severity="warning" className='mb-3'>You have some missing images. Please upload!</Alert>
                        :
                            null
                }
                <div className="container">
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                <h2 className="mb-3 text-uppercase"> Product Details</h2>
                                {
                                    (loading) ?
                                        <div className="text-center">
                                            <ScaleLoader color="#ee6734" />
                                        </div>
                                    :
                                    <div className="container mt-4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Product Name:</span><br />
                                                <span className="title-desc">{product?.name || 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Student Name:</span><br />
                                                <span className="title-desc">{product?.user_name || 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Trainer Name:</span><br />
                                                <span className="title-desc">{product?.trainer_name || 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Training Name:</span><br />
                                                <span className="title-desc">{product?.training_name || 'N/A'}</span>
                                            </div>
                                            {/* <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Batch Name:</span><br />
                                                <span className="title-desc">{product?.batch_name || 'N/A'}</span>
                                            </div> */}
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Submitted Date:</span><br />
                                                <span className="title-desc">{product?.submitted_date || 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Trainer Approval Date:</span><br />
                                                <span className="title-desc">{product?.trainer_approval_date || 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Product Status:</span><br />
                                                <span className="title-desc">{product?.status || 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Market:</span><br />
                                                <span className="title-desc">{product?.market?.toUpperCase() || 'N/A'}</span>
                                            </div>

                                            <hr />

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Product Summary:</span><br />
                                                <span className="title-desc">{comments.filter(c => (c.user && c.user.role === 'Student')).length ? comments.filter(c => (c.user && c.user.role === 'Student'))[0]?.message : 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Trainer Remarks:</span><br />
                                                <span className="title-desc">{comments.filter(c => (c.user && c.user.role === 'Trainer')).length ? comments.filter(c => (c.user && c.user.role === 'Trainer'))[0]?.message : 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <span className="title-span">Admin Note:</span><br />
                                                <span className="title-desc">{comments.filter(c => (c.user && (c.user.role === 'Admin' || c.user.role === "Super Admin"))).length ? comments.filter(c => (c.user && (c.user.role === 'Admin' || c.user.role === "Super Admin")))[0]?.message : 'N/A'}</span>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <span className="title-span">Product Image:</span><br />
                                                <ImageView
                                                    id={product?.id}
                                                    objectKey='image'
                                                    setImage={()=>{}}
                                                    src={product?.image}
                                                    label='PRODUCT IMAGE'
                                                    endURL='upload-product-image'
                                                    updateHandler={updateProductHandler}
                                                    setModal={()=> window.open(process.env.REACT_APP_IMAGE_BASE_URL +"/" + product?.image, "_blank")}
                                                />
                                                {/* {
                                                    product?.image ?
                                                        <img
                                                            alt="product image"
                                                            src={process.env.REACT_APP_IMAGE_BASE_URL +"/" + product?.image}
                                                            className="product-img"
                                                            aria-label='Product Image'
                                                            onClick={()=> window.open(process.env.REACT_APP_IMAGE_BASE_URL +"/" + product?.image, "_blank")}
                                                        />
                                                    :
                                                        'N/A'
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
