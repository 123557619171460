import NavBar from 'components/navbar'
import Footer from 'components/footer'
import AddProductDetails from './components/AddProductDetails'
import useAddProduct from './useAddProduct'
import ProductKeywordsTable from './components/ProductKeywordsTable'
import GulfProductKeywordsTable from './components/GulfProductKeywordsTable'
import WalmartProductKeywordsTable from './components/WalmartProductKeywordsTable'
import AddProductComment from './components/AddProductComment'
import ActionButtons from './components/ActionButtons'
import ProductDetails from './components/ProductDetails';
import { useDispatch, useSelector } from "react-redux";


export default function EditProduct({ id, show, allow, isPending, isApproved, close }) {
    const  [
        loading,

        keywords, setKeywords,
        jsExactSV, setJSExactSV,
        jsBroadSV, setJSBroadSV,
        helium10SV, setHelium10SV,
        jsGiveAPD, setJSGiveAPD,
        helium10NOGA, setHelium10NOGA,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        helium10SWRS, setHelium10SWRS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        addKeywordHandler,

        comment, setComment,
        addComment,
        showContent,
        showAction,
        comments, setComments,
        role,
        product,
        isImageAlert,
    ] = useAddProduct(id, show, allow)

    const {user} = useSelector(({auth}) => auth)
    const {training_id,training_slug} = useSelector(({studentRed}) => studentRed)

    return (
        <div>
            {/* <Memoized
                Component={NavBar}
            />

            <BreadCrumbs role={role} isApproved={isApproved} isPending={isPending} style={{ marginTop: 30 }} /> */}

            <>
                {
                    showContent ?
                        <AddProductDetails
                            loading={loading}
                            keywords={keywords}
                            setKeywords={setKeywords}
                            jsExactSV={jsExactSV}
                            setJSExactSV={setJSExactSV}
                            jsBroadSV={jsBroadSV}
                            setJSBroadSV={setJSBroadSV}
                            helium10SV={helium10SV}
                            setHelium10SV={setHelium10SV}
                            jsGiveAPD={jsGiveAPD}
                            setJSGiveAPD={setJSGiveAPD}
                            helium10NOGA={helium10NOGA}
                            setHelium10NOGA={setHelium10NOGA}
                            jsScreenWS={jsScreenWS}
                            setJSScreenWS={setJSScreenWS}
                            jsScreenWRS={jsScreenWRS}
                            setJSScreenWRS={setJSScreenWRS}
                            helium10SWS={helium10SWS}
                            setHelium10SWS={setHelium10SWS}
                            helium10SWRS={helium10SWRS}
                            setHelium10SWRS={setHelium10SWRS}
                            cereboRKSWSVS={cereboRKSWSVS}
                            setCereboRKSWSVS={setCereboRKSWSVS}
                            helium10MKSH={helium10MKSH}
                            setHelium10MKSH={setHelium10MKSH}
                            past5YGT={past5YGT}
                            setPast5YGT={setPast5YGT}
                            profitMargin={profitMargin}
                            setProfitMargin={setProfitMargin}
                            productKeywords={productKeywords}
                            setProductKeywords={setProductKeywords}
                            addKeyword={addKeywordHandler}
                        />
                    :
                        <ProductDetails product={product} loading={loading} comments={comments} isImageAlert={isImageAlert} />
                }


                {
                    productKeywords ?
                        <>
                        {
                            training_slug && training_slug ==='become-a-seller-on-walmart-pl-ds-wholesale' ?
                            <>
                                <WalmartProductKeywordsTable loading={loading} show={showContent} productKeywords={productKeywords} setProductKeywords={setProductKeywords} />
                            </>
                            : training_slug && training_slug ==='become-a-seller-on-amazon-gulf-ksa-uae-noon-marketplaces' ?
                            <>
                                <GulfProductKeywordsTable loading={loading} show={showContent} productKeywords={productKeywords} setProductKeywords={setProductKeywords} />
                            </>
                            :
                            <>
                                <ProductKeywordsTable loading={loading} show={showContent} productKeywords={productKeywords} setProductKeywords={setProductKeywords} />
                            </>
                        }
                            { showContent ? <AddProductComment loading={loading} comment={comment} addComment={addComment} setComment={setComment} /> : null }
                        </>
                    :
                        null
                }

                {
                    allow ?
                        <ActionButtons id={id} close={close} />
                    : null
                }

            </>

            {/* <Footer /> */}
        </div>
    )
}
