import Header from '../../components/header'
import { useHistory } from 'react-router'

export default function ThankYou() {
    const history = useHistory()

    return (
        <div>
            {/* <!--  /Navbar --> */}
            <Header />
            
            {/* <!--     main-information-wrapper --> */}
            <section className="main-information-wrapper vh-100 d-flex align-items-center">
                {/* <!--  FORM --> */}
                <div className="container">
                    <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="card p-2 p-md-5 ">
                            <div className="card-body text-center">
                                <div className="thank-you">
                                    <i className="fas fa-hands-helping mb-3"></i>
                                    <p>Thank you for submitting your request!
                                    Team Enablers will be verifying the information within 72 working hours and will issue you credentials via email. Also please keep an eye on your Junk folder as well</p>
                                </div>
                                <button onClick={()=>history.push("/login")} style={{ height: 40, color: 'white', border: '0px', padding: '0px 25px', borderRadius: 20, backgroundColor: '#018BF0',  }}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <!--  /FORM --> */}
            </section>
            {/* <!--    /main-information-wrapper --> */}
            {/* <!--   Footer --> */}
            <footer className="footer sticky-footer bg-off-white pt-0">
                <div className="container">
                    <div className="footer-content">
                    <div className="d-flex flex-column align-items-center">
                        <p className="mb-0 px-1 text-white">Terms &amp; Conditions | Privacy policy</p>
                        <p className="mb-0 px-1 text-white">EPAS is operated by Enablers.org </p>
                    </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
