import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getEVSDetails } from 'redux/actions/studentActions'
import { useHistory } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import BreadCrumbs from './BreadCrumbs'

export default function EVSDetails() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { loading: isLoading, user } = useSelector(({auth})=>auth)
    const { loading, evs } = useSelector(({studentRed})=>studentRed)

    useEffect(() => {
        if(user && user.id) {
            if(!evs)
                dispatch(getEVSDetails(user.id))
        } else {
            history.push('/student-home')   
        }
    }, [])

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs className="mb-4" />
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                            <div className="">
                                {
                                    (isLoading || loading) ?
                                        <div className="text-center">
                                            <ScaleLoader color="#ee6734" />
                                        </div>
                                    :    
                                        (evs) ?
                                            <div>
                                                <h2 className="text-center mb-4" >EVS Details</h2>
                                                
                                                <div className="bordered-div">
                                                    <p>
                                                        <span className="heading-txt">Email: </span>
                                                        {evs.email}
                                                    </p>
                                                    <p>
                                                        <span className="heading-txt">Password: </span>
                                                        {evs.password}
                                                    </p>
                                                    <p>
                                                        <span className="heading-txt">Url: </span>
                                                        <a href="https://evs.enablers.org/" target="_blank">evs.enablers.org</a>
                                                    </p>
                                                </div>
                                            </div>
                                        :    
                                            <h2 style={{ textAlign: 'center' }}>Your Account is Under Process</h2>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            </div>
            
            <Footer />
        </div>
    )
}
