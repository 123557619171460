import React from 'react'
import { ScaleLoader } from 'react-spinners'

export default function DeactivationReason({ reason, setReason, loading, submitHandler }) {
    return (
        <div  style={{ minWidth: '50vw' }}>
            <div className="form-group">
                <label htmlFor="comments">Reason</label>
                <textarea placeholder="Specify reason..." value={reason} onChange={e=>setReason(e.target.value)} name="message" id="comments" className="form-control" rows="10" required="" style={{marginTop: 0, marginBottom: 0, height: 241}}></textarea>
            </div>
            
            {
                loading ?
                    <button type="button" className="btn aree-btn btn-secondary  text-uppercase text-white rdu-50 px-4 mb-3">
                        <ScaleLoader color='#fff' height={15} />
                    </button>
                : 
                    <button onClick={submitHandler} type="button" className="btn aree-btn btn-secondary  text-uppercase text-white rdu-50 px-4 mb-3">
                        Submit
                    </button>
            }
        </div>
    )
}
