import DROPBOX from 'assets/images/dropbox-icon.png'
import PRO from 'assets/images/pro-icon.png'

import NavBar from 'components/navbar'
import Footer from 'components/footer'

export default function ApprovedProduct() {
    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">

                <section className="dash-content-wrapper pro-keywords pt-5 pb-md-2">
                    <div className="container">
                        <h2 className="mb-3 text-white text-uppercase">keyword details</h2>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-5 ">
                                <form>
                                    <div className="form-row ">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputKeywords">Keywords</label>
                                        <input
                                            autoComplete="off" type="text" className="form-control" id="inputKeywords" placeholder="Enter Keywords" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputExactsearch">JS Exact Search Volume</label>
                                        <input
                                            autoComplete="off" type="text" className="form-control" id="inputExactsearch" placeholder="Enter JS Exact Search Volume" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputBroadsearch">JS Broad Search Volume</label>
                                        <input
                                            autoComplete="off" type="text" className="form-control" id="inputBroadsearch" placeholder="Enter JS Broad Search Volume" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputHelium10search">Helium 10 Search Volume</label>
                                        <input
                                            autoComplete="off" type="text" className="form-control" id="inputHelium10search" placeholder="Enter Helium 10 Search Volume" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputJSgiveaways">JS Giveaways per day</label>
                                        <input
                                            autoComplete="off" type="text" className="form-control" id="inputJSgiveaways" placeholder="JS Giveaways per day" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputHelium10">Helium10 no. of giveaways</label>
                                        <input
                                            autoComplete="off" type="text" className="form-control" id="inputHelium10" placeholder="Enter Helium10 no. of giveaways" required />
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile01" style={{ width: "100%" }}>JS Screenshot without sorting</label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input form-control" id="customFile" required />
                                            <label className="custom-file-label " htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile02" style={{ width: "100%" }}>JS screenshot with revenue sorting</label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input" id="customFile" required />
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile03" style={{ width: "100%", marginTop: 10 }}>Helium 10 Screenshot without sorting </label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input" id="customFile" required />
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile12" style={{ width: "100%", marginTop: 10 }}>Helium 10 Screenshot with revenue sorting</label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input" id="customFile" required />
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile04" style={{ width: "100%", marginTop: 10 }}>Cerebo relevant keywords screenshot with search volume sorting</label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input" id="customFile" required />
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile13" style={{ width: "100%", marginTop: 10 }}>Helium 10 main keyword search history screenshots</label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input" id="customFile" required />
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="input-group col-md-6">
                                        <label htmlFor="inputGroupFile05" style={{ width: "100%", marginTop: 10 }}>Past 5 years google trends</label>
                                        <div className="custom-file">
                                            <input
                                            autoComplete="off" type="file" className="custom-file-input" id="customFile" required />
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="pt-5 text-center">
                                        <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                            <button type="button" className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3">Add More</button>
                                            <button type="button" className="btn btn-org btn-lg text-uppercase">Save</button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <Footer />
        </div>
    )
}
