import React from 'react'

export default function Input({ disabled, value, setValue, helper, placeholder, type, error }) {
    return (
        <div className="flex-base">
            {
                helper ?
                    <div className='input-help'>{helper}</div>
                :
                    null
            }
            <input 
                autoComplete="off" 
                disabled={disabled} 
                value={value || ''} 
                onChange={e=>setValue(e.target.value)} 
                placeholder={placeholder} 
                type={type ? type : "number"} 
                name="" 
                className={`w-100 ${helper ? 'evaluation-input' : 'normal-input'}`}
            />
            
            {error && error !== '' ?
                <div className='error-message'>{error}</div>
            :
                null
            }
        </div>
    )
}
