import { PLTrainigID, trainigID, VATrainingID, OneToOneTrainingID } from "constants/constants";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsCount } from "redux/actions/studentActions";

const useStudentData = () => {
    const dispatch = useDispatch()
    const [canAddProduct, setCanAddProduct] = useState(false)
    const [isPopup, setIsPopup] = useState(false)

    const { user } = useSelector(({ auth }) => auth)

    const {
        pending,
        loading,
        approved,
        archived,
    } = useSelector(({ studentRed }) => studentRed)

    useEffect(() => {
        if(user.training_id == trainigID) {
            dispatch(getProductsCount(true))
        } else {
            dispatch(getProductsCount())
        }
    }, [])

    useEffect(() => {
        if(archived && archived > 0) {
            let isFirst = localStorage.getItem('isFirst')
            if(!isFirst) {
                setIsPopup(true)
                localStorage.setItem('isFirst', 'no')
            }
        }
    }, [archived])

    useEffect(() => {
        if(pending > 0) {
            setCanAddProduct(false)
        }
        // else if(user.training_id == PLTrainigID || user.training_id == OneToOneTrainingID) { // removing || user.training_id == VATrainingID condition
        //     if(approved >= 2) {
        //         setCanAddProduct(false)
        //     } else {
        //         setCanAddProduct(true)
        //     }
        // }
        else if(approved >= 1) {
            setCanAddProduct(false)
        }
        else {
            setCanAddProduct(true)
        }
    }, [pending, approved])

    return [
        pending,
        approved,
        loading,
        user,
        canAddProduct,
        archived,
        isPopup,
        setIsPopup
    ]
}

export default useStudentData;
