import React from 'react'
import USER from 'assets/images/user-icon.png'
import EBMS from 'assets/images/ebms-user-logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'redux/actions/authActions'
import { toast } from 'react-toastify'
import { trainigID } from 'constants/constants'

export default function NavBar() {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        user,
        role
    } = useSelector(({ auth }) => auth)

    const logoutHandler = () => {
        dispatch(logout(()=>history.push('/login')))
    }

    return (
        <div>

            {/* Maintenance warning */}
            {/* <div style={{ padding: '3px 0px', backgroundColor: 'red', width: '100%' }}>
                <h6 style={{ textAlign: 'center', color: 'white', fontWeight: 500 }}>
                We are planning to bring the site down for regular maintenance. It will take 48 hours. Dates will be announced later.
                </h6>
            </div> */}

            <header id="header">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-dark header-nav" >
                    <div className="embs-logo d-flex flex-row align-items-center " style={{ cursor: 'pointer' }} onClick={()=>history.push('/')}>
                        <img src={EBMS} className="navbar-brand" />
                        <h4 className="logo-tie d-none d-md-block m-0 px-3">Enablers Product <br />Approval System</h4>
                    </div>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                        <ul className="navbar-nav align-items-start align-items-md-center">
                            <li className="nav-item">
                                <a className="nav-link active text-uppercase" style={{ cursor: 'pointer' }} onClick={()=>history.push(role === 'Student' ? '/student-home' : '/admin-home')}>Home</a>
                            </li>
                            {
                                role === 'Student' ?
                                    <>
                                        {
                                            user.training_id == trainigID ?
                                                <>
                                                    <li className="nav-item">
                                                        <a className="nav-link text-uppercase" style={{ cursor: 'pointer' }} onClick={()=>history.push("/product-evaluation")}>Product evaluate</a>
                                                    </li>
                                                </>
                                            :
                                                null
                                                // <li className="nav-item text-uppercase">
                                                //     <a className="nav-link" style={{ cursor: 'pointer' }} onClick={()=>history.push('/saved-products')}>Archived Products</a>
                                                // </li>
                                        }
                                        <li className="nav-item text-uppercase">
                                            <a className="nav-link" style={{ cursor: 'pointer' }} onClick={()=>history.push(user.training_id == trainigID ? '/student-evaluated-products' : '/student-all-products')}>All Products</a>
                                        </li>
                                        <li className="nav-item text-uppercase">
                                            <a className="nav-link" style={{ cursor: 'pointer' }} onClick={()=>history.push('/evs-details')}>EVS Credentials</a>
                                        </li>
                                    </>
                                :
                                    null
                            }
                            {/* <li className="nav-item dropdown no-arrow mx-1 d-none d-md-inline-block">
                                <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="far fa-bell fa-fw"></i>
                                <span className="badge badge-danger badge-counter">3+</span>
                                </a>
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                                <h6 className="dropdown-header">
                                    Alerts Center
                                </h6>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 12, 2019</div>
                                        <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-success">
                                            <i className="fas fa-donate text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 7, 2019</div>
                                        $290.29 has been deposited into your account!
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-warning">
                                            <i className="fas fa-exclamation-triangle text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 2, 2019</div>
                                        Spending Alert: We've noticed unusually high spending for your account.
                                    </div>
                                </a>
                                <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                                </div>
                            </li> */}
                            <li className="nav-item dropdown no-arrow notic user-info">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {/* <!--                 <span className="mr-2 d-none d-lg-inline text-gray-600 small">Valerie Luna</span> --> */}
                                    {
                                        role === "Student" ?
                                            <i className="fas fa-user-graduate fa-1x" style={{ color: "white"}}></i>
                                        : (role === "Admin" || role === "Super Admin") ?
                                            <i className="fas fa-user-cog fa-1x" style={{ color: "white"}}></i>
                                        :
                                            <i className="fas fa-user fa-1x"></i>
                                    }
                                    {/* <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" /> */}
                                </a>
                                {/* <!-- Dropdown - User Information --> */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                {
                                    role === 'Student' ?
                                        <>
                                            <a onClick={()=>history.push('/student-profile')} className="dropdown-item cursor-pointer">
                                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Profile
                                            </a>
                                            <div className="dropdown-divider"></div>
                                        </>
                                    :
                                        null
                                }
                                {/* <a className="dropdown-item" href="#">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                                </a>
                                <a className="dropdown-item" href="#">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                                </a> */}
                                <a onClick={logoutHandler} className="dropdown-item cursor-pointer" data-toggle="modal" data-target="#logoutModal">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    </nav>
                </div>
            </header>

            {/* <!--  FORM --> */}
            <section className="banner-wrapper d-flex align-items-center ">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="user-img">
                                {/* <img src={USER} /> */}
                                {
                                    role === "Student" ?
                                        <i className="fas fa-user-graduate fa-3x" style={{ color: "white"}}></i>
                                    : (role === "Admin" || role === "Super Admin") ?
                                        <i className="fas fa-user-cog fa-3x" style={{ color: "white"}}></i>
                                    :
                                        <i className="fas fa-user fa-3x" style={{ color: "white"}}></i>
                                }
                            </div>
                            <div className="pl-3">
                                <h2 className="st-ur text-white mb-0">{user ? user.name.toUpperCase() || 'N/A' : 'N/A'}</h2>
                                <p className="st-des text-white mb-0">{role || 'N/A'}</p>
                                {
                                    role === 'Student' ?
                                        user.training_name ?
                                            <span className="st-tr-bch text-white"> {user.training_name || 'N/A'}</span>
                                                : null
                                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
