import { useHistory } from 'react-router-dom'

import DROPBOX from 'assets/images/dropbox-icon.png'

import Card from 'components/card'
import NavBar from 'components/navbar'
import Footer from 'components/footer'
import Memoized from 'components/Memoized'

export default function EditorHome() {
    const history = useHistory()

    return (
        <div>
            <Memoized
                Component={NavBar}
            />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper pt-5 pb-md-4">
                    <div className="container">
                        <div className="row">
                            
                            <Card
                                value=''
                                image={DROPBOX}
                                onClick={()=>history.push('/view-editor-students')}
                                title='View Students'
                            />

                        </div>
                    </div>
                </section>
            
            </div>
            
            <Memoized
                Component={Footer}
            />
        </div>
    )
}
