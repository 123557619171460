import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import ReactDOM from 'react-dom';
import { addProductForApproval, getProductsCount } from 'redux/actions/studentActions';
import { PLTrainigID, trainigID, VATrainingID, OneToOneTrainingID } from "constants/constants";

let isFirst = 0;

const useAddProduct = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        loading,
        pending,
        approved,
    } = useSelector(({ studentRed }) => studentRed)
    const {user} = useSelector(({auth}) => auth)

    useEffect(() => {
        dispatch(getProductsCount())
        setSubmitted(Math.random())
    }, [])

    useEffect(()=>{
        if(user.training_id == trainigID) {
            history.push('/student-home')
        } else if(pending > 0) {
            toast.warn('You already have pending product in your queue')
            history.push('/student-home')
        } else if(user.training_id == PLTrainigID || user.training_id == VATrainingID || user.training_id == OneToOneTrainingID) {
            if(approved >= 2) {
                toast.warn('You have already 2 approved products in the system')
                history.push('/student-home')
            }
        } else if(approved >= 1) {
            toast.warn('You have already 1 approved product in the system')
            history.push('/student-home')
        }
    }, [pending, approved])

    const [productKeywords, setProductKeywords] = useState([])

    const [index, setIndex] = useState(0)
    const [name, setName] = useState('')
    const [market, setMarket] = useState('')
    const [image, setImage] = useState(null)

    const [keywords, setKeywords] = useState('')
    const [jsExactSV, setJSExactSV] = useState('')
    const [jsBroadSV, setJSBroadSV] = useState('')
    const [helium10SV, setHelium10SV] = useState('')
    const [jsGiveAPD, setJSGiveAPD] = useState('')
    const [helium10NOGA, setHelium10NOGA] = useState('')
    const [jsScreenWS, setJSScreenWS] = useState(null)
    const [jsScreenWRS, setJSScreenWRS] = useState(null)
    const [helium10SWS, setHelium10SWS] = useState(null)
    const [helium10SWRS, setHelium10SWRS] = useState(null)
    const [cereboRKSWSVS, setCereboRKSWSVS] = useState(null)
    const [helium10MKSH, setHelium10MKSH] = useState(null)
    const [past5YGT, setPast5YGT] = useState(null)
    const [profitMargin, setProfitMargin] = useState('')

    const [inputHelium10Exactsearch, setInputHelium10Exactsearch] = useState('')
    const [averageReviewsOfTop10, setAverageReviewsOfTop10] = useState('')
    const [averageSellingPriceOfTop10, setAverageSellingPriceOfTop10] = useState('')
    const [helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting] = useState(null)
    const [helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year] = useState(null)
    const [helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime] = useState(null)
    const [averageReviewsOfTop10SS, setAverageReviewsOfTop10SS] = useState(null)
    const [availableBudget, setAvailableBudget] = useState('')
    const [currency, setCurrency] = useState('usd')

    const [comment, setComment] = useState('')
    const [prohibited, setProhibited] = useState(false)
    const [isModal, setModal] = useState(false)
    const [isKeywordConfirmation, setKeywordConfirmation] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)

    const formik = useFormik({
        initialValues: {
            // name: '',
            // market: '',
            keywords: '',
            jsExactSV: '',
            jsBroadSV: '',
            // inputHelium10Exactsearch: '',
            helium10SV: '',
            competing_products: '',
            // jsGiveAPD: '',
            // helium10NOGA: '',
            averageReviewsOfTop10: '',
            averageSellingPriceOfTop10: '',
            availableBudget: '',
            profitMargin: ''
        },
        validationSchema: Yup.object({
            keywords: Yup.string()
                .max(255, 'Must be 255 characters or less')
                .required('Product Keywrords are required'),
            jsExactSV: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('JS Exact Search Volume is required'),
            jsBroadSV: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('JS Broad Search Volume is required'),
            // inputHelium10Exactsearch: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('Helium 10 Exact Search Volume is required'),
            helium10SV: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Helium 10 Search Volume is required'),
            // jsGiveAPD: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('JS Giveaways per day is required'),
            // helium10NOGA: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('Helium10 no. of giveaways is required'),
            averageReviewsOfTop10: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Average Reviews of Top 10 is required'),
            averageSellingPriceOfTop10: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Average Selling Price of Top 10 is required'),
            availableBudget: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Available Budget is required'),
            profitMargin: Yup.string()
                .required('Profit Margin is required')
                .test('Must be 100% or less', 'Must be 100% or less', value =>
                    value <= 100
                )
        }),
        onSubmit: values => {
            addKeywordHandler(values)
        },
    });

    window.onbeforeunload = function () {return false}

    useEffect(() => {
        if(isFirst < 2) {
            isFirst++;
            setSubmitted(Math.random())
        }
    }, [Object.keys(formik.errors).length])

    useEffect(() => {
        let elem = ReactDOM.findDOMNode(document.getElementById(Object.keys(formik.errors)[0]));

        if(elem) {
            elem.focus()
        }
    }, [isSubmitted])

    const nextHandler = () => {
        if(name === '') {
            toast.warn('Please enter Product Name.')
        } else if(market === '') {
            toast.warn('Please enter Product Market.')
        } else if(!image) {
            toast.warn('Please select Product Image.')
        } else {
            setIndex(1)
            setProhibited(true)
        }
    }

    const onKeywordConfirmation = () => {
        setKeywordConfirmation(false)
        setTimeout(() => {
            window.scrollTo(0, 300)
        }, 500);
    }

    const onKeywordCanelation = () => {
        setKeywordConfirmation(false)
        setTimeout(() => {
            window.scrollTo(0, 2500)
        }, 500);
    }

    const addToArray = ({
        keywords, jsExactSV, jsBroadSV, helium10SV, competing_products, jsGiveAPD, helium10NOGA,
        inputHelium10Exactsearch, averageReviewsOfTop10, averageSellingPriceOfTop10,
        availableBudget, profitMargin, helium_10_magnet_search_volume_history_all_time
    }) => {
        if(keywords === '') { toast.warn('Please enter Product Keywords.') }
        else if(jsExactSV === '') { toast.warn('Please enter JS Exact Search Volume.') }
        else if(jsBroadSV === '') { toast.warn('Please enter JS Broad Search Volume.') }
        else if(helium10SV === '') { toast.warn('Please enter Helium 10 Search Volume.') }
        else if(!jsScreenWS) {
            toast.warn('Please select JS Screenshot of top 10.')
            ReactDOM.findDOMNode(document.getElementById('jsScreenWS')).focus()
        } else if(!jsScreenWRS) {
            toast.warn('Please select JS screenshot for 11th to 20th seller.')
            ReactDOM.findDOMNode(document.getElementById('jsScreenWRS')).focus()
        } else if(!helium10SWS) {
            toast.warn('Please select Helium 10 Screenshot of top 10.')
            ReactDOM.findDOMNode(document.getElementById('helium10SWS')).focus()
        } else if(averageReviewsOfTop10 === '') { toast.warn('Please enter Average Reviews Of Top 20.') }
        else if(averageSellingPriceOfTop10 === '') { toast.warn('Please enter Average Selling Price of Top 20') }
        else if(!helium10ExtScreenshotWithRevenueSorting) {
            toast.warn("Please select Helium 10 Ext Screen for 11th to 20th seller.")
            ReactDOM.findDOMNode(document.getElementById('helium10ExtScreenshotWithRevenueSorting')).focus()
        } else if(!helium10MagnetSearchVolumeHistory1Year) {
            toast.warn("Please select Helium 10 Magnet Search Volum History 1 Year")
            ReactDOM.findDOMNode(document.getElementById('helium10MagnetSearchVolumeHistory1Year')).focus()
        } else if(!helium10MKSH) {
            toast.warn('Please select Helium 10 main keyword search history screenshots.')
            ReactDOM.findDOMNode(document.getElementById('helium10MKSH')).focus()
        } else if(!past5YGT) {
            toast.warn('Please select Lifetime BSR screenshot top 3.')
            ReactDOM.findDOMNode(document.getElementById('past5YGT')).focus()
        }
        else if(availableBudget === '') { toast.warn('Please anter Available Budget.')}
        else if(profitMargin === '') { toast.warn('Please anter Profit Margin.') }
        else {
            let productKWs = [...productKeywords];

            let kwrd = {
                keyword: keywords,
                competing_products,
                js_exact_search_volume: jsExactSV,
                js_broad_search_volume: jsBroadSV,
                // helium_10_exact_search: inputHelium10Exactsearch, // Removed 1
                helium10_search_volume: helium10SV,
                // js_giveaways_per_day: jsGiveAPD, // Removed 2
                // helium10_giveaways: helium10NOGA, // Removed 3
                average_reviews_of_top_10: averageReviewsOfTop10,
                average_selling_price_of_top_10: averageSellingPriceOfTop10,
                profit_margin: profitMargin,
                available_budget: availableBudget,
                currency,

                js_screenshot_without_sorting: jsScreenWS,
                js_screenshot_with_revenue_sorting: jsScreenWRS,
                helium10_screenshot_without_sorting: helium10SWS,
                google_trend_past_5_years: past5YGT,
                // cerebro_relevant_keywords: cereboRKSWSVS, // Removed 4
                helium10_main_keyword: helium10MKSH,
                helium_10_ext_screenshot_with_revenue_sorting: helium10ExtScreenshotWithRevenueSorting,
                helium_10_magnet_search_volume_history_1_year: helium10MagnetSearchVolumeHistory1Year,
                helium_10_magnet_search_volume_history_all_time: helium10MagnetSearchVolumeHistoryAllTime,
                // average_reviews_of_top_10_ss: averageReviewsOfTop10SS, // Removed 6
            }

            productKWs.push(kwrd);
            setProductKeywords(productKWs);
            return true;
        }

        return false;
    }

    const addKeywordHandler = (values) => {
        let added = addToArray(values);
        if(added) {
            setEmptyDetails()
            setKeywordConfirmation(true)
        }
    }

    const setEmptyBasics = () => {
        setName('')
        setMarket('')
        setImage(null)
    }

    const setEmptyDetails = () => {
        formik.resetForm();
        setKeywords('')
        setJSExactSV('')
        setJSBroadSV('')
        setHelium10SV('')
        setJSGiveAPD('')
        setHelium10NOGA('')
        setJSScreenWS(null)
        setJSScreenWRS(null)
        setHelium10SWS(null)
        setHelium10SWRS(null)
        setCereboRKSWSVS(null)
        setHelium10MKSH(null)
        setPast5YGT(null)
        setProfitMargin('')
        setInputHelium10Exactsearch('')
        setAverageReviewsOfTop10('')
        setAverageSellingPriceOfTop10('')
        setHelium10ExtScreenshotWithRevenueSorting(null)
        setHelium10MagnetSearchVolumeHistory1Year(null)
        setHelium10MagnetSearchVolumeHistoryAllTime(null)
        setAverageReviewsOfTop10SS(null)
        setAvailableBudget('')
        setCurrency('usd')
    }

    const doneHandler = () => {
        if(loading) return;

        if(productKeywords && productKeywords.length) {
            if(comment === '') {
                toast.warn('Please add Product Details.')
            } else {
                let formData = new FormData();

                formData.append('name', name)
                formData.append('image', image)
                formData.append('market', market)
                formData.append('message', comment)
                formData.append('len', productKeywords.length)

                // productKeywords.map((pK, i) => {
                //     Object.keys(pK).map(key => {
                //         formData.append(`keywords${i}.${key}`, pK[key])
                //     })
                // })

                productKeywords.map((pK, i) => {
                    Object.keys(pK).map(key => {
                        formData.append(`keywords[${i}][${key}]`, pK[key])
                    })
                })

                setProhibited(false)
                dispatch(addProductForApproval(formData, () => {
                    setEmptyBasics();
                    setEmptyDetails();
                    history.push('/student-all-products')
                }))
            }
        } else {
            toast.warn('Please add at least one keyword.')
        }
    }

    return [
        formik,
        loading,
        index,
        setIndex,
        name,
        setName,
        market,
        setMarket,
        image,
        setImage,
        nextHandler,

        keywords, setKeywords,
        jsExactSV, setJSExactSV,
        jsBroadSV, setJSBroadSV,
        helium10SV, setHelium10SV,
        jsGiveAPD, setJSGiveAPD,
        helium10NOGA, setHelium10NOGA,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        helium10SWRS, setHelium10SWRS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        addKeywordHandler,
        doneHandler,
        comment, setComment,
        doneHandler,
        prohibited, setProhibited.products,
        isModal, setModal,

        inputHelium10Exactsearch, setInputHelium10Exactsearch,
        averageReviewsOfTop10, setAverageReviewsOfTop10,
        averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
        helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
        helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
        helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
        averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
        availableBudget, setAvailableBudget,
        currency, setCurrency,
        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ]
}

export default useAddProduct;
