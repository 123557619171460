import ConfirmationAlert from 'components/confimationAlert';
import Modal from 'components/Modal/index.js';
import React, { useState } from 'react'
import ImageView from 'screens/editPrivateLabelProduct/components/ImageView';

export default function ProductKeywordsTable({
    productKeywords,
    setProductKeywords
}) {
    const [index, setIndex] = useState(null)
    const [isRemoveConfirmation, setRemoveConfirmation] = useState(false)

     const getRow = (data, index) => {
         let row = [];

        let values = Object.values(data)
        values = values.slice(9, values.length)
        values = values.map(val => URL.createObjectURL(val))

        Object.keys(data).map((key, i) => {
            if(i <= 8) {
                row.push(<td className="keyword-td">{data[key]}</td>)
            } else {
                row.push(<td className="keyword-td">
                    <ImageView
                        id={i}
                        index={i-9}
                        absolutePath
                        srcs={values}
                        setImage={()=>{}}
                        src={URL.createObjectURL(data[key])}
                    />
                </td>)
            }
        })
        
        row.push(
            <td className="keyword-td"><button type="button" className="approve-btn" onClick={()=>{setRemoveConfirmation(true); setIndex(index)}}>Remove</button></td>
        )

        return <tr>{row}</tr>
    }

    const removeItem = () => {
        let ps = [...productKeywords];
        ps = ps.filter((k, i) => i !== index);
        setProductKeywords(ps);
        setRemoveConfirmation(false);
    }

    return (
        <div>
            <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                <div className="container">
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    <h2 className="mb-3 text-uppercase">keywords</h2>
                                <table className="table " id="dataTable">

                                    <thead>
                                        <tr>
                                            <th className="keyword-td">KEYWORDS</th>
                                            <th className="keyword-td">JS EXACT SEARCH VOLUME</th>
                                            <th className="keyword-td">JS BROAD SEARCH VOLUME</th>
                                            {/* <th className="keyword-td">Helium 10 Exact Search Volume</th> */}
                                            <th className="keyword-td">HELIUM 10 SEARCH VOLUME</th>
                                            {/* <th className="keyword-td">JS GIVEAWAY PER DAY</th> */}
                                            {/* <th className="keyword-td">HELIUM 10 NO. OF GIVEAWAYS</th> */}
                                            <th className="keyword-td">Average Reviews of Top 10</th>
                                            <th className="keyword-td">Average Selling Price of Top 10</th>
                                            <th className="keyword-td">PROFIT MARGIN</th>
                                            <th className="keyword-td">Available Budget</th>
                                            <th className="keyword-td">Currecncy</th>

                                            <th className="keyword-td">JS SCREENSHOT WITHOUT SORTING</th>
                                            <th className="keyword-td">JS SCREENSHOT WITH REVENUE SORTING</th>
                                            <th className="keyword-td">HELIUM 10 SCREENSHOT WITHOUT SORTING</th>
                                            {/* <th className="keyword-td">HELIUM 10 SCREENSHOT WITH REVENUE SORTING</th> */}
                                            <th className="keyword-td">PAST 5 YEARS GOOGLE TRENDS</th>
                                            {/* <th className="keyword-td">CEREBRO RELEVANT KEYWORDS SCREEN SHOT WITH SEARCH VOLUME SORTING</th> */}
                                            <th className="keyword-td">HELIUM10 MAIN KEYWORD SEARCH HISTORY SCREEN SHOTS</th>
                                            <th className="keyword-td">Helium 10 Ext Screenshot with revenue sorting</th>
                                            <th className="keyword-td">Helium 10 Magnet Search Volume History 1 Year</th>
                                            {/* <th className="keyword-td">Helium 10 Magnet Search Volume History All Time</th> */}
                                            {/* <th className="keyword-td">Average Reviews of Top 10</th> */}
                                            

                                            <th className="keyword-td"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productKeywords.map((pKeywrod, i) => (
                                                getRow(pKeywrod, i)
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Modal fullWidth show={isRemoveConfirmation} close={()=>{}} hideClose={true}>
                <ConfirmationAlert 
                    title="Are you sure you want to delete this keyword?"
                    description=""
                    onConfirm={()=>removeItem()}
                    onCancel={()=>setRemoveConfirmation(false)}
                />
            </Modal>
        </div>
    )
}