// Auth Types
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const LOGIN_LOADING_ON = 'LOGIN_LOADING_ON'
export const LOGIN_LOADING_OFF = 'LOGIN_LOADING_OFF'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'
export const GET_CURRENT_USER_LOADING_ON = 'GET_CURRENT_USER_LOADING_ON'
export const GET_CURRENT_USER_LOADING_OFF = 'GET_CURRENT_USER_LOADING_OFF'


// All Students Types
export const STUDENT_SIGNUP = 'STUDENT_SIGNUP'
export const STUDENT_LOADING = 'STUDENT_LOADING'
export const GET_EVS_DETAILS = 'GET_EVS_DETAILS'
export const GET_PRODUCT_KEYWORD = 'GET_PRODUCT_KEYWORD'
export const ADD_PRODUCT_KEYWROD = 'ADD_PRODUCT_KEYWROD'
export const ADD_PRODUCT_COMMENT = 'ADD_PRODUCT_COMMENT'
export const STUDENT_MORE_LOADING = 'STUDENT_MORE_LOADING'
export const GET_STUDENT_PRODUCTS = 'GET_STUDENT_PRODUCTS'
export const ADD_EVALUATED_PRODUCT = 'ADD_EVALUATED_PRODUCT'
export const DELETE_STUDENT_PRODUCT = 'DELETE_STUDENT_PRODUCT'
export const UPDATE_STUDENT_PRODUCT = "UPDATE_STUDENT_PRODUCT"
export const DELETE_PRODUCT_KEYWORD = 'DELETE_PRODUCT_KEYWORD'
export const ADD_PRODUCT_FOR_APPROVAL = 'ADD_PRODUCT_FOR_APPROVAL'
export const EVALUATE_STUDENT_PRODUCT = 'EVALUATE_STUDENT_PRODUCT'
export const GET_STUDENT_PRODUCT_COUNT = 'GET_STUDENT_PRODUCT_COUNT'
export const GET_STUDENT_MORE_PRODUCTS = 'GET_STUDENT_MORE_PRODUCTS'


// Admin Types
export const ADD_BATCH = 'ADD_BATCH'
export const ADD_EDITOR = 'ADD_EDITOR'
export const ADD_STUDENT = 'ADD_STUDENT'
export const ADD_TRAINER = 'ADD_TRAINER'
export const ADD_COMMENTS = 'ADD_COMMENTS'
export const ADD_TRAINING = 'ADD_TRAINING'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const ADMIN_LOADING = 'ADMIN_LOADING'
export const GET_ALL_EDITORS = 'GET_ALL_EDITORS'
export const GET_MORE_EDITORS = 'GET_MORE_EDITORS'
export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS'
export const GET_MORE_STUDENTS = 'GET_MORE_STUDENTS'
export const GET_ALL_TRAINERS = 'GET_ALL_TRAINERS'
export const GET_MORE_TRAINERS = 'GET_MORE_TRAINERS'
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const UPDATE_EDITOR_STATUS = 'UPDATE_EDITOR_STATUS'
export const GET_PENDING_PRODUCTS = 'GET_PENDING_PRODUCTS'
export const UPDATE_STUDENT_STATUS = 'UPDATE_STUDENT_STATUS'
export const GET_MORE_ALL_PRODUCTS = 'GET_MORE_ALL_PRODUCTS'
export const GET_MORE_PENDING_PRODUCTS = 'GET_MORE_PENDING_PRODUCTS'
export const GET_MORE_ADMIN_LOADING = 'GET_MORE_ADMIN_LOADING'
export const GET_PENDING_PRODUCTS_COUNT = 'GET_PENDING_PRODUCTS_COUNT'


// EDITOR TYPES
export const EDITOR_LOADING = 'EDITOR_LOADING'
export const GET_STUDENT_EVS = 'GET_STUDENT_EVS'
export const EDIT_STUDENT_EVS = 'EDIT_STUDENT_EVS'
export const EDITOR_MORE_LOADING = 'EDITOR_MORE_LOADING'
export const GET_EDITOR_STUDENTS = 'GET_EDITOR_STUDENTS'
export const GET_EDITOR_MORE_STUDENTS = 'GET_EDITOR_MORE_STUDENTS'


// Trainer Types
export const TRAINER_LOADING = 'TRAINER_LOADING'
export const TRAINER_ADD_STUDENT = 'TRAINER_ADD_STUDENT'
export const TRAINER_MORE_LOADING = 'TRAINER_MORE_LOADING'
export const TRAINER_ADD_COMMENTS = 'TRAINER_ADD_COMMENTS'
export const TRAINER_UPDATE_STATUS = 'TRAINER_UPDATE_STATUS'
export const TRAINER_GET_ALL_STUDENTS = 'TRAINER_GET_ALL_STUDENTS'
export const TRAINER_GET_MORE_STUDENTS = 'TRAINER_GET_MORE_STUDENTS'
export const TRAINER_GET_ALL_PRODUCTS = 'TRAINER_GET_ALL_PRODUCTS'
export const TRAINER_GET_MORE_PRODUCTS = 'TRAINER_GET_MORE_PRODUCTS'
export const TRAINER_GET_PENDINGS_COUNT = 'TRAINER_GET_PENDINGS_COUNT'
export const CHANGE_TRAINER_ALL_STATUSES = 'CHANGE_TRAINER_ALL_STATUSES'
export const TRAINER_GET_PENDING_PRODUCTS = 'TRAINER_GET_PENDING_PRODUCTS'
export const TRAINER_GET_PENDING_STUDENTS = 'TRAINER_GET_PENDING_STUDENTS'
export const TRAINER_GET_MORE_ALL_PRODUCTS = 'TRAINER_GET_MORE_ALL_PRODUCTS'
export const TRAINER_UPDATE_STUDENT_STATUS = 'TRAINER_UPDATE_STUDENT_STATUS'
export const TRAINER_GET_MORE_PENDING_STUDENTS = 'TRAINER_GET_MORE_PENDING_STUDENTS'
export const TRAINER_GET_MORE_PENDING_PRODUCTS = 'TRAINER_GET_MORE_PENDING_PRODUCTS'


// UTIL TYPES
export const SHOW_PASSWORDS = 'SHOW_PASSWORDS'
export const GET_REFERENCE_DATA = 'GET_REFERENCE_DATA'
export const GET_REFERENCE_DATA_LOADING = 'GET_REFERENCE_DATA_LOADING'
