import axios from 'axios';
import qs from 'qs';


const query = ({ url, method = 'get', data }) => {
    let token = localStorage.getItem('@token', '');

    return axios.request({
        url: url,
        method: method.toUpperCase(),
        data: data,
        params: data,
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'indices' })
    });
};

export default query;
