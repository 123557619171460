import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import Input from './Input'
import Memoized from './Memoized'
import InputPicker from './InputPicker'

export default function AddProductDetails({
    loading,
    keywords, setKeywords,
    jsExactSV, setJSExactSV,
    jsBroadSV, setJSBroadSV,
    helium10SV, setHelium10SV,
    jsGiveAPD, setJSGiveAPD,
    helium10NOGA, setHelium10NOGA,
    jsScreenWS, setJSScreenWS,
    jsScreenWRS, setJSScreenWRS,
    helium10SWS, setHelium10SWS,
    helium10SWRS, setHelium10SWRS,
    cereboRKSWSVS, setCereboRKSWSVS,
    helium10MKSH, setHelium10MKSH,
    past5YGT, setPast5YGT,
    profitMargin, setProfitMargin,
    addKeyword
}) {
    const [key, setKey1] = useState(Math.random())

    return (

        <div className="main-information-wrapper">
        
            <section className="dash-content-wrapper pro-keywords pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase">keyword details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5 ">
                            {/* <form> */}
                                <div className="form-row ">
                                    <Memoized
                                        Component={Input}
                                        value={keywords} 
                                        setValue={setKeywords} 
                                        id="inputKeywords" 
                                        label="Keywords" 
                                        placeholder="Enter Keywords"
                                    />
                                    <Memoized
                                        Component={Input}
                                        value={jsExactSV} 
                                        setValue={setJSExactSV} 
                                        id="inputExactsearch" 
                                        label="JS Exact Search Volume" 
                                        placeholder="Enter JS Exact Search Volume"
                                    />
                                    <Memoized
                                        Component={Input}
                                        value={jsBroadSV} 
                                        setValue={setJSBroadSV} 
                                        id="inputBroadsearch" 
                                        label="JS Broad Search Volume" 
                                        placeholder="Enter JS Broad Search Volume"
                                    />
                                    <Memoized
                                        Component={Input}
                                        value={helium10SV} 
                                        setValue={setHelium10SV} 
                                        id="inputHelium10search" 
                                        label="Helium 10 Search Volume" 
                                        placeholder="Enter Helium 10 Search Volume"
                                    />
                                    <Memoized
                                        Component={Input}
                                        value={jsGiveAPD} 
                                        setValue={setJSGiveAPD} 
                                        id="inputJSgiveaways" 
                                        label="JS Giveaways per day" 
                                        placeholder="Enter JS Giveaways per day"
                                    />
                                    <Memoized
                                        Component={Input}
                                        value={helium10NOGA} 
                                        setValue={setHelium10NOGA} 
                                        id="inputHelium10" 
                                        label="Helium10 no. of giveaways" 
                                        placeholder="Enter Helium10 no. of giveaways"
                                    />

                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile01"
                                        label="JS Screenshot without sorting"
                                        setValue={setJSScreenWS}
                                        name={jsScreenWS ? jsScreenWS.name : 'Choose file'}
                                    />
                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile02"
                                        label="JS screenshot with revenue sorting"
                                        setValue={setJSScreenWRS}
                                        name={jsScreenWRS ? jsScreenWRS.name : 'Choose file'}
                                    />
                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile03"
                                        label="Helium 10 Screenshot without sorting"
                                        setValue={setHelium10SWS}
                                        name={helium10SWS ? helium10SWS.name : 'Choose file'}
                                    />
                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile04"
                                        label="Helium 10 Screenshot with revenue sorting"
                                        setValue={setHelium10SWRS}
                                        name={helium10SWRS ? helium10SWRS.name : 'Choose file'}
                                    />
                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile05"
                                        label="Cerebo relevant keywords screenshot with search volume sorting"
                                        setValue={setCereboRKSWSVS}
                                        name={cereboRKSWSVS ? cereboRKSWSVS.name : 'Choose file'}
                                    />
                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile06"
                                        label="Helium 10 main keyword search history screenshots"
                                        setValue={setHelium10MKSH}
                                        name={helium10MKSH ? helium10MKSH.name : 'Choose file'}
                                    />
                                    <Memoized
                                        Component={InputPicker}
                                        key={key}
                                        id="inputGroupFile07"
                                        label="Past 5 years google trends"
                                        setValue={setPast5YGT}
                                        name={past5YGT ? past5YGT.name : 'Choose file'}
                                    />
                                    
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputBroadsearch">Profit Margin</label>
                                        <input 
                                            autoComplete="off" value={profitMargin} onChange={e=>setProfitMargin(e.target.value)} type="text" className="form-control" id="inputBroadsearch" placeholder="Enter Profit Margin" required />
                                    </div>
                                </div>
                                <div className="pt-5 text-center">
                                    {
                                        loading ?
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-org btn-lg text-uppercase">
                                                    <ScaleLoader color="#fff" height={20} />
                                                </button>
                                            </span>
                                        :    
                                            <span className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <button type="button" className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" onClick={()=>addKeyword(()=>setKey1(Math.random()))}>Add</button>
                                            </span>
                                    }
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section>
        
        </div>
            
    )
}
