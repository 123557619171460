import axios from 'axios'
import './style.css'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import LOGIN_LOGO from '../../assets/images/enablers-login-logo.png'

export default function ResetPassword() {
    const location  = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    let token = location?.pathname?.split('/')[3] || ''
    let email = location?.search?.split('=')[1] || ''

    useEffect(()=>{
        if(email === '' || token === '') {
            history.push('/login?true')
        }
    }, [])

    const resetHandler = () => {
        if(!email || email === '' || !token || token === '') {
            toast.warn('No token found. Please open link again from email.')
        } else if(password === '') {
            toast.warn('Please specify new password.')
        } else if(confirmPassword === '') {
            toast.warn('Please specify confirm password.')
        } else if (password !== confirmPassword) {
            toast.warn("Both passwords don't match.")
        } else {
            setLoading(true)

            var config = {
                method: 'post',
                url: process.env.REACT_APP_ENDPOINT + '/api/password/reset',
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Transfer-Encoding': 'application/json',
                },
                data: { email: decodeURIComponent(email), password, password_confirmation: confirmPassword, token }
            };
            
            axios(config)
                .then(res => {
                    if(res.status === 200 && res.data.message) {
                        toast.success('Your password is reset successfully. Please login.')
                        history.push('/login?true')
                    } else {
                        toast.error(res.data.error ? res.data.error : 'Something went wrong. Please try again.')
                    }
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                    var message = "Something went wrong"

                    if(err?.response?.data?.errors?.email) {
                        message = err?.response?.data?.errors?.email[0]
                    } else if(err?.response?.data?.errors?.password) {
                        message = err?.response?.data?.errors?.password[0]
                    } else if(err?.response?.data?.message) {
                        message = err?.response?.data?.message;
                    }

                    toast.error(
                        message
                    )
                })
        }
    }

    return (
        <div id="forgot-rap">
            <header>
                <div className="container">
                    <div className="logo-wrap py-3">
                        <img src={LOGIN_LOGO} />
                    </div>
                </div>
            </header>
            <section className="main-forgot-wrapper vh-100 d-flex align-items-center flex-row">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-9 mx-auto">
                            <div className="card o-hidden border-0 shadow-lg">
                                <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                                    <p className="mb-4">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p>
                                                </div>
                                                <hr />
                                                <form className="user">
                                                    <div className="form-group">
                                                        <input
                                                            autoComplete="off" value={password} onChange={e=>setPassword(e.target.value)} type="password" className="form-control form-control-user rounded" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter New Password" />
                                                        <input
                                                            autoComplete="off" value={confirmPassword} onChange={e=>setConfirmPassword(e.target.value)} type="password" className="form-control form-control-user rounded mt-3" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Confirm New Password" />
                                                    </div>
                                                    <div className="d-flex bd-highlight align-items-center">
                                                        <a href="/login" className="small text-uppercase flex-grow-1 bd-highlight w-100 custom-link" >Remember Password? Login!</a>
                                                        {
                                                            loading ?
                                                                <a className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor" style={{ color: 'white' }}>
                                                                    <ScaleLoader color="white" height={15} />
                                                                </a>
                                                            :
                                                                <a className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor" style={{ color: 'white' }} onClick={resetHandler}>
                                                                    Reset Password
                                                                </a>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
