import React from 'react'
import Input from './Input'
import Profit from './Profit'
import Select from './Select'

export default function FirstWidget({ 
    next, 
    progress, 
    percentage,

    market, setMarket,
    sellingPrice, setSellingPrice,
    salesVolume, setSalesVolume,
    noCompSellers, setNoCompSellers,
    productCost, setProductCost,
    unitsPack, setUnitsPack,
    fbaFee, setFBAFee,
    shippingCost, setShippingCost,
    bundlingCost, setBundlingCost,
    vat, setVAT,
}) {

    return (
        <div style={{ marginTop: 10 }}>
            <h5 style={{ color: '#2a323f', textAlign: 'center', marginTop: 20 }}>Congratulations! Your product fits in criteria. You can proceed to contact suppliers.</h5>
            
            <form className="pt-3 pt-md-5">
                <div className="row">
                    <div className="col-md-5 mr-auto">
                        {/* <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Amzaon marketplace</label>
                            <select className="custom-select" value={market} onChange={e=>setMarket(e.target.value)}>
                                <option value="us">USA</option>
                                <option value="uk">UK</option>
                            </select>
                        </div> */}
                        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Selling price*</label>
                            <Input 
                                value={sellingPrice} 
                                setValue={setSellingPrice} 
                                helper={market==='us'?'$':'£'} 
                                placeholder='Selling Price' 
                                error={(!sellingPrice || sellingPrice < 10) ? `Price should be at least ${market==='us'?'$':'£'}10` : null} 
                            />
                        </div>
                        {/* <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Estimate Sales Volume</label>
                            <Input placeholder='Sales Volume' value={salesVolume} setValue={setSalesVolume} />
                        </div> */}
                        {/* <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Number of competitive sellers</label>
                            <Input 
                                placeholder='No of competitive sellers' 
                                value={noCompSellers} 
                                setValue={setNoCompSellers}
                                error={(noCompSellers && noCompSellers < 2) ? `No of competitive sellers must be at least 2.` : null} 
                            />
                        </div> */}
                        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Product cost per unit*</label>
                            <Input 
                                value={productCost} 
                                setValue={setProductCost} 
                                helper={market==='us'?'$':'£'} 
                                placeholder='Product Cost' 
                                error={(!productCost || productCost < 0) ? `Enter Valid Product Cost` : null} 
                            />
                        </div>
                    </div>
                    <div className="col-md-5 ml-auto">
                        {/* <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Units in pack</label>
                            <Input placeholder='Units in pack' value={unitsPack} setValue={setUnitsPack} />
                        </div> */}
                        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">FBA Fee*</label>
                            <Input 
                                value={fbaFee} 
                                setValue={setFBAFee} 
                                helper={market==='us'?'$':'£'} 
                                placeholder='FBA Fee' 
                                error={(!fbaFee || fbaFee < 0) ? `Enter Valid FBA Fee` : null} 
                            />
                        </div>
                        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Shipping To Amazon FC*</label>
                            <Input 
                                value={shippingCost} 
                                setValue={setShippingCost} 
                                helper={market==='us'?'$':'£'} 
                                placeholder='Shipping To Amazon FC' 
                                error={(!shippingCost || sellingPrice < 0) ? `Enter Valid Shipping Cost` : null} 
                            />
                        </div>
                        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                            <label className="flex-base">Repacking And Bundling*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <Input 
                                value={bundlingCost} 
                                setValue={setBundlingCost} 
                                helper={market==='us'?'$':'£'} 
                                placeholder='Repacking And Bundling' 
                                error={(!bundlingCost || bundlingCost < 0) ? `Enter Valid Repacking And Bundling` : null} 
                            />
                        </div>
                        {
                            market === 'uk' ?
                                <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                                    <label className="flex-base">VAT*</label>
                                    <Input
                                        value={vat} 
                                        setValue={setVAT} 
                                        placeholder='VAT' 
                                        helper={market==='us'?'$':'£'} 
                                        error={(market === 'uk' && (!vat || vat < 0)) ? `Enter Valid VAT` : null} 
                                    />
                                </div>
                            :
                                null    
                        }
                    </div>

                    {
                        // (sellingPrice && productCost) ?
                            <div style={{ width: '100%' }}>
                                <div style={{ border: '0.5px #F1F1F1 solid', marginTop: 20, marginBottom: 10 }} />
                                <Profit
                                    market={market}
                                    margin={percentage}
                                    profit={((percentage/100) * sellingPrice).toFixed(2)}
                                    sellingPrice={sellingPrice}
                                    productCost={productCost}
                                    fbaFee={fbaFee}
                                    shippingCost={shippingCost}
                                    bundlingCost={bundlingCost}
                                    vat={vat}
                                />
                                <div style={{ border: '0.5px #F1F1F1 solid', marginTop: 10, marginBottom: 10 }} />
                            </div>
                        // : null
                    }


                    {
                        (
                            !percentage || percentage < 10 || sellingPrice < 10 || !bundlingCost || bundlingCost < 0 || (market === 'uk' && (!vat || vat < 0)) ||
                            !shippingCost || sellingPrice < 0 || !fbaFee || fbaFee < 0 || !productCost || productCost < 0
                        ) ?
                            <div className="col-12 text-center ">
                                <button type="button" className="btn-disabled" disabled>Done</button>
                            </div>
                        :
                            <div className="col-12 text-center ">
                                <button type="button" className="btn-org" onClick={next}>Done</button>
                            </div>    
                    }
                </div>
            </form>
        </div>
    )
}
