import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingsCount } from "redux/actions/trainerActions";

export default function useHomeData() {
    const dispatch = useDispatch()
    
    const {
        loading,
        pendingStudentsCount,
        totalPending: pendingProductsCount,
    } = useSelector(({ trainerRed }) => trainerRed)

    useEffect(() => {
        // if(pendingProductsCount === 0 && pendingStudentsCount === 0)
            dispatch(getPendingsCount())
    }, [])

    return [
        loading,
        pendingProductsCount,
        pendingStudentsCount,
    ];
}