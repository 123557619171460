import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { useDispatch, useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import Memoized from 'components/Memoized'
import Input from 'components/Input'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { addEditor } from 'redux/actions/adminActions'
import { useHistory } from 'react-router-dom'
import BreadCrumbs from './BreadCrumbs'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Message from 'components/validationMessage/Message';
import { validateEmail } from 'utils/validation'

export default function AddEditor() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { loading } = useSelector(({ adminRed }) => adminRed)

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
              .max(60, 'Must be 60 characters or less')
              .required('Student Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Student Email is required'),
        }),
        onSubmit: values => {
            addHandler(values)
        },
    });

    const addHandler = ({ name, email }) => {
        if(name === "") {
            toast.warn('Please enter Editor name.')
        } else if(email === '') {
            toast.warn('Please enter Editor email.')
        } else if(!validateEmail(email)) {
            toast.warn('Please enter valid Editor email.')
        }  else {
            dispatch(
                addEditor(
                    {
                        name,
                        email
                    },
                    ()=>{
                        history.push('/admin-home')
                    }
                )
            )
        }
    }

    return (
        <div>
            <Memoized
                Component={NavBar}
            />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">

                        <BreadCrumbs style={{ marginBottom: 20 }} />
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <h2 className="text-center mb-4" >Add Editor</h2>
                                                
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <div className={`form-group col-md-6 mb-4`}>
                                                        <label htmlFor='name'>Editor Name*:</label>
                                                        <input 
                                                            id="name"
                                                            type="text" 
                                                            maxLength={60}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            {...formik.getFieldProps('name')}
                                                            placeholder="Enter Editor Name"
                                                        />
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <Message value={formik.errors.name} />
                                                        ) : null}
                                                    </div>
                                                    <div className={`form-group col-md-6 mb-4`}>
                                                        <label htmlFor='email'>Editor Email*:</label>
                                                        <input 
                                                            id="email"
                                                            type="text" 
                                                            maxLength={60}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            {...formik.getFieldProps('email')}
                                                            placeholder="Enter Editor Email"
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <Message value={formik.errors.email} />
                                                        ) : null}
                                                    </div>
                                                    {/* <Memoized
                                                        Component={Input}
                                                        value={name}
                                                        setValue={setName}
                                                        label="Editor Name"
                                                        placeholder="Enter Editor Name"
                                                    />
                                                    <Memoized
                                                        Component={Input}
                                                        value={email}
                                                        setValue={setEmail}
                                                        label="Editor Email"
                                                        placeholder="Enter Editor Email"
                                                    /> */}
                                                </div>    

                                                {
                                                    loading ?
                                                        <button 
                                                            type="button" 
                                                            style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                            className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" 
                                                        >
                                                            <ScaleLoader color="#fff" height={15} />
                                                        </button>    
                                                    :
                                                        <button 
                                                            type="button" 
                                                            onClick={formik.handleSubmit}
                                                            style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                            className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" 
                                                        >
                                                            Submit
                                                        </button>
                                                }
                                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            </div>
            
            <Memoized
                Component={Footer}
            />
        </div>
    )
}
