import React from 'react'
import { useState } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import AuthenticateModal from 'screens/authenticateModal'
import { useDispatch, useSelector } from 'react-redux'
import  { showPasswords } from 'redux/actions/utilActions'

export default function PasswordView({ item, containerStyle }) {
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false)
    const [password, setPasswordStr] = useState("")
    const [isPassword, setPassword] = useState(false)

    const { isPasswordShown } = useSelector(({ utilsRed }) => utilsRed)

    const finishHandler = (str, isVerified) => {
        if(isVerified) {
            setPasswordStr(str);
            setPassword(true);
            setModal(false);
            // dispatch(showPasswords())
        } else {
            setModal(false);
        }
    }

    const toggleHandler = () => {
        if(isPassword) {
            setPassword(false)
        } 
        // else if(isPasswordShown) {
        //     setPassword(true)
        // } 
        else {
            setModal(true)
        }
    }
    
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...containerStyle }}>
                {
                    isPassword ?
                        <span className="mr-1">{password.toString().replace(/&amp;/g, '&')}</span>
                    :    
                        <span className="mr-1">*********</span>
                }
                {
                    isPassword ? 
                        <VisibilityOffIcon className="cursor" onClick={toggleHandler} />    
                    :
                        <VisibilityIcon className="cursor" onClick={toggleHandler} />
                }
            </div>

            <AuthenticateModal
                id={item?.id}
                isModal={isModal}
                setModal={setModal}
                finishHandler={finishHandler}
            />
        </>
    )
}
