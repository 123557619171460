import InputPicker from 'components/Input/InputPicker'
import React from 'react'
import { ScaleLoader } from 'react-spinners'

export default function ConfirmationAlert({ title, description, onConfirm, onCancel, loading }) {
    return (
        <div className="card o-hidden border-0 shadow-lg">
                    <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-1">{title}</h1>
                                        <hr />
                                        <h6 className="h6 text-gray-900 mb-2">{description}</h6>
                                    </div>
                                    <form className="user">
                                        
                                        {
                                            (!onConfirm || !onCancel) ?
                                                null
                                            :    
                                                <>
                                                    <hr />
                                                    {
                                                        loading ?
                                                            <a
                                                                style={{ color: 'white', width: 160 }} 
                                                                className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight ml-auto mr-auto cursor"
                                                            >
                                                                <ScaleLoader color="#FFF" height={15} />
                                                            </a>
                                                        :    
                                                            <div className="d-flex bd-highlight align-items-center justify-content-around pt-5">
                                                                    <a 
                                                                        onClick={onConfirm}
                                                                        style={{ color: 'white', width: 160 }} 
                                                                        className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor"
                                                                    >
                                                                        Yes
                                                                    </a>
                                                                    <a 
                                                                        onClick={onCancel}
                                                                        style={{ color: 'white', width: 160 }} 
                                                                        className="btn btn-secondary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor"
                                                                    >
                                                                        No
                                                                    </a>
                                                            </div>
                                                    }
                                                </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}
