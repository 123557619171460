import NavBar from 'components/navbar'
import Footer from 'components/footer'
import AddProductBasics from './components/AddProductBasics'
import AddProductDetails from './components/AddProductDetails'
import useAddProduct from './useAddProduct'
import ProductKeywordsTable from './components/ProductKeywordsTable'
import Memoized from './components/Memoized'
import AddProductComment from 'screens/editPrivateLabelProduct/components/AddProductComment'
import { Prompt } from 'react-router-dom'
import Modal from 'components/Modal/index.js'
import Disclaimer from './components/Disclaimer'
import { toast } from 'react-toastify'
import BreadCrumbs from './components/BreadCrumbs'
import ConfirmationAlert from 'components/confimationAlert'

export default function AddProduct() {
    const  [
        formik,
        loading,
        index,
        setIndex,
        name,
        setName,
        market,
        setMarket,
        image,
        setImage,
        nextHandler,

        keywords, setKeywords,
        competingProducts, setCompetingProducts,
        egrowSV, setEgrowSV,
        avgReviewsOfTop10, setAvgReviewsOfTop10,
        avgSellingPriceOfTop10, setAvgSellingPriceOfTop10,
        profitMargin, setProfitMargin,
        availableBudget, setAvailableBudget,
        currency, setCurrency,
        egrowRevenueSorted, setEgrowRevenueSorted,
        egrowRevenueUnSorted, setEgrowRevenueUnSorted,
        avgMonthlyRevenueOfTop10, setAvgMonthlyRevenueOfTop10,
        sixtyPercentOfTheTotalMonthlyRevenueOfTop10, setsixtyPercentOfTheTotalMonthlyRevenueOfTop10,

        productKeywords, setProductKeywords,
        addKeywordHandler,
        doneHandler,

        comment, setComment,
        addComment,
        prohibited, setProhibited,
        isModal, setModal,


        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ] = useAddProduct()

    return (
        <div style={{ scrollBehavior: 'smooth' }}>
            <Memoized
                Component={NavBar}
            />

            <BreadCrumbs className="mt-5" />

            {
                index === 0 ?
                    <AddProductBasics
                        formik={formik}
                        onNext={nextHandler}
                        name={name}
                        setName={setName}
                        market={market}
                        setMarket={setMarket}
                        image={image}
                        setImage={setImage}
                    />
                :
                    <>
                        <AddProductDetails
                            formik={formik}
                            loading={loading}

                            keywords={keywords}
                            setKeywords={setKeywords}
                            competingProducts={competingProducts}
                            setCompetingProducts={setCompetingProducts}
                            egrowSV={egrowSV}
                            setEgrowSV={setEgrowSV}
                            avgReviewsOfTop10={avgReviewsOfTop10}
                            setAvgReviewsOfTop10={setAvgReviewsOfTop10}
                            avgSellingPriceOfTop10={avgSellingPriceOfTop10}
                            setAvgSellingPriceOfTop10={setAvgSellingPriceOfTop10}
                            profitMargin={profitMargin}
                            setProfitMargin={setProfitMargin}
                            availableBudget={availableBudget}
                            setAvailableBudget={setAvailableBudget}
                            currency={currency}
                            setCurrency={setCurrency}
                            egrowRevenueSorted={egrowRevenueSorted}
                            setEgrowRevenueSorted={setEgrowRevenueSorted}
                            egrowRevenueUnSorted={egrowRevenueUnSorted}
                            setEgrowRevenueUnSorted={setEgrowRevenueUnSorted}
                            avgMonthlyRevenueOfTop10={avgMonthlyRevenueOfTop10}
                            setAvgMonthlyRevenueOfTop10={setAvgMonthlyRevenueOfTop10}
                            sixtyPercentOfTheTotalMonthlyRevenueOfTop10={sixtyPercentOfTheTotalMonthlyRevenueOfTop10}
                            setsixtyPercentOfTheTotalMonthlyRevenueOfTop10={setsixtyPercentOfTheTotalMonthlyRevenueOfTop10}



                            setSubmitted={setSubmitted}
                        />

                        {
                            productKeywords && productKeywords.length ?
                                <>
                                    <ProductKeywordsTable
                                        productKeywords={productKeywords}
                                        setProductKeywords={setProductKeywords}
                                    />
                                    <AddProductComment
                                        loading={loading}
                                        comment={comment}
                                        addComment={()=>{
                                            if(comment === '') {
                                                toast.warn('Please add Product Details.')
                                            } else {
                                                setModal(true)
                                            }
                                        }}
                                        setComment={setComment}
                                    />
                                </>
                            :
                                null
                        }
                    </>
            }



            <Modal fullWidth show={isModal} close={()=>setModal(false)} hideClose={true}>
                <Disclaimer done={()=>{
                    setModal(false)
                    addComment()
                }} />
            </Modal>

            <Modal fullWidth show={isKeywordConfirmation} close={()=>{}} hideClose={true}>
                <ConfirmationAlert
                    title="Do you want to add more keywords to this product?"
                    description=""
                    onConfirm={onKeywordConfirmation}
                    onCancel={onKeywordCanelation}
                />
            </Modal>

            <Prompt
                when={prohibited}
                message={location =>
                    `Changes you made may not be saved.`
                }
            />


            <Footer />
        </div>
    )
}
