import { useState } from "react"
import Lightbox from "react-awesome-lightbox"

export default function Image({ src, ...rest }) {
    const [img, setImg] = useState(false)

    return (
        (img && img !== '') ?
            <Lightbox
                image={src}
                title="Image"
                onClose={()=>setImg(false)}
            />
        :
            <img
                src={src}
                onClick={()=>setImg(true)}
                className="keyword-img cursor"
                {...rest}
            />
    )
}
