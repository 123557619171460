import {
    ADD_BATCH,
    ADD_EDITOR,
    ADD_STUDENT,
    ADD_TRAINER,
    ADD_TRAINING,
    ADD_COMMENTS,
    UPDATE_STATUS,
    ADMIN_LOADING,
    GET_ALL_EDITORS,
    GET_ALL_STUDENTS,
    GET_ALL_TRAINERS,
    GET_ALL_PRODUCTS,
    GET_MORE_EDITORS,
    GET_MORE_STUDENTS,
    GET_MORE_TRAINERS,
    UPDATE_EDITOR_STATUS,
    GET_PENDING_PRODUCTS,
    GET_MORE_ALL_PRODUCTS,
    UPDATE_STUDENT_STATUS,
    GET_MORE_ADMIN_LOADING,
    GET_MORE_PENDING_PRODUCTS,
    GET_PENDING_PRODUCTS_COUNT,
} from '../types';

const initState = {
    isProductRequested: false,
    moreLoading: false,
    totalTrainers: 0,
    totalStudents: 0,
    totalEditors: 0,
    totalPending: 0,
    loading: false,
    trainings: [],
    trainers: [],
    students: [],
    products: [],
    approved: 0,
    batches: [],
    pending: [],
    editors: [],
    allTotal: 0,
    evs: null,
}

export default function reducer(state=initState, action) {
    let { payload } = action;

    switch (action.type) {
        case GET_MORE_ALL_PRODUCTS: {
            let products = [...state.products];
            products = products.concat(payload.products)

            return {
                ...state,
                products,
                loading: false,
                moreLoading: false,
                allTotal: payload.total,
            }
        }

        case GET_ALL_PRODUCTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                allTotal: payload.total,
                products: payload.products,
            }

        case GET_MORE_PENDING_PRODUCTS: {
            let products = [...state.pending]
            products = products.concat(payload.products)

            return {
                ...state,
                loading: false,
                pending: products,
                moreLoading: false,
                isProductRequested: true,
                // totalPending: payload.total,
            }
        }

        case GET_PENDING_PRODUCTS_COUNT:
            return {
                ...state,
                loading: false,
                totalPending: payload
            }

        case GET_PENDING_PRODUCTS: 
            return {
                ...state,
                loading: false,
                moreLoading: false,
                isProductRequested: true,
                pending: payload.products,
                // totalPending: payload.total,
            }

        case ADD_TRAINER: {
            let trainers = [...state.trainers]
            trainers.push(payload)
            let totalTrainers = state.totalTrainers + 1

            return {
                ...state,
                trainers,
                totalTrainers,
                loading: false,
            }
        }

        case ADD_STUDENT: {
            let students = [...state.students]
            students.push(payload)
            let totalStudents = state.totalStudents + 1

            return {
                ...state,
                students,
                totalStudents,
                loading: false,
            }
        }

        case ADD_EDITOR: {
            let editors = [...state.editors]
            editors.push(payload)
            let totalEditors = state.totalEditors + 1;

            return {
                ...state,
                editors,
                totalEditors,
                loading: false,
            }
        }

        case ADD_TRAINING: {
            let trainings = [...state.trainings]
            trainings.push(payload)

            return {
                ...state,
                trainings,
                loading: false,
            }
        }

        case ADD_BATCH: {
            let batches = [...state.batches]
            batches.push(payload)

            return {
                ...state,
                batches,
                loading: false,
            }
        }

        case GET_ALL_TRAINERS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                trainers: payload.users,
                totalTrainers: payload.total,
            }

        case GET_MORE_TRAINERS: {
            let trainers = [...state.trainers];
            trainers = trainers.concat(payload.users)

            return {
                ...state,
                trainers,
                loading: false,
                moreLoading: false,
                totalTrainers: payload.total,
            }
        }

        case GET_ALL_STUDENTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                students: payload.users,
                totalStudents: payload.total,
            }

        case GET_MORE_STUDENTS: {
            let students = [...state.students];
            students = students.concat(payload.users)

            return {
                ...state,
                students,
                loading: false,
                moreLoading: false,
                totalStudents: payload.total,
            }
        }

        case GET_ALL_EDITORS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                editors: payload.users,
                totalEditors: payload.total,
            }

        case GET_MORE_EDITORS: {
            let editors = [...state.editors];
            editors = editors.concat(payload.users)

            return {
                ...state,
                editors,
                loading: false,
                moreLoading: false,
                totalEditors: payload.total,
            }
        }

        case UPDATE_STATUS: {
            let trainers = state.trainers.map(t => {
                if(t.id === payload.id)
                    return {
                        ...t,
                        status: payload.status
                    }
                else return t;
            })

            return {
                ...state,
                trainers,
                loading: false,
            }
        }

        case UPDATE_STUDENT_STATUS: {
            let students = state.students.map(s => {
                if(s.id === payload.id)
                    return {
                        ...s,
                        status: payload.status
                    }
                else return s;
            })

            return {
                ...state,
                students,
                loading: false,
            }
        }

        case UPDATE_EDITOR_STATUS: {
            let editors = state.editors.map(e => {
                if(e.id === payload.id)
                    return {
                        ...e,
                        status: payload.status
                    }
                else return e;
            })

            return {
                ...state,
                editors,
                loading: false,
            }
        }

        case ADD_COMMENTS:
            return {
                ...state,
                loading: false,
            }

        case GET_MORE_ADMIN_LOADING:
            return {
                ...state,
                moreLoading: payload
            }

        case ADMIN_LOADING:
            return {
                ...state,
                loading: payload,
            }
    
        default:
            return state;
    }
}