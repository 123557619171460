import React from 'react'

export default function Input({ value, setValue, required, maxLength, id, type, label, placeholder, fillWidth, multiline, disabled }) {
    return (
        <div className={`form-group ${fillWidth ? 'col-md-12' : 'col-md-6'} mb-4`}>
            <label htmlFor={id}>{label}{required ? "*" : ""}:</label>
            {
                multiline ?
                    <textarea 
                        id={id}
                        required
                        value={value}
                        rows={multiline}
                        autoComplete="off"
                        style={{ minHeight: 200 }}
                        type={type || "text"} 
                        className="form-control"
                        placeholder={placeholder || ""} 
                        onChange={e=>setValue(e.target.value)}
                    />
                :    
                    <input 
                        id={id}
                        required
                        value={value}
                        autoComplete="off"
                        disabled={disabled}
                        maxLength={maxLength}
                        type={type || "text"} 
                        className="form-control"
                        placeholder={placeholder || ""} 
                        onChange={e=>setValue(e.target.value)}
                    />
            }
        </div>
    )
}
