import React from 'react'
import Message from 'components/validationMessage/Message'

export default function PickerInput({ formik, id, value, onChange, type, label, placeholder, options, symbol, option, setOption }) {
    return (
        <div className="col-md-6">
            <div className="form-group ">
                <label htmlFor={id || Math.random().toString()}>{label || ''}</label>
                <input 
                    required
                    type={type || "text"}
                    // value={value}
                    autoComplete="off"
                    className="form-control"
                    placeholder={placeholder || ''}
                    id={id || Math.random().toString()}
                    {...formik.getFieldProps(id)}
                    // onChange={e=> onChange ? onChange(e.target.value) : {}}
                />
                {
                    symbol ?
                        <div style={{ 
                                position: 'absolute', 
                                top: 35, 
                                right: 17, 
                                width: 40, 
                                height: 40, 
                                borderRadius: 5,
                                backgroundColor: "#d1d3e2", 
                                borderLeft: '2px #979cba solid',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <span>{symbol}</span>
                        </div>
                    : options?.length ?
                        <div style={{ 
                                position: 'absolute', 
                                top: 35, 
                                right: 17, 
                                width: 58, 
                                height: 40, 
                                borderRadius: 5,
                                backgroundColor: "#d1d3e2", 
                                borderLeft: '2px #979cba solid',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <select value={option} onChange={e=>setOption(e.target.value)} style={{ backgroundColor: '#d1d3e2', border: 'none' }}>
                                {
                                    options.map(opt => (
                                        <option>{opt}</option>
                                    ))
                                }
                            </select>
                            {/* <span>USD</span> */}
                        </div>
                    :
                        null
                }
                {formik.touched[id] && formik.errors[id] ? (
                    <Message value={formik.errors[id]} />
                ) : null}
            </div>
        </div>
    )
}
