import axios from 'axios'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { validateEmail } from 'utils/validation'
import LOGIN_LOGO from '../../assets/images/enablers-login-logo.png'

export default function ForgetPassword() {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const forgetHandler = () => {
        if(email === '' || !validateEmail(email)) {
            toast.warn('Please enter valid email address.')
        } else {
            setLoading(true)

            var config = {
                method: 'post',
                url: process.env.REACT_APP_ENDPOINT + '/api/password/email',
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Transfer-Encoding': 'application/json',
                },
                data: { email }
            };
              
            axios(config)
                .then(res => {
                    if(res.status === 200 && res.data.message) {
                        if(res.data.message && res.data.message == "You have requested password reset recently, please check your email.") {
                            toast.warn(res.data.message)
                        } else  {
                            toast.success(res.data.message || 'An email has been sent to your email address.')
                            history.push('/login')
                        }
                    } else {
                        toast.error(res.data.error ? res.data.error : 'Something went wrong. Please try again.')
                    }
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                    toast.error(
                        err?.response?.data?.errors?.email[0] ||
                        err?.response?.data?.message || 
                        'Something went wrong'
                    )
                })
        }
    }

    return (
        <div id="forgot-rap">
            <header>
                <div className="container">
                    <div className="logo-wrap py-3 cursor" onClick={()=>history.push('/')}>
                        <img src={LOGIN_LOGO} />
                    </div>
                </div>
            </header>
            <section className="main-forgot-wrapper vh-100 d-flex align-items-center flex-row">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-9 mx-auto">
                            <div className="card o-hidden border-0 shadow-lg">
                                <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                                    <p className="mb-4">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p>
                                                </div>
                                                <hr />
                                                <form className="user">
                                                    <div className="form-group">
                                                        <input
                                                            autoComplete="off" value={email} onChange={e=>setEmail(e.target.value)} type="email" className="form-control form-control-user rounded" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                                                    </div>
                                                    <div className="d-flex bd-highlight align-items-center">
                                                        <a className="small text-uppercase flex-grow-1 bd-highlight w-100 custom-link" onClick={()=>history.push('/login')}>Already have an account? Login!</a>
                                                        {
                                                            loading ?
                                                                <a className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor" style={{ color: 'white' }}>
                                                                    <ScaleLoader color="white" height={15} />
                                                                </a>
                                                            :
                                                                <a className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor" style={{ color: 'white' }} onClick={forgetHandler}>
                                                                    Reset Password
                                                                </a>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
