import React from 'react'
import { ScaleLoader } from 'react-spinners'
import Input from './Input'
import RadioGroup from './RadioGroup'
import Select from './Select'

export default function SecondWidget({
    done,
    loading,
    market,
    setMarket,
    isAmazonSelling, setIsAmazonSelling,
    above50Reviews, setAbove50Reviews,
    bsrStable, setBSRStable,
    priceStable, setPriceStable,
    fbmControl, setFBMControl,
    brandRestrict, setBrandRestrict,
    buyBox, setBuyBox,
    fragileItem, setFragileItem,
    hazmatProduct, setHazmatProduct,
    onlyBrandSeller, setOnlyBrandSeller,
    category, setCategory,
    asin, setASIN,
    bsr, setBSR,

    salesVolume, setSalesVolume,
    noCompSellers, setNoCompSellers,
    unitsPack, setUnitsPack,
}) {

    return (
        <div style={{ marginTop: 10 }}>
            <form className="pt-3 pt-md-5">
                <div className="row">
                <div className="col-md-5 mr-auto mt-0 mt-md-5">
                    <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                        <label className="flex-base">Amzaon marketplace*</label>
                        <select className="custom-select" value={market} onChange={e=>setMarket(e.target.value)}>
                            <option value="us">USA</option>
                            <option value="uk">UK</option>
                        </select>
                    </div>

                    <Select
                        value={isAmazonSelling}
                        setValue={setIsAmazonSelling}
                        label='Amazon selling itself?*'
                        options={[{name: 'Yes', id: 'yes'}, {name: 'No', id: 'no'}, {name: 'Out of stock', id: 'out of box'}]}
                    />
                    {isAmazonSelling === 'yes' ?
                        <div className='error-message' style={{ textAlign: 'end', marginTop: -9 }}>Invalid choice</div>
                    :null}
                    
                    <RadioGroup
                        label='Above 50 reviews?*'
                        setValue={setAbove50Reviews}
                    />
                    <RadioGroup
                        setValue={setBSRStable}
                        label='BSR stable over 90 days?*'
                    />
                    <RadioGroup
                        setValue={setPriceStable}
                        label='Price stable over 90 days?*'
                    />
                    <RadioGroup
                        setValue={setBrandRestrict}
                        label='Product brand registered?*'
                    />

                    <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                        <label className="flex-base">Monthly Revenue*</label>
                        <Input 
                            value={salesVolume}
                            placeholder='Monthly Revenue' 
                            setValue={setSalesVolume}
                            error={(!salesVolume || salesVolume < 5000) ? `Min. 5000${market === 'us' ? '$' : '£'}.` : null} 
                        />
                    </div>

                    <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                        <label className="flex-base">BSR*</label>
                        <Input 
                            value={bsr}
                            placeholder='BSR' 
                            setValue={setBSR}
                            error={(!bsr || bsr <= 0 || bsr > 60000) ? `Must be between ${market === 'us' ? '$' : '£'}1-60k.` : null} 
                        />
                    </div>

                    <Select
                        value={category}
                        setValue={setCategory}
                        label='Category*'
                        options={[
                            {name: 'Baby', id: 'baby'}, 
                            {name: 'Health & Household', id: 'health & houshold'}, 
                            {name: 'Home & Kitchen', id: 'homw & kitchen'},
                            {name: 'Industrial & Scientific', id: 'industrial & scientific'},
                            {name: 'Kitchen & Dinning', id: 'kitchen & dinning'},
                            {name: 'Pet Supplies', id: 'pet supplies'},
                            {name: 'Arts, Crafts & Sewing', id: 'arts, crafts & sewing'},
                            {name: 'Office Products', id: 'office products'},
                            {name: 'Patio, Lawn & Garden', id: 'patio, lawn & garden'},
                            {name: 'Sports & Outdoors', id: 'sports & outdoors'},
                            {name: 'Tools & Home Improvements', id: 'tools & home improvements'},
                            {name: 'Toys & Games', id: 'toys & games'},
                        ]}
                    />
                    {(!category || category === '') ?
                        <div className='error-message' style={{ textAlign: 'end', marginTop: -9 }}>Please select Category</div>
                    :null}

                </div>
                <div className="col-md-5 ml-auto  mt-0 mt-md-5">
                    <RadioGroup
                        setValue={setBuyBox}
                        label='Buy box suppressed*'
                    />
                    <RadioGroup
                        setValue={setFragileItem}
                        label='Fragile item?*'
                    />
                    
                    <RadioGroup
                        setValue={setHazmatProduct}
                        label='Hazmat Product?*'
                    />
                    <RadioGroup
                        setValue={setOnlyBrandSeller}
                        label='Brand is only Seller*'
                    />
                    <RadioGroup
                        setValue={setFBMControl}
                        label='FBM controls buy box?*'
                    />
                    
                    <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                        <label className="flex-base">Number of competitive FBA sellers*</label>
                        <Input 
                            placeholder='No of competitive sellers' 
                            value={noCompSellers} 
                            setValue={setNoCompSellers}
                            error={(!noCompSellers || noCompSellers < 2) ? `No of competitive sellers must be at least 2.` : null} 
                        />
                    </div>

                    <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                        <label className="flex-base">Units in pack*</label>
                        <Input placeholder='Units in pack' value={unitsPack} setValue={setUnitsPack} />
                    </div>
                    {(!unitsPack || unitsPack === '') ?
                        <div className='error-message' style={{ textAlign: 'end', marginTop: -19, marginBottom: 15 }}>Please enter units in pack</div>
                    :null}
                    
                    
                    <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
                        <label className="flex-base">ASIN*</label>
                        <Input
                            type='text'
                            value={asin}
                            setValue={setASIN}
                            placeholder='ASIN'
                        />
                    </div>
                    {(!asin || asin === '') ?
                        <div className='error-message' style={{ textAlign: 'end', marginTop: -9 }}>Please enter ASIN</div>
                    :null}
                </div>
                <div className="col-12 text-center mt-5 ">
                    {
                        loading ?
                        <button type="button" className="btn-org">
                            <ScaleLoader color="white" height={15} />
                        </button>
                    :   
                        <button type="button" className="btn-org" onClick={done}>Next</button>
                    }
                </div>
                </div>
            </form>
        </div>
    )
}
