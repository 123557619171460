import Footer from 'components/footer'
import AddProductDetails from './components/AddProductDetails'
import useAddProduct from './useAddProduct'
import ProductKeywordsTable from './components/ProductKeywordsTable'
import AddProductComment from 'screens/editPrivateLabelProduct/components/AddProductComment'
import Modal from 'components/Modal/index.js'
import Disclaimer from './components/Disclaimer'
import { toast } from 'react-toastify'
import ConfirmationAlert from 'components/confimationAlert'

export default function AddProductKeywords({ close, id }) {
    const  [
        formik,
        loading,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        comment, setComment,
        addComment,
        isModal, setModal,
        averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
        helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
        helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
        helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
        averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
        availableBudget, setAvailableBudget,

        h10MainKWSVScreenshot, setH10MainKWSVScreenshot,
        h10MainKWSVWITHAMZSVScreenshot, setH10MainKWSVWITHAMZSVScreenshot,
        h10ExtensionCompleteScreenshot, setH10ExtensionCompleteScreenshot,
        googlesheetSrcST10SellerAvgReviewsSales, setGooglesheetSrcST10SellerAvgReviewsSales,
        googleTrendScreenshot, setGoogleTrendScreenshot,

        currency, setCurrency,
        topViewRef,
        bottomViewRef,
        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ] = useAddProduct(id, close)

    return (
        <div>
            <div className="card-body" style={{ height: '90vh', overflowY: 'auto' }}>
        {/* <div style={{ scrollBehavior: 'smooth', height: '100vh' }}>
            <div style={{ overflowY: 'auto' }}> */}


            <AddProductDetails
                formik={formik}
                loading={loading}
                jsScreenWS={jsScreenWS}
                setJSScreenWS={setJSScreenWS}
                jsScreenWRS={jsScreenWRS}
                setJSScreenWRS={setJSScreenWRS}
                helium10SWS={helium10SWS}
                setHelium10SWS={setHelium10SWS}
                cereboRKSWSVS={cereboRKSWSVS}
                setCereboRKSWSVS={setCereboRKSWSVS}
                helium10MKSH={helium10MKSH}
                setHelium10MKSH={setHelium10MKSH}
                past5YGT={past5YGT}
                setPast5YGT={setPast5YGT}
                profitMargin={profitMargin}
                setProfitMargin={setProfitMargin}
                averageSellingPriceOfTop10={averageSellingPriceOfTop10}
                setAverageSellingPriceOfTop10={setAverageSellingPriceOfTop10}
                helium10ExtScreenshotWithRevenueSorting={helium10ExtScreenshotWithRevenueSorting}
                setHelium10ExtScreenshotWithRevenueSorting={setHelium10ExtScreenshotWithRevenueSorting}
                helium10MagnetSearchVolumeHistory1Year={helium10MagnetSearchVolumeHistory1Year}
                setHelium10MagnetSearchVolumeHistory1Year={setHelium10MagnetSearchVolumeHistory1Year}
                helium10MagnetSearchVolumeHistoryAllTime={helium10MagnetSearchVolumeHistoryAllTime}
                setHelium10MagnetSearchVolumeHistoryAllTime={setHelium10MagnetSearchVolumeHistoryAllTime}
                averageReviewsOfTop10SS={averageReviewsOfTop10SS}
                setAverageReviewsOfTop10SS={setAverageReviewsOfTop10SS}
                availableBudget={availableBudget}
                setAvailableBudget={setAvailableBudget}

                h10MainKWSVScreenshot={h10MainKWSVScreenshot}
                setH10MainKWSVScreenshot={setH10MainKWSVScreenshot}
                h10MainKWSVWITHAMZSVScreenshot={h10MainKWSVWITHAMZSVScreenshot}
                setH10MainKWSVWITHAMZSVScreenshot={setH10MainKWSVWITHAMZSVScreenshot}
                h10ExtensionCompleteScreenshot={h10ExtensionCompleteScreenshot}
                setH10ExtensionCompleteScreenshot={setH10ExtensionCompleteScreenshot}
                googlesheetSrcST10SellerAvgReviewsSales={googlesheetSrcST10SellerAvgReviewsSales}
                setGooglesheetSrcST10SellerAvgReviewsSales={setGooglesheetSrcST10SellerAvgReviewsSales}
                googleTrendScreenshot={googleTrendScreenshot}
                setGoogleTrendScreenshot={setGoogleTrendScreenshot}

                currency={currency}
                setCurrency={setCurrency}
                productKeywords={productKeywords}
                myRef={topViewRef}
                setSubmitted={setSubmitted}
            />

            {
                productKeywords && productKeywords.length ?
                    <>
                        <ProductKeywordsTable
                            productKeywords={productKeywords}
                            setProductKeywords={setProductKeywords}
                        />
                        <AddProductComment
                            loading={loading}
                            comment={comment}
                            addComment={()=>{
                                if(comment === '') {
                                    toast.warn('Please add Product Details.')
                                } else {
                                    setModal(true)
                                }
                            }}
                            setComment={setComment}
                            myRef={bottomViewRef}
                        />
                    </>
                :
                    null
            }

            <Modal fullWidth show={isModal} close={()=>setModal(false)} hideClose={true}>
                <Disclaimer done={()=>{
                    setModal(false)
                    addComment()
                }} />
            </Modal>

            <Modal fullWidth show={isKeywordConfirmation} close={()=>{}} hideClose={true}>
                <ConfirmationAlert
                    title="Do you want to add more keywords to this product?"
                    description=""
                    onConfirm={onKeywordConfirmation}
                    onCancel={onKeywordCanelation}
                />
            </Modal>

            </div>
        </div>
    )
}
