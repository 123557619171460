import { ScaleLoader } from "react-spinners"
import Input from "./Input"

export default function AddProductComment({ 
    loading,
    comment,
    addComment,
    setComment,
    myRef,
}) {

    return (

        <div className="main-information-wrapper">
        
            <section className="dash-content-wrapper pro-details pt-5 pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase" ref={myRef}>Product Details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5">
                            {/* <form onSubmit={e=>e.preventDefault()}> */}
                                <div className="form-row ">
                                    <Input
                                        fillWidth
                                        multiline={6}
                                        value={comment} 
                                        setValue={setComment} 
                                        id="name" 
                                        label="Write Details About Product" 
                                        placeholder="Enter Details"
                                    />
                                    
                                </div>
                                <div className="pt-5 text-center">
                                    {
                                        loading ?
                                            <button type="button" className="btn btn-blue btn-lg orange-btn text-uppercase mb-4 mb-md-0">
                                                <ScaleLoader color="#fff" height={15} />
                                            </button>
                                        :
                                            <button type="button" className="btn btn-blue btn-lg orange-btn text-uppercase mb-4 mb-md-0" onClick={addComment}>Submit</button>
                                    }
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section>
        
        </div>

    )
}
