import DISCLAMIER from 'assets/images/disclaimer-icon.png'

import NavBar from 'components/navbar'
import Footer from 'components/footer'

export default function Warning() {
    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper pro-keywords pt-5 pb-md-2">
                    <div className="container">
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button>
                        <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content rdu-15">
                                <div className="card rdu-15">
                                    <div className="card-body text-center  p-3 p-md-5">
                                        <img src={DISCLAMIER} />
                                        <h3 className="text-uppercase text-danger">Disclaimer</h3>
                                        <hr />
                                        <ul className="wr-list pl-0 ">
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                        </ul>
                                        <hr />
                                        <div className="custom-control custom-checkbox my-4">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">I agree the above mention lorem ipsum generators on the internet</label>
                                        </div>
                                        <button type="button" className="btn aree-btn btn-secondary  text-uppercase text-white rdu-50 px-4 mb-3" disabled>Read & Agree </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            
            </div>
            
            <Footer />
        </div>
    )
}
