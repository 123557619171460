import axios from "axios";
import { useEffect, useState } from "react"

export default function useStudentDetail(id) {
    const [loading, setLoading] = useState(true)
    const [productsData, setProductsData] = useState(null)

    useEffect(() => {
        let token = localStorage.getItem('@token', '');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ENDPOINT}/api/student-productscount-history/${id}`,
            headers: { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config).then(response => {
            
            if(response.status === 200) {
                setProductsData(response.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
        })
    }, [])

    return [
        loading,
        productsData,
    ]
}