import React from 'react'

export default function Input({ value, setValue, id, type, label, placeholder, fillWidth }) {
    return (
        <div className={`form-group ${fillWidth ? 'col-md-12' : 'col-md-6'} mb-4`}>
            <label className="font-weight-bold h6 bl-clr" htmlFor={id || "inputName"}>{label}:</label>
            <input 
                required 
                value={value}
                type={type || "text"} 
                autoComplete="off"
                id={id || "inputName"} 
                className="form-control"
                placeholder={placeholder || ""} 
                onChange={e=>setValue(e.target.value)}
            />
        </div>
    )
}
