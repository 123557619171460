import axios from 'axios'
import MyModal from 'components/Modal'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { ScaleLoader } from 'react-spinners'
import { toast } from 'react-toastify'

export default function AuthenticateModal({ id, isModal, setModal, finishHandler }) {
    const history = useHistory()
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const reset = () => {
        setLoading(false)
        setPassword('')
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          submitHandler();
        }
    }

    const submitHandler = () => {
        let token = localStorage.getItem('@token', '');

        if(token && token !== '') {
            if(password === '' || password.length < 8) {
                toast.warn("Password must be 8 characters or more!")
            } else {

                setLoading(true)
                const config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_ENDPOINT}/api/authenticate/${id}`,
                    headers: { 
                      'Accept': 'application/json', 
                      'Content-Type': 'application/json', 
                      'Authorization': `Bearer ${token}`
                    },
                    data: { password }
                };
        
                axios(config)
                    .then(res => {
                        if(res.status === 200) {
                            finishHandler(res?.data?.data?.user_password, true)
                            reset()
                        } else {
                            toast.warn("Authentication failed. Please try again!")
                            finishHandler()
                            reset()
                        }
                    })
                    .catch(err => {
                        reset()
                        toast.error(
                            err?.response?.data?.message ||
                            'Something went wrong'
                        )
                    })
            }
        } else {
            toast.warn("No authenticated user found. Please login again!")
            history.push("/login")
            finishHandler()
            reset()
        }
    }

    return (
        <MyModal show={isModal} close={()=>setModal(false)}>
            <div className="card o-hidden border-0 shadow-lg">
                <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-2">Enter Your Password?</h1>
                                    <p className="mb-4">Passwords are critical. Just enter your password below and we'll verify you!</p>
                                </div>
                                <hr />
                                <form className="user">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            value={password}
                                            autoComplete="off"
                                            onKeyDown={onKeyDown}
                                            id="exampleInputEmail"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Password..."
                                            onChange={e=>setPassword(e.target.value)}
                                            className="form-control form-control-user rounded"
                                        />
                                    </div>
                                    <div className="d-flex bd-highlight align-items-center justify-content-center">
                                        {
                                            loading ?
                                                <a className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight" style={{ opacity: 0.5, color: 'white', width: 160 }}>
                                                    <ScaleLoader color="white" height={15} />
                                                </a>
                                            :
                                                <a 
                                                    onClick={submitHandler}
                                                    style={{ color: 'white', width: 160 }} 
                                                    className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor"
                                                >
                                                    Submit
                                                </a>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MyModal>
    )
}
