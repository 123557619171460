import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export default function MyModal({ show, children, fullWidth, close, hideClose }) {

    const modalstyles = {
      modal: {
        maxWidth: "100%",
      }
    };

    return (
        <Modal  open={show} onClose={close} center showCloseIcon={!hideClose} 
            styles={fullWidth ? modalstyles : null}
        >
            {children}
        </Modal>
    )
}
