import React from 'react'

export default function RadioGroup({ value, label, setValue }) {
    return (
        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
            <label className="flex-base">{label}</label>
            <div className="d-flex flex-row flex-base justify-content-between">
                <div className="form-check d-flex flex-row align-items-center">
                    <input checked={value === 'yes'} className="form-check-input" type="radio" name={label} id="yes-1" value="yes" onChange={e=>setValue(e.target.value)} />
                    <label className="form-check-label" htmlFor="yes-1">
                        Yes
                    </label>
                </div>
                <div className="form-check d-flex flex-row align-items-center">
                    <input checked={value === 'no'} className="form-check-input" type="radio" name={label} id="no-1" value="no" onChange={e=>setValue(e.target.value)} />
                    <label className="form-check-label" htmlFor="no-1">
                        No
                    </label>
                </div>
            </div>
        </div>
    )
}
