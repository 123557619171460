import {
    ADD_BATCH,
    ADD_EDITOR,
    ADD_STUDENT,
    ADD_TRAINER,
    ADD_COMMENTS,
    ADD_TRAINING,
    UPDATE_STATUS,
    ADMIN_LOADING,
    GET_ALL_EDITORS,
    GET_ALL_STUDENTS,
    GET_ALL_TRAINERS,
    GET_ALL_PRODUCTS,
    GET_MORE_EDITORS,
    GET_MORE_STUDENTS,
    GET_MORE_TRAINERS,
    UPDATE_EDITOR_STATUS,
    GET_PENDING_PRODUCTS,
    UPDATE_STUDENT_STATUS,
    GET_MORE_ALL_PRODUCTS,
    GET_MORE_ADMIN_LOADING,
    GET_MORE_PENDING_PRODUCTS,
    GET_PENDING_PRODUCTS_COUNT,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { unauthenticationHandler } from './authActions';

export const addTrainer = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-trainer`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: ADD_TRAINER,
                        payload: response.data.trainer,
                    });

                    toast.success('Trainer added successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.errors?.email && error?.response?.data?.errors?.email.length) ? 
                            error.response.data.errors.email[0] 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const addStudent = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-student`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: ADD_STUDENT,
                        payload: response.data.student,
                    });

                    toast.success('Student added successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.errors?.email && error?.response?.data?.errors?.email.length) ? 
                            error.response.data.errors.email[0] 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const addEditor = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-editor`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: ADD_EDITOR,
                        payload: response.data.editor,
                    });

                    toast.success('Editor added successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.errors?.email && error?.response?.data?.errors?.email.length) ? 
                            error.response.data.errors.email[0] 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const addTraining = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-training`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: ADD_TRAINING,
                        payload: response.data.training,
                    });

                    toast.success('Training added successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.message) ? 
                            error.response.data.message 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const addBatch = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/add-batch`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
    
                    dispatch({
                        type: ADD_BATCH,
                        payload: response.data.batch,
                    });

                    toast.success('Batch added successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.message) ? 
                            error.response.data.message 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const updateStatus = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/update-status`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data: { user_id: data.user_id, status: data.status, ...data }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    if(data.role === 'trainer') {
                        dispatch({
                            type: UPDATE_STATUS,
                            payload: { id: data.user_id, status: response.data.status},
                        });
                    } else if(data.role === 'student') {
                        dispatch({
                            type: UPDATE_STUDENT_STATUS,
                            payload: { id: data.user_id, status: response.data.status},
                        });
                    } else if(data.role === 'editor') {
                        dispatch({
                            type: UPDATE_EDITOR_STATUS,
                            payload: { id: data.user_id, status: response.data.status},
                        });
                    }

                    toast.success('Status updated successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const addComments = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'put',
                url: `${process.env.REACT_APP_ENDPOINT}/api/product/${data.id}`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data: { message: data.message, status: data.status }
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: ADD_COMMENTS,
                        payload: { id: data.user_id, status: response.data.status},
                    });

                    toast.success('Product status updated successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(
                    (error?.response?.data?.message) ? 
                            error.response.data.message 
                        : 
                            (error.message || 'Something went wrong!')
                )
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const getPendingProductsCount = (cb) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_LOADING, payload: true });
        
        let token = localStorage.getItem('@token', '');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ENDPOINT}/api/total-pending-products`,
            headers: { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config).then(response => {
            
            if(response.status === 200) {
                dispatch({
                    type: GET_PENDING_PRODUCTS_COUNT,
                    payload: response.data.pending_products_count || 0
                });

                cb && cb();
            } else {
                alert(response.data.message || 'Something went wrong!')
            
                dispatch({
                    type: ADMIN_LOADING,
                    payload: false
                });
            }
        }).catch(error => {
            if(error.response && error.response.status === 401) {
                dispatch(unauthenticationHandler())
            } else { 
                dispatch({
                    type: ADMIN_LOADING,
                    payload: false
                });
            }
        })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};

export const deleteUser = (data, cb) => async (dispatch) => {
    try {
            dispatch({ type: ADMIN_LOADING, payload: true });

            let token = localStorage.getItem('@token', '');

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}/api/user-delete/${data.id}`,
                headers: { 
                  'Accept': 'application/json', 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data
            };

            axios(config).then(response => {
                
                if(response.status === 200) {
                    dispatch({
                        type: ADMIN_LOADING,
                        payload: false,
                    });

                    toast.success('User deleted successfully.')
    
                    cb && cb();
                } else {
                    toast.error(response.data.message || 'Something went wrong!')
                
                    dispatch({ type: ADMIN_LOADING, payload: false });

                }
            }).catch(error => {
                if(error.response && error.response.status === 401) {
                    dispatch(unauthenticationHandler())
                } else { 
                    dispatch({ type: ADMIN_LOADING, payload: false });
                }
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            })
        
    } catch (e) {
        dispatch({ type: ADMIN_LOADING, payload: false });
    }
};