import React from 'react'

export default function Select({
    value,
    label,
    setValue,
    options,
}) {
    return (
        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start">
            <label className="flex-base">{label}</label>
            <select className="custom-select" value={value || ''} onChange={e=>setValue(e.target.value)}>
                <option value=''>Open this select menu</option>
                {
                    options.map(opt => (
                        <option key={opt.id} value={opt.id}>{opt.name}</option>
                    ))
                }
            </select>
        </div>
    )
}
