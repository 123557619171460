import React from 'react'

export default function InputPicker({ key, setValue, id, label, name, fillWidth }) {
    return (
        <div className={`input-group ${fillWidth ? 'col-md-12' : 'col-md-6'}`}>
            <label htmlFor={id} style={{ width: "100%" }}>{label}:</label>  
                    
            <div className="custom-file">
                <input 
                    key={key} 
                    onChange={e=>setValue(e.target.files[0])} 
                    accept="image/x-png,image/jpg,image/jpeg"
                    type="file" 
                    className="custom-file-input form-control" 
                    id="customFile"
                    autoComplete="off" 
                    required 
                />
                <label className="custom-file-label " htmlFor="customFile">{name}</label>
            </div>
        </div>
    )
}
