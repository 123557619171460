import React from 'react'

export default function ConfirmationAlert({ icon, description }) {
    return (
        <div className="card o-hidden border-0 shadow-lg">
            <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <div className="text-center">
                                {
                                    icon || 
                                    <i className="fas fa-info-circle fa-fw fa-5x" style={{ color: "#ff7a01" }} ></i>
                                }
                                <hr />
                                <h6 className="h4 mb-2" style={{ color: 'black', textAlign: 'left' }}>{description}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
