import { useFormik } from 'formik';
import * as Yup from 'yup';
import Footer from 'components/footer'
import { useDispatch, useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import Memoized from 'components/Memoized'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import InputPicker from 'components/Input/InputPicker'
import SelectPicker from 'components/Input/SelectPicker'
import { signUp } from 'redux/actions/studentActions'
import { getReferenceData } from 'redux/actions/utilActions'
import { useHistory } from 'react-router-dom'
import Header from 'components/header'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import InputMask from 'react-input-mask'
import Message from 'components/validationMessage/Message';
import { validateEmail } from 'utils/validation';
import ReactDOM from 'react-dom';

let isFirst = 0;

export default function AddStudent() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { loading } = useSelector(({ studentRed }) => studentRed)
    const { loading: isLoading, referenceData } = useSelector(({ utilsRed }) => utilsRed)

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            address: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().max(60, 'Must be 60 characters or less').required('Student Name is required'),
            email: Yup.string().email('Invalid email address').required('Student Email is required'),
            address: Yup.string().max(120, 'Must be 120 characters or less').required('Address is required'),
        }),
        onSubmit: values => {
            addHandler()
        },
    });

    const [cnic, setCNIC] = useState('')
    const [batch, setBatch] = useState('1')
    const [phone, setPhone] = useState('')
    const [trainer, setTrainer] = useState('')
    const [training, setTraining] = useState('')
    const [cnicBack, setCNICBack] = useState(null)
    const [cnicFront, setCNICFront] = useState(null)
    const [isSubmitted, setSubmitted] = useState(false)
    const [validations, setValidations] = useState({
        phone: false,
        cnic: false,
        trainer: false,
        training: false,
        batch: false,
        cnicBack: false,
        cnicFront: false
    })

    useEffect(()=>{
        dispatch(getReferenceData())
    }, [])

    // Remove errors if value is valid
    useEffect(() => {
        if(cnic !== '') setValidations(prev => ({ ...prev, cnic: false }))
        if(phone !== '') setValidations(prev => ({ ...prev, phone: false }))
        if(batch !== '') setValidations(prev => ({ ...prev, batch: false }))
        if(trainer !== '') setValidations(prev => ({ ...prev, trainer: false }))
        if(training !== '') setValidations(prev => ({ ...prev, training: false }))
        if(cnicFront) setValidations(prev => ({ ...prev, cnicFront: false }))
        if(cnicBack) setValidations(prev => ({ ...prev, cnicBack: false }))
    }, [phone, cnic, training, trainer, batch, cnicFront, cnicBack])

    // useEffect(() => {
    //     if(isFirst < 2) {
    //         isFirst++;
    //         setSubmitted(Math.random())
    //     }
    // }, [Object.keys(formik.errors).length])

    useEffect(() => {
        let elem = ReactDOM.findDOMNode(document.getElementById(Object.keys(formik.errors)[0]));

        if(elem) {
            elem.focus()
        }
    }, [isSubmitted])

    const handleError = (key, message) => {
        ReactDOM.findDOMNode(document.getElementById(key)).scrollIntoView()
        setValidations({
            ...validations,
            [key]: message
        })
    }

    const addHandler = () => {
        if(formik.values.name === "") toast.warn('Please enter Student name.')
        else if(formik.values.email === '' || !validateEmail(formik.values.email)) toast.warn('Please enter valid Student email.')
        else if(phone === '') handleError('phone', 'Please enter Student phone number.')
        else if(cnic === '') handleError('cnic', "Please enter Student CNIC number.")
        else if(trainer === '') handleError('trainer', 'Please select Trainer.')
        else if(training === '') handleError('training', 'Please select Training.')
        else if(batch === '') handleError('batch', 'Please select Batch.')
        else if(formik.values.address === '') toast.warn("Please enter Student Address.")
        else if(!cnicFront) handleError('cnicFront', 'Please choose CNIC front image.')
        else if(!cnicBack) handleError('cnicBack', 'Please choose CNIC back image.')
        else {
            let formData = new FormData()

            formData.append('name', formik.values.name)
            formData.append('email', formik.values.email)
            formData.append('phone', phone)
            formData.append('cnic', cnic)
            formData.append('trainer', trainer)
            formData.append('training', training)
            formData.append('batch', batch)
            formData.append('address', formik.values.address)
            formData.append('cnic_front_image', cnicFront)
            formData.append('cnic_back_image', cnicBack)

            dispatch(
                signUp( formData, ()=>{
                        formik.resetForm()
                        setPhone('')
                        setCNIC('')
                        setTrainer('')
                        setTraining('')
                        setBatch('')
                        setCNICFront(null)
                        setCNICBack(null)
                        history.push('/thankyou')
                    }
                )
            )
        }
    }

    return (
        <div>
            <Header />
            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h2 className="text-center mb-4" >Sign Up</h2>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div className={'form-group col-md-6 mb-4'}>
                                            <label htmlFor='name'>Student Name*:</label>
                                            <input
                                                id="name"
                                                type="text"
                                                maxLength={60}
                                                className="form-control"
                                                {...formik.getFieldProps('name')}
                                                placeholder="Enter Student Name"
                                            />
                                            { isSubmitted && formik.touched.name && formik.errors.name ? ( <Message value={formik.errors.name} /> ) : null}
                                        </div>
                                        <div className={`form-group col-md-6 mb-4`}>
                                            <label htmlFor='email'>Student Email*:</label>
                                            <input
                                                id="email"
                                                type="text"
                                                maxLength={60}
                                                className="form-control"
                                                {...formik.getFieldProps('email')}
                                                placeholder="Enter Student Email"
                                            />
                                            { isSubmitted && formik.touched.email && formik.errors.email ? ( <Message value={formik.errors.email} /> ) : null}
                                        </div>
                                        <div className={`form-group col-md-6 mb-4`}>
                                            <label id="phone" htmlFor={'phone'}>Student Phone*:</label>
                                            <PhoneInput
                                                id="phone"
                                                value={phone}
                                                maxLength={16}
                                                onChange={setPhone}
                                                className="form-control"
                                                placeholder="Enter Student Phone"
                                                style={{ borderWidth: '0px !important' }}
                                            />
                                            { isSubmitted && validations.phone ? ( <Message value={validations.phone} /> ) : null}
                                        </div>

                                        <div className='form-group col-md-6 md-'>
                                            <label id="cnic" htmlFor={'cnic'}>CNIC Number*:</label>
                                            <InputMask mask="99999-9999999-9" value={cnic} onChange={e=>setCNIC(e.target.value)}>
                                                {
                                                    (inputProps) =>
                                                    <input
                                                        required
                                                        id='cninc'
                                                        type={"text"}
                                                        {...inputProps}
                                                        className="form-control"
                                                        placeholder={"Enter Student CNIC Number"}
                                                    />
                                                }
                                            </InputMask>
                                            { isSubmitted && validations.cnic ? ( <Message value={validations.cnic} /> ) : null}
                                        </div>

                                        <Memoized
                                            required
                                            id='trainer'
                                            label='Trainer'
                                            value={trainer}
                                            setValue={setTrainer}
                                            Component={SelectPicker}
                                            validation={validations.trainer}
                                            options={referenceData ? referenceData.trainers : []}
                                        />

                                        <Memoized
                                            required
                                            id='training'
                                            label='Training'
                                            value={training}
                                            setValue={setTraining}
                                            Component={SelectPicker}
                                            validation={validations.training}
                                            options={referenceData ? referenceData.trainings : []}
                                        />

                                        {/* <Memoized
                                            required
                                            id='batch'
                                            label='Batch'
                                            value={batch}
                                            setValue={setBatch}
                                            Component={SelectPicker}
                                            validation={validations.batch}
                                            options={referenceData ? referenceData.batches : []}
                                        /> */}

                                        <div className={`form-group col-md-12 mb-4`}>
                                            <label htmlFor='address'>Student Address*:</label>
                                            <input
                                                id="address"
                                                type="text"
                                                maxLength={120}
                                                className="form-control"
                                                {...formik.getFieldProps('address')}
                                                placeholder="Enter Student Address"
                                            />
                                            { isSubmitted && formik.touched.address && formik.errors.address ? ( <Message value={formik.errors.address} /> ) : null}
                                        </div>

                                        <div id="cnicFront" style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className="col-md-6">
                                            <InputPicker fillWidth id='cnicFront' setValue={setCNICFront} label="Upload CNIC Front Image" name={cnicFront ? cnicFront.name : 'Choose File'}/>
                                            { isSubmitted && validations.cnicFront ? <Message value={validations.cnicFront} style={{ marginLeft: 20 }} /> : null }
                                        </div>

                                        <div id='cnicBack'style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className="col-md-6">
                                            <InputPicker fillWidth id='cnicBack' setValue={setCNICBack} label="Upload CNIC Back Image" name={cnicBack ? cnicBack.name : 'Choose File'}/>
                                            { isSubmitted && validations.cnicBack ? <Message value={validations.cnicBack} style={{ marginLeft: 20 }} /> : null }
                                        </div>
                                    </div>
                                    <div style={{ height: 15 }} />
                                    {
                                        isLoading || loading ?
                                        <button type="button" style={{ maxWidth: 160, marginLeft: 'auto' }} className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3"><ScaleLoader color="#fff" height={15} /></button>
                                        :
                                        <button type="button" style={{ maxWidth: 160, marginLeft: 'auto' }} className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3"
                                            onClick={()=>{
                                                setSubmitted(Math.random());
                                                formik.handleSubmit();
                                            }}
                                        >Submit</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}
