import { memo } from 'react'

const Memoized = ({ Component, children, ...componentProps }) => {
    return (
        children ?
            <Component {...componentProps}>{children}</Component>
        :   
            <Component {...componentProps} />
    )
}

export default memo(Memoized);