import React from 'react'

export default function Picker({ value, setValue, id, label, options, placeholder }) {
    return (
        <div className="form-group col-md-6 mb-4">
            <label className="font-weight-bold h6 bl-clr" htmlFor={id || "inputTraining"}>{label}:</label>
            <select value={value} onChange={e=>setValue(e.target.value)} className="custom-select form-control" id={id || "inputTraining"} required>
                <option selected value="" disabled="">{placeholder || "Please Select"}</option>
                {
                    options.map(opt => (
                        <option value={opt}>{opt}</option>
                    ))
                }
            </select>
        </div>
    )
}
