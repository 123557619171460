import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { useDispatch, useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import Memoized from 'components/Memoized'
import Input from './components/Input'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { changePassword } from 'redux/actions/authActions'
import BreadCrumbs from './components/BreadCrumbs'
import InputPicker from 'components/Input/InputPicker'
import axios from 'axios'
import Image from 'components/image'

export default function StudentProfile() {
    const dispatch = useDispatch()
    const { authLoading: loading, passwordLoading, user } = useSelector(({ auth }) => auth)

    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')

    const [imageUploading, setImageUploading] = useState(false)
    const [img1Updated, setImg1Updated] = useState(false)
    const [img2Updated, setImg2Updated] = useState(false)
    const [cnic_back_image, setCNICBackImage] = useState(user?.cnic_back_image)
    const [cnic_front_image, setCNICFrontImage] = useState(user?.cnic_front_image)

    const passwordHandler = () => {
        if(password === "") {
            toast.warn('Please enter password.')
        } else if(cPassword === '') {
            toast.warn('Please enter confirm password.')
        } else if(password !== cPassword) {
            toast.warn("Both passwords don't match.")
        } else {
            dispatch(
                changePassword(
                    {
                        password,
                    },
                    ()=>{
                        setPassword('')
                        setCPassword('')
                    }
                )
            )
        }
    }

    const uploadImages = () => {
        let formData = new FormData()

        if(img1Updated) {
            formData.append('is_cnic_front_image_updated', true)
            formData.append('cnic_front_image', cnic_front_image)
        }

        if(img2Updated) {
            formData.append('is_cnic_back_image_updated', true)
            formData.append('cnic_back_image', cnic_back_image)
        }

        setImageUploading(true)
        let token = localStorage.getItem('@token', '');

        var config = {
            method: 'post',
            data: formData,
            url: `${process.env.REACT_APP_ENDPOINT}/api/upload-cnic-images/${user.id}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };

        axios(config).then(response => {

            if(response.status === 200) {
                if(response?.data?.data?.cnic_front_image) {
                    setCNICFrontImage(response?.data?.data?.cnic_front_image)
                    setImg1Updated(false)
                }

                if(response?.data?.data?.cnic_back_image) {
                    setCNICBackImage(response?.data?.data?.cnic_back_image)
                    setImg2Updated(false)
                }
                toast.success('CNIC updated successfully.')
                setImageUploading(false)
            } else {
                toast.error(response.data.message || 'Something went wrong!')

                setImageUploading(false)
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message || 'Something went wrong!')
            setImageUploading(false)
        })
    }

    return (
        <div>
            <Memoized Component={NavBar} />
            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs className="mb-3" />
                        {/* Profile Card */}
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                {
                                    (loading) ?
                                        <div className="text-center"><ScaleLoader color="#ee6734" /></div>
                                    :
                                    (user) ?
                                        <div>
                                            <h2 className="text-center mb-4" >Student Details</h2>
                                            <div className="bordered-div">
                                                <p><span className="heading-txt">Name: </span> {user.name}</p>
                                                <p><span className="heading-txt">Email: </span> {user.email}</p>
                                                <p><span className="heading-txt">Address: </span> {user.address || 'N/A'}</p>
                                                {/*
                                                <p><span className="heading-txt">Batch: </span> {user.batch_name || 'N/A'}</p>
                                                */}
                                                <p><span className="heading-txt">Trainer: </span> {user.trainer_name || 'N/A'}</p>
                                                <p><span className="heading-txt">Training: </span> {user.training_name || 'N/A'}</p>

                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12">
                                                        {
                                                            cnic_front_image && typeof cnic_front_image === 'string' ?
                                                            <Image
                                                                alt="CNIC Front Image"
                                                                aria-label='CNIC Front Image'
                                                                src={process.env.REACT_APP_IMAGE_BASE_URL +"/" + cnic_front_image}
                                                            />
                                                            :
                                                            <InputPicker
                                                                fillWidth
                                                                id="inputGroupFile03"
                                                                label="CNIC Front Image"
                                                                setTouched={setImg1Updated}
                                                                setValue={setCNICFrontImage}
                                                                name={cnic_front_image ? cnic_front_image.name : 'Choose file'}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="col-md-6 col-xs-12">
                                                        {
                                                            cnic_back_image && typeof cnic_back_image === 'string' ?
                                                            <Image
                                                                alt="CNIC Back Image"
                                                                aria-label='CNIC Back Image'
                                                                src={process.env.REACT_APP_IMAGE_BASE_URL +"/" + cnic_back_image}
                                                            />
                                                            :
                                                            <InputPicker
                                                                fillWidth
                                                                id="inputGroupFile03"
                                                                label="CNIC Back Image"
                                                                setTouched={setImg2Updated}
                                                                setValue={setCNICBackImage}
                                                                name={cnic_back_image ? cnic_back_image.name : 'Choose file'}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="d-flex ml-auto">
                                                        {
                                                            imageUploading ?
                                                            <button type="button" style={{ maxWidth: 240, marginLeft: 'auto' }}
                                                                className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3"
                                                            >
                                                                <ScaleLoader color="#fff" height={15} />
                                                            </button>
                                                            :
                                                            ((cnic_front_image && cnic_back_image) && (img1Updated || img2Updated)) ?
                                                                <button type="button" onClick={uploadImages} style={{ maxWidth: 240, marginLeft: 'auto' }}
                                                                    className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3"
                                                                >
                                                                    Update CNIC
                                                                </button>
                                                            :   null

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <h2 style={{ textAlign: 'center' }}>No information found.</h2>
                                }
                            </div>
                        </div>

                        {/* Update Password Card */}
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h2 className="text-center mb-4" >Update Password</h2>

                                    <div className="row">
                                        <div className="col-md-6 col-xs-12">
                                            <Memoized
                                                fillWidth
                                                type="password"
                                                Component={Input}
                                                value={password}
                                                label="New Password"
                                                setValue={setPassword}
                                                placeholder="Enter new password"
                                            />
                                        </div>
                                        <div className="col-md-6 col-xs-12">
                                            <Memoized
                                                fillWidth
                                                type="password"
                                                Component={Input}
                                                value={cPassword}
                                                label="Confirm Password"
                                                setValue={setCPassword}
                                                placeholder="Confirm password"
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        {
                                            passwordLoading ?
                                                <button
                                                    type="button"
                                                    style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                    className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3"
                                                >
                                                    <ScaleLoader color="#fff" height={15} />
                                                </button>
                                            :
                                                <button
                                                    type="button"
                                                    onClick={passwordHandler}
                                                    style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                    className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3"
                                                >
                                                    Update
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <Memoized
                Component={Footer}
            />
        </div>
    )
}
