import React from 'react'
import { Grid } from '@material-ui/core'
import { BeatLoader } from 'react-spinners'
import { useSelector } from 'react-redux'

export default function StudentFilterView({ hideTrainer, filter, setFilter, applyFilter }) {
    const {
        referenceData
    } = useSelector(({ utilsRed }) => utilsRed)

    const changeHandler = (event) => {
        let { name, value } = event.target;
        
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const resetHandler = () => {
        setFilter({
            trainer: '',
            training: '',
            batch: '',
            status: ''
        })

        setTimeout(() => {
            applyFilter()
        }, 200);
    }
    
    let {
        batches,
        trainers,
        trainings,
    } = referenceData ? referenceData : { batches: [], trainers: [], trainings: [] };

    return (
        <div className="d-block d-md-flex align-items-center justify-content-center py-3">
            <div className="table-filler mt-4 mt-md-0">
                <form className="filler-form d-block d-md-flex align-items-center">
                    
                    <Grid container justify="center">

                        {
                            hideTrainer ? null :
                            <Grid item xs={12} md={3} sm={6}>
                                <div className="form-group mb-4 w-100 px-0 px-md-3">
                                    <select name="trainer" value={filter.trainer} onChange={(e)=>changeHandler(e)} className="form-control" style={{ height: 45, borderRadius: 25 }}>
                                        <option value=''>Trainer</option>
                                        {
                                            trainers.map((tr, i) => (
                                                <option key={i} value={tr.name}>{tr.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </Grid>
                        }

                        {
                            <Grid item xs={12} md={3} sm={6}>
                                <div className="form-group mb-4 w-100 px-0 px-md-3">
                                    <select name="training" value={filter.training} onChange={(e)=>changeHandler(e)} className="form-control" style={{ height: 45, borderRadius: 25 }}>
                                        <option value=''>Training</option>
                                        {
                                            trainings.map((tr, i) => (
                                                <option key={i} value={tr.name}>{tr.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </Grid>
                        }

                        {
                            <Grid item xs={12} md={3} sm={6}>
                                <div className="form-group mb-4 w-100 px-0 px-md-3">
                                    <select name="batch" value={filter.batch} onChange={(e)=>changeHandler(e)} className="form-control" style={{ height: 45, borderRadius: 25 }}>
                                        <option value=''>Batch</option>
                                        {
                                            batches.map((batch, i) => (
                                                <option key={i} value={batch.name}>{batch.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </Grid>
                        }

                        {
                            <Grid item xs={12} md={3} sm={6}>
                                <div className="form-group mb-4 w-100 px-0 px-md-3">
                                    <select name="status" value={filter.status} onChange={(e)=>changeHandler(e)} className="form-control" style={{ height: 45, borderRadius: 25 }}>
                                        <option value=''>Status</option>
                                        <option value='active'>Active</option>
                                        <option value='pending'>Pending</option>
                                        <option value='expired'>Deactivated</option>
                                    </select>
                                </div>
                            </Grid>
                        }

                        <div className="form-group mb-0 w-100 ">
                            {
                                false ?
                                    <div className="text-center table-load-more pt-3">
                                        <BeatLoader color="white" />
                                    </div>
                                :
                                    <div className="d-flex w-100 justify-content-center">
                                        <div className="text-center table-load-more mr-2">
                                            <input onClick={applyFilter} type="button" name="Load More" value="FILTER" style={{background: "#FF7D02", border: 'none', borderRadius: 25, color: 'white'}} className="tr-wt-btn py-2 px-5" />
                                        </div>
                                        {
                                            filter.batch !== '' || filter.status !== '' || filter.trainer !== '' || filter.training !== '' ?
                                                <div className="text-center table-load-more ml-2">
                                                    <input onClick={resetHandler} type="button" name="Load More" value="RESET" style={{background: "#FF7D02", border: 'none', borderRadius: 25, color: 'white'}} className="tr-wt-btn py-2 px-5" />
                                                </div>
                                            :
                                                null    
                                        }
                                    </div>
                            }
                        </div>
                        

                    </Grid>
                </form>
            </div>
        </div>
    )
}
