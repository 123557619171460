import ConfirmationAlert from 'components/confimationAlert';
import Modal from 'components/Modal/index.js';
import React, { useState } from 'react'
import ImageView from 'screens/editPrivateLabelProduct/components/ImageView';

export default function ProductKeywordsTable({
    productKeywords,
    setProductKeywords
}) {
    const [index, setIndex] = useState(null)
    const [isRemoveConfirmation, setRemoveConfirmation] = useState(false)

     const getRow = (data, index) => {
         let row = [];


        let values = Object.values(data)


        values = values.slice(2, values.length)
        values = values.map(val => URL.createObjectURL(val))

         Object.keys(data).map((key, i) => {
            if(i <= 1) {
                row.push(<td className="keyword-td">{data[key]}</td>)
            } else {
                row.push(<td className="keyword-td">
                    <ImageView
                        id={i}
                        index={i-2}
                        absolutePath
                        setImage={()=>{}}
                        src={URL.createObjectURL(data[key])}
                        srcs={values}
                    />
                </td>)
            }
        })

        row.push(
            <td className="keyword-td"><button type="button" className="approve-btn" onClick={()=>{setRemoveConfirmation(true); setIndex(index)}}>Remove</button></td>
        )

        return <tr>{row}</tr>
    }

    const removeItem = () => {
        let ps = [...productKeywords];
        ps = ps.filter((k, i) => i !== index);
        setProductKeywords(ps);
        setRemoveConfirmation(false);
    }

    return (
        <div>
            <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                <div className="container">
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    <h2 className="mb-3 text-uppercase">keywords</h2>
                                <table className="table " id="dataTable">

                                    <thead>
                                        <tr>
                                            <th className="keyword-td">Available Budget</th>
                                            <th className="keyword-td">Currecncy</th>
                                            <th className="keyword-td">H10 Main KW SV Screenshot</th>
                                            <th className="keyword-td">H10 Main KW SV WITH AMZ SV Screenshot</th>
                                            <th className="keyword-td">H10 Extension Complete Screenshot</th>
                                            <th className="keyword-td">Google sheet Screenshot that has Top 10 Seller Average Reviews, Sales,Revenue of Data Spark</th>
                                            <th className="keyword-td">Google Trend Screenshot</th>
                                            <th className="keyword-td"></th>


                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productKeywords.map((pKeywrod, i) => (
                                                getRow(pKeywrod, i)
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal fullWidth show={isRemoveConfirmation} close={()=>{}} hideClose={true}>
                <ConfirmationAlert
                    title="Are you sure you want to delete this keyword?"
                    description=""
                    onConfirm={()=>removeItem()}
                    onCancel={()=>setRemoveConfirmation(false)}
                />
            </Modal>
        </div>
    )
}
