import React, { useEffect } from 'react'

import ScaleLoader from "react-spinners/ScaleLoader";
import { getCurrentUser } from '../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function Loading(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        role,
        isAuth
    } = useSelector(({ auth }) => auth);

    useEffect(()=>{
        if(isAuth) {
            if(props.location.state) {
                history.push(props.location.state)
            } else {
                if(role === 'Student')
                    history.push("/student-home")
                else if(role === 'Admin' || role === "Super Admin")
                    history.push("/admin-home")
                else if(role === 'Editor')
                    history.push("/editor-home")
                else if(role === 'Trainer')
                    history.push("/trainer-home")
            }
        } else {
            dispatch(getCurrentUser(()=>history.push("/login")));
        }
    }, [isAuth])

    return (
        <div id="page-top" style={{ background: "#258cf5", height: '100vh' }}>

            <div id="login-wrapper" className="d-flex justify-content-center align-items-center min-h">
                <div className="login-content-rap col-xl-7 col-md-10 mx-auto">
                    <div className="container">
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '48vh' }}>
                            <ScaleLoader color="#fff" loading={true} size={150} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
