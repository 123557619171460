import { isProductPending } from 'utils/getProductStatus';
import {
    STUDENT_SIGNUP,
    STUDENT_LOADING,
    GET_EVS_DETAILS,
    STUDENT_MORE_LOADING,
    UPDATE_STUDENT_PRODUCT,
    ADD_PRODUCT_COMMENT,
    ADD_PRODUCT_KEYWROD,
    GET_PRODUCT_KEYWORD,
    GET_STUDENT_PRODUCTS,
    ADD_EVALUATED_PRODUCT,
    DELETE_PRODUCT_KEYWORD,
    DELETE_STUDENT_PRODUCT,
    ADD_PRODUCT_FOR_APPROVAL,
    GET_STUDENT_MORE_PRODUCTS,
    GET_STUDENT_PRODUCT_COUNT,
} from '../types';

const initState = {
    isProductRequested: false,
    evaluatedProducts: [],
    moreLoading: false,
    loading: false,
    keywords: [],
    comments: [],
    products: [],
    training_id: null,
    training_slug: null,
    product: null,
    approved: 0,
    archived: 0,
    pending: 0,
    evs: null,
    total: 0,
}


export default function reducer(state=initState, action) {
    let { payload } = action;

    switch (action.type) {
        case ADD_EVALUATED_PRODUCT: {
            let products = [...state.evaluatedProducts];
            products = products.concat(payload)

            return {
                ...state,
                loading: false,
                evaluatedProducts: products
            }
        }

        case GET_STUDENT_PRODUCTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                total: payload.total,
                isProductRequested: true,
                products: payload.products,
            }

        case GET_STUDENT_MORE_PRODUCTS: {
            let products = [...state.products]
            products = products.concat(payload.products)

            return {
                ...state,
                products,
                loading: false,
                moreLoading: false,
                total: payload.total,
            }
        }

        case GET_STUDENT_PRODUCT_COUNT: {
            return {
                ...state,
                loading: false,
                pending: payload.pending,
                approved: payload.approved,
                archived: payload.archived
            }
        }

        case ADD_PRODUCT_FOR_APPROVAL: {

            return {
                ...state,
                loading: false,
            }
        }

        case DELETE_STUDENT_PRODUCT: {
            let products = state.products.filter(p => p.id !== payload);
            let approved = products.filter(p => p.status.includes('Approve'))
            let pending = products.filter(p => (p.status !== 'Submitted'))

            return {
                ...state,
                pending,
                products,
                approved,
                loading: false,
            }
        }

        case GET_PRODUCT_KEYWORD:
            return {
                ...state,
                loading: false,
                keywords: payload.keywords || [],
                comments: payload.comments || [],
                product: payload.product || null,
                training_id: payload.training_id || null,
                training_slug: payload.training_slug || null
            }

        case ADD_PRODUCT_KEYWROD: {
            let ks = [...state.keywords];
            if(payload)
                ks.push(payload);

            return {
                ...state,
                keywords: ks,
                loading: false,
            }
        }

        case DELETE_PRODUCT_KEYWORD: {
            let ks = state.keywords.filter(k => k.id != payload);

            return {
                ...state,
                keywords: ks,
                loading: false
            }
        }

        case ADD_PRODUCT_COMMENT:
            return {
                ...state,
                loading: false,
            }

        case GET_EVS_DETAILS:
            return {
                ...state,
                evs: payload,
                loading: false,
            }

        case STUDENT_SIGNUP:
        return {
            ...state,
            loading: false
        }

        case STUDENT_MORE_LOADING:
            return {
                ...state,
                moreLoading: payload,
            }

        case STUDENT_LOADING:
            return {
                ...state,
                loading: payload,
            }

        case UPDATE_STUDENT_PRODUCT:
            return {
                ...state,
                product: {
                    ...state.product,
                    ...payload
                }
            }

        default:
            return state;
    }
}
