import { useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
import PasswordView from 'components/passwordView'
import useStudentDetail from './useStudentDetail'
import Image from 'components/image'

export default function ViewStudents({ student }) {
    const history = useHistory()
    const {role} = useSelector(({auth}) => auth)
    const {loading}=useSelector(({adminRed})=>adminRed)
    const  [
        productsLoading,
        productsData,
    ] = useStudentDetail(student?.id)

    useEffect(() => {
        if(student?.id) {}
        else history.goBack();
    }, [])

    return (
        <div className="card mb-5">
            <div className="card-body p-3 p-md-4">  
                <div id="all-pro-evaulation-wrapper" className="table-responsive">
                    {
                        loading ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ScaleLoader color="#4e73df" />
                            </div>
                        :    
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Name:</span><br />
                                        <span className="title-desc">{student?.name || 'N/A'}</span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Status:</span><br />
                                        <span className="title-desc">{student?.status || 'N/A'}</span>
                                    </div>
                                    
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Email:</span><br />
                                        <span className="title-desc">{student?.email || 'N/A'}</span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Phone:</span><br />
                                        <span className="title-desc">{student?.phone || 'N/A'}</span>
                                    </div>
                                    
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Trainer:</span><br />
                                        <span className="title-desc">{student?.trainer_name || 'N/A'}</span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Batch:</span><br />
                                        <span className="title-desc">{student?.batch_name || 'N/A'}</span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Training:</span><br />
                                        <span className="title-desc">{student?.training_name || 'N/A'}</span>
                                    </div>
                                    
                                    <div className="col-xs-6 col-md-6 mb-3">
                                        <span className="title-span">Address:</span><br />
                                        <span className="title-desc">{student?.address || 'N/A'}</span>
                                    </div>
                                    
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">CNIC Number:</span><br />
                                        <span className="title-desc">{student?.cnic || 'N/A'}</span>
                                    </div>
                                    {
                                        role == "Admin" ? 
                                            <div className="col-xs-12 col-md-6 mb-3" />
                                        :
                                            <div className="col-xs-12 col-md-6 mb-3">
                                                <span className="title-span">Password:</span><br />
                                                <PasswordView item={student} containerStyle={{ justifyContent: 'flex-start', marginLeft: 10 }} />
                                            </div>
                                    }
                                    
                                    <div className="col-sm-6 mb-3">
                                        <span className="title-span">CNIC Front Image:</span><br />
                                        {
                                            student?.cnic_front_image ?
                                                <Image
                                                    alt="cnic front image"
                                                    aria-label='CNIC Front Image'
                                                    src={process.env.REACT_APP_IMAGE_BASE_URL + '/' + student?.cnic_front_image}
                                                />
                                            :
                                                <i className="far fa-image fa-5x"></i>    
                                        }
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <span className="title-span">CNIC Back Image:</span><br />
                                        { student?.cnic_back_image ?
                                                <Image
                                                    alt="cnic back image"
                                                    aria-label='CNIC Back Image'
                                                    src={process.env.REACT_APP_IMAGE_BASE_URL + '/' + student?.cnic_back_image}
                                                />
                                            :
                                                <i className="far fa-image fa-5x"></i>
                                        }
                                        
                                    </div>

                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Total Products:</span><br />
                                        {
                                            productsLoading ? 
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            :
                                                null
                                        }
                                        <span className="title-desc">{productsData?.total_products}</span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Pending Products:</span><br />
                                        {
                                            productsLoading ?
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            :
                                                <span className="title-desc">{productsData?.pending_products}</span>
                                        }
                                    </div>

                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Approved Products:</span><br />
                                        {
                                            productsLoading ?
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            :
                                                <span className="title-desc">{productsData?.approved_products}</span>
                                        }
                                    </div>
                                    <div className="col-xs-12 col-md-6 mb-3">
                                        <span className="title-span">Rejected Products:</span><br />
                                            {
                                                productsLoading ?
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                :    
                                                    <span className="title-desc">{productsData?.rejected_products}</span>
                                            }
                                    </div>
                                    
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
