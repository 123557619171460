import AppNavigation from './routes'
import configureStore from './redux'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "react-awesome-lightbox/build/style.css"

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <AppNavigation />
      <ToastContainer />
    </Provider>
  );
}

export default App;
