import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { ScaleLoader } from 'react-spinners'
import { updateStatus } from "redux/actions/adminActions";
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'components/materialTable';
import BreadCrumbs from './BreadCrumbs';
import PasswordView from 'components/passwordView';

export default function ViewEditors() {
    const dispatch = useDispatch()
    const {role} = useSelector(({auth}) => auth)
    const {loading}=useSelector(({adminRed})=>adminRed)

    const updateHandler = (id, refresh) => {
        dispatch(updateStatus({
            user_id: id,
            role: 'editor'
        }))
        refresh()
    }

    const actionView = (editor, refresh) => {
        return(
            editor.status === 'active' ?
                <button 
                    type="button" 
                    onClick={()=>updateHandler(editor.id, refresh)} 
                    className="btn-org text-uppercase"
                >
                    Deactivate
                </button>
            : 
                <button 
                    type="button" 
                    onClick={()=>updateHandler(editor.id, refresh)} 
                    className="btn-org text-uppercase"
                >
                    Activate
                </button>
        )  
    }


    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
            
            <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                <div className="container">
                    <BreadCrumbs />
                    <h3 className="mb-0 text-uppercase text-white pb-4">All  Editors</h3>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            {
                                loading ?
                                    <div className="content-centered">
                                        <ScaleLoader color="#4e73df" />
                                    </div>
                                :    
                                    <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                        {
                                            loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <ScaleLoader color="#ee6734" />
                                                </div>
                                            :    
                                                <MaterialTable
                                                    method='post'
                                                    url={process.env.REACT_APP_ENDPOINT+'/api/user/Editor'}
                                                    columns={
                                                        role == "Admin" ?
                                                            [
                                                                { id: 'name', label: 'Name', minWidth: 100, searchable: true, sortable: true, index: 0 },
                                                                { id: 'email', label: 'Email', minWidth: 150, searchable: true, sortable: true, index: 1 },
                                                                { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: true, index: 2 },
                                                                { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                                            ]
                                                        :
                                                            [
                                                                { id: 'name', label: 'Name', minWidth: 100, searchable: true, sortable: true, index: 0 },
                                                                { id: 'email', label: 'Email', minWidth: 150, searchable: true, sortable: true, index: 1 },
                                                                { id: 'password_s_t_r', Component: PasswordView, label: 'Password', minWidth: 100 },
                                                                { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: true, index: 2 },
                                                                { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                                            ]
                                                    }
                                                />
                                        }
                                    </div>
                            }

                            
                        </div>
                    </div>
                </div>
            </section>
            </div>
            
            <Footer />
        </div>
    )
}
