import MyModal from 'components/Modal';
import React, { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { deleteProductKeyword } from 'redux/actions/studentActions';
import { getKeywordImages } from 'utils/extractProducts';
import ImageView from './ImageView';
import './styles.css';

export default function ProductKeywordsTable({
    show,
    loading,
    productKeywords,
    setProductKeywords
}) {
    const dispatch = useDispatch()
    const [img, setImage] = useState('')
    const [isModal, setModal] = useState(false)
    const {user} = useSelector(({auth}) => auth)
    const {product} = useSelector(({studentRed}) => studentRed)




    const removeItem = (id) => {
        dispatch(deleteProductKeyword(id))
    }

    const updateKeyword = (id, data) => {
        let keywords = []

        keywords = productKeywords.map((keyword, i) => {
            if(keyword.id == id) {
                return {
                    ...keyword,
                    ...data
                }
            } else {
                return keyword
            }
        })

        setProductKeywords(keywords)
    }

    return (
        <div>
               <section className="dash-content-wrapper all-pro-evaulation">
                <div className="container">
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                <h2 className="mb-3 text-uppercase">keywords</h2>

                                {
                                    (loading && !show) ?
                                        <div className="text-center">
                                            <ScaleLoader color="#ee6734" />
                                        </div>
                                    :
                                        productKeywords.length ?
                                            <table className="table " id="dataTable">

                                                <thead>
                                                    <tr>
                                                        <th className="keyword-td">KEYWORD</th>
                                                        <th className="keyword-td">Competing Products</th>
                                                        <th className="keyword-td">Egrow SV</th>

                                                        <th className="keyword-td">Average Reviews of Top 10</th>
                                                        <th className="keyword-td">Average Selling Price of Top 10</th>
                                                        <th className="keyword-td">Profit Margin</th>
                                                        <th className="keyword-td">Available Budget</th>
                                                        <th className="keyword-td">Currecncy</th>
                                                        <th className="keyword-td">Avg Monthly Revenue of Top 10</th>
                                                        <th className="keyword-td">60% of the Total Monthly Revenue of Top 10</th>

                                                        <th className="keyword-td">Egrow Revenue Sorted</th>
                                                        <th className="keyword-td">Egrow Revenue Unsorted</th>
                                                        <th className="keyword-td"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        productKeywords.map((key, i) => (
                                                            <tr key={i}>
                                                                <td className="keyword-td">{key.keyword || 'N/A'}</td>
                                                                <td className="keyword-td">{key.competing_products || 'N/A'}</td>
                                                                <td className="keyword-td">{key.egrow_sv || 'N/A'}</td>
                                                                <td className="keyword-td">{key.average_reviews_of_top_10 || 'N/A'}</td>
                                                                <td className="keyword-td">{key.average_selling_price_of_top_10 || 'N/A'}</td>
                                                                <td className="keyword-td">{key.profit_margin || 'N/A'}</td>
                                                                <td className="keyword-td">{key.available_budget || 'N/A'}</td>
                                                                <td className="keyword-td">{key.currency || 'N/A'}</td>
                                                                <td className="keyword-td">{key.avg_monthly_revenue_of_top_10 || 'N/A'}</td>
                                                                <td className="keyword-td">{key.sixty_percent_of_the_total_monthly_revenue_of_top10 || 'N/A'}</td>

                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={13}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='Egrow Revenue Sorted'
                                                                        objectKey='egrow_revenue_sorted'
                                                                        src={key.egrow_revenue_sorted}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={14}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='Egrow Revenue Unsorted'
                                                                        objectKey='egrow_revenue_unsorted'
                                                                        src={key.egrow_revenue_unsorted}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>

                                                                <td className="keyword-td">
                                                                    {
                                                                        show ?
                                                                            loading ?
                                                                                <button type="button" className="approve-btn">
                                                                                    <ScaleLoader color="#fff" height={20} />
                                                                                </button>
                                                                            :
                                                                                <button type="button" className="approve-btn" onClick={()=>removeItem(key.id)}>Remove</button>
                                                                        :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        :
                                            <h5 style={{ textAlign: 'center' }}>No Keywords Found.</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            <MyModal show={isModal} close={()=>setModal(false)}>
                <div className='image-view'>
                    <img src={img} style={{width: '100%', height: 'auto' }} />
                </div>
            </MyModal>
        </div>
    )
}
