import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import rootReducer from './reducers';
import { LOGOUT } from './types';

const getMiddleware = () => {
  const middleware = [reduxThunk];

  return applyMiddleware(...middleware);
};

const appReducer = (state, action) => {
  let newState = state;

  if (action.type === LOGOUT) {
    localStorage.clear();
    newState = undefined;
  }

  return rootReducer(newState, action);
};

export default () => {
  let store = createStore(appReducer, getMiddleware());
  return store;
};