import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
} from "react-router-dom";
import { useSelector } from "react-redux";

import Login from 'screens/login';
import Forget from 'screens/forget';
import Loading from 'screens/loading';
import ThankYou from 'screens/thankyou';
import ProductApproval from 'screens/productApproval';
import AdminProductApproval from 'screens/adminProductApproval';
import AdminApprovedProduct from 'screens/adminProductApproval';
import KeywordDetails from 'screens/keywordDetails';
import EditPrivateLabelProduct from 'screens/editPrivateLabelProduct';
import EVSDetails from 'screens/evsDetails';
import StudentProfile from 'screens/studentProfile';
import ApprovedSuccessfully from 'screens/approvedSuccess';
import Warning from 'screens/warning';
import StudentDetails from 'components/StudentDetails';
import NotFound from 'screens/notFound';
import ResetPassword from 'screens/resetPassword';

// Students Components
import SignUp from 'screens/student/signup';
import StudentHome from 'screens/student/home';
import SavedProducts from 'screens/student/savedProducts';
import StudentAllProducts from 'screens/student/allProducts';
import ProductEvaluation from 'screens/student/productEvaluation';
import StudentPendingProducts from 'screens/student/pendingProducts';
import StudentApprovedProducts from 'screens/student/approvedProducts';
import EditEvaluatedProduct from 'screens/student/editEvaluatedProduct';
import StudentEvaluatedProducts from 'screens/student/evaluatedProducts';
import AddPrivateLabelProduct from 'screens/student/addPrivateLabelProduct';
import AddWalmartProduct from 'screens/student/addWalmartProduct';
import AddGulfProduct from 'screens/student/addGulfProduct';


// Admin Components
import AdminHome from "screens/admin/home";
import AllProducts from "screens/admin/allProducts";
import PendingProducts from "screens/admin/pendingProducts";
import AddStudent from 'screens/admin/addStudent';
import AddTrainer from 'screens/admin/addTrainer';
import AddTraining from 'screens/admin/addTraining';
import AddEditor from 'screens/admin/addEditor';
import AddBatch from 'screens/admin/addBatch';
import ViewTrainers from 'screens/admin/viewTrainers';
import ViewStudents from 'screens/admin/viewStudents';
import ViewEditors from 'screens/admin/viewEditors';
import ViewTrainings from 'screens/admin/viewTrainings';
import ViewBatches from 'screens/admin/viewBatches';
import ViewActivityLogs from 'screens/admin/viewActivityLogs';

// Editor Components
import EditorHome from 'screens/editor/home';
import EditStudentEvs from 'screens/editor/evsDetails';
import ViewEditorStudents from 'screens/editor/viewStudents';

// Trainer Components
import TrainerHome from 'screens/trainer/home';
import TrainerAddStudent from 'screens/trainer/addStudent';
import TrainerAllProducts from 'screens/trainer/allProducts';
import TrainerViewStudents from 'screens/trainer/viewStudents';
import TrainerPendingProducts from 'screens/trainer/pendingProducts';
import TrainerPendingStudents from 'screens/trainer/pendingStudents';
import MainWrapper from "components/MainWrapper";

export default function Routes() {
    const {
        role,
        isAuth,
    } = useSelector(({ auth }) => auth)

    const PrivateRoute = ({ component: Component, ...rest }) => {
        let token = localStorage.getItem('@token', '');

        if(isAuth) {
            switch (role) {
                case 'Student': {
                    if(
                        rest.path === '/student-home' || rest.path === '/student-all-products' || rest.path === '/add-product' || rest.path === '/add-walmart-product' ||
                        rest.path === '/add-gulf-product' || rest.path === '/edit-product' || rest.path === '/student-profile' || rest.path === '/evs-details' ||
                        rest.path === '/product-evaluation' || rest.path === '/student-pending-products' || rest.path === '/student-approved-product' ||
                        rest.path === '/student-evaluated-products' || rest.path === '/edit-evaluated-product' || rest.path === '/saved-products'
                    )
                        return <Route {...rest} render={(props) => (<Component {...props} />)} />
                    else
                        return <Redirect to={{ pathname: "/", state: 'student-home' }} />
                }

                case 'Admin': {
                    if(
                        rest.path === '/admin-home' || rest.path === '/pending-products' || rest.path === '/edit-product' ||
                        rest.path === '/all-products' || rest.path === '/add-trainer' || rest.path === '/add-student' ||
                        rest.path === '/add-editor' || rest.path === '/add-training' || rest.path === '/add-batch' ||
                        rest.path === '/view-trainers' || rest.path === '/view-editors' || rest.path === '/view-students' ||
                        rest.path === '/view-trainings' || rest.path === '/view-batches' || rest.path === "/view-activity-logs"
                    )
                        return <Route {...rest} render={(props) => (<Component {...props} />)} />
                    else
                        return <Redirect to={{ pathname: "/", state: 'admin-home' }} />
                }

                case 'Super Admin': {
                    if(
                        rest.path === '/admin-home' || rest.path === '/pending-products' || rest.path === '/edit-product' ||
                        rest.path === '/all-products' || rest.path === '/add-trainer' || rest.path === '/add-student' ||
                        rest.path === '/add-editor' || rest.path === '/add-training' || rest.path === '/add-batch' ||
                        rest.path === '/view-trainers' || rest.path === '/view-editors' || rest.path === '/view-students' ||
                        rest.path === '/view-trainings' || rest.path === '/view-batches' || rest.path === "/view-activity-logs"
                    )
                        return <Route {...rest} render={(props) => (<Component {...props} />)} />
                    else
                        return <Redirect to={{ pathname: "/", state: 'admin-home' }} />
                }

                case 'Editor': {
                    if(rest.path === '/editor-home' || rest.path === '/view-editor-students' || rest.path === '/edit-student-evs')
                        return <Route {...rest} render={(props) => (<Component {...props} />)} />
                    else
                        return <Redirect to={{ pathname: "/", state: 'editor-home' }} />
                }

                case 'Trainer': {
                    if(rest.path === '/trainer-home' || rest.path === '/trainer-pending-products' || rest.path === '/trainer-all-products' ||
                    rest.path === '/trainer-add-student' || rest.path === '/trainer-view-students' || rest.path === '/edit-product' ||
                    rest.path === '/trainer-pending-students')
                        return <Route {...rest} render={(props) => (<Component {...props} />)} />
                    else
                        return <Redirect to={{ pathname: "/", state: 'trainer-home' }} />
                }

                default:
                    return <Route {...rest} render={(props) => (<Component {...props} />)} />
            }

        } else if(token && token !== '') {
            return <Redirect to={{ pathname: "/", state: rest.path }} />
        } else {
            return <Redirect to='/login' />
        }
    }

    return(
        <Router>
            <MainWrapper>
                <Switch>

                    {/* Auth Routes */}
                    <Route exact path='/' component={Loading} />
                    <Route path='/login' component={Login} />
                    <Route path='/forget' component={Forget} />
                    <Route path='/password/reset/:token' component={ResetPassword} />
                    <Route path='/signup' component={SignUp} />
                    <Route path='/thankyou' component={ThankYou} />

                    <Route path='/productApproval' component={ProductApproval} />
                    <Route path='/adminProductApproval' component={AdminProductApproval} />
                    <Route path='/adminApprovedProduct' component={AdminApprovedProduct} />
                    <Route path='/keywordDetails' component={KeywordDetails} />
                    <Route path='/approvedSuccessfully' component={ApprovedSuccessfully} />
                    <Route path='/warning' component={Warning} />
                    <Route path='/student-details' component={StudentDetails} />


                    {/* Student Routes */}
                    <PrivateRoute path='/student-all-products' component={StudentAllProducts} />
                    <PrivateRoute path='/saved-products' component={SavedProducts} />
                    <PrivateRoute path='/student-home' component={StudentHome} />
                    <PrivateRoute path='/add-product' component={AddPrivateLabelProduct} />
                    <PrivateRoute path='/add-walmart-product' component={AddWalmartProduct} />
                    <PrivateRoute path='/add-gulf-product' component={AddGulfProduct} />
                    <PrivateRoute path='/edit-product' component={EditPrivateLabelProduct} />
                    <PrivateRoute path='/evs-details' component={EVSDetails} />
                    <PrivateRoute path='/student-profile' component={StudentProfile} />
                    <PrivateRoute path='/product-evaluation' component={ProductEvaluation} />
                    <PrivateRoute path='/edit-evaluated-product' component={EditEvaluatedProduct} />
                    <PrivateRoute path='/student-pending-products' component={StudentPendingProducts} />
                    <PrivateRoute path='/student-approved-product' component={StudentApprovedProducts} />
                    <PrivateRoute path='/student-evaluated-products' component={StudentEvaluatedProducts} />



                    {/* Admin Routes */}
                    <PrivateRoute path='/add-batch' component={AddBatch} />
                    <PrivateRoute path='/add-editor' component={AddEditor} />
                    <PrivateRoute path='/admin-home' component={AdminHome} />
                    <PrivateRoute path='/add-student' component={AddStudent} />
                    <PrivateRoute path='/add-trainer' component={AddTrainer} />
                    <PrivateRoute path='/add-training' component={AddTraining} />
                    <PrivateRoute path='/all-products' component={AllProducts} />
                    <PrivateRoute path='/view-editors' component={ViewEditors} />
                    <PrivateRoute path='/view-students' component={ViewStudents} />
                    <PrivateRoute path='/view-trainers' component={ViewTrainers} />
                    <PrivateRoute path='/pending-products' component={PendingProducts} />
                    <PrivateRoute path='/view-trainings' component={ViewTrainings} />
                    <PrivateRoute path='/view-batches' component={ViewBatches} />
                    <PrivateRoute path="/view-activity-logs" component={ViewActivityLogs} />


                    {/* Editor Routes */}
                    <PrivateRoute path='/editor-home' component={EditorHome} />
                    <PrivateRoute path='/edit-student-evs' component={EditStudentEvs} />
                    <PrivateRoute path='/view-editor-students' component={ViewEditorStudents} />

                    {/* Trainer Routes */}
                    <PrivateRoute path='/trainer-home' component={TrainerHome} />
                    <PrivateRoute path='/trainer-add-student' component={TrainerAddStudent} />
                    <PrivateRoute path='/trainer-all-products' component={TrainerAllProducts} />
                    <PrivateRoute path='/trainer-view-students' component={TrainerViewStudents} />
                    <PrivateRoute path='/trainer-pending-products' component={TrainerPendingProducts} />
                    <PrivateRoute path='/trainer-pending-students' component={TrainerPendingStudents} />

                    <Route exact path="/*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </MainWrapper>
        </Router>
    )
}
