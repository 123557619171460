import {
    EDITOR_LOADING,
    GET_STUDENT_EVS,
    EDIT_STUDENT_EVS,
    EDITOR_MORE_LOADING,
    GET_EDITOR_STUDENTS,
    GET_EDITOR_MORE_STUDENTS
} from '../types';

const initState = {
    moreLoading: false,
    loading: false,
    evsDetails: '',
    students: [],
    total: 0,
}

export default function reducer(state=initState, action) {
    let { payload } = action;

    switch (action.type) {
        case GET_EDITOR_STUDENTS:
            return {
                ...state,
                loading: false,
                moreLoading: false,
                total: payload.total,
                students: payload.users,
            }

        case GET_EDITOR_MORE_STUDENTS: {
            let students = [...state.students]
            students = students.concat(payload.users)

            return {
                ...state,
                students,
                loading: false,
                moreLoading: false,
                total: payload.total,
            }
        }

        case EDIT_STUDENT_EVS: {
            let students = state.students.map(s => {
                if(s.id == payload.user_id)
                    return {
                        ...s,
                        evs_status: payload.status,
                        evs_password: payload.password,
                    }
                else return s;
            })

            return {
                ...state,
                students,
                loading: false,
            }
        }

        case EDITOR_MORE_LOADING:
            return {
                ...state,
                moreLoading: payload,
            }

        case EDITOR_LOADING:
            return {
                ...state,
                loading: payload,
            }

        case GET_STUDENT_EVS:
            return {
                ...state,
                moreLoading: false,
                evsDetails: payload,
            }
    
        default:
            return state;
    }
}