import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'components/materialTable'

export default function EvaluatedProducts() {
    const history = useHistory()

    const actionView = (product) => {
        return(
            <button 
                type="button" 
                className="btn-org text-uppercase"
                onClick={()=>history.push("/edit-evaluated-product", {
                    product
                })}
            >
                View
            </button>
        )
    }

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <h3 className="mb-0 text-uppercase text-white pb-4">Evaluated  Products</h3>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                 <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    <MaterialTable
                                        method='get'
                                        url={process.env.REACT_APP_ENDPOINT+'/api/student-training-products'}
                                        columns={[
                                            { id: 'asin', label: 'ASIN', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'market', label: 'Marketplace', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'buy_box_suppressed', label: 'Buy Box Suppressed', minWidth: 150, searchable: true, sortable: true },
                                            { id: 'above_50_reviews', label: 'Above 50 Reviews', minWidth: 150, searchable: true, sortable: true },
                                            { id: 'fragile_items', label: 'Fragile Item', minWidth: 150, searchable: true, sortable: true },
                                            { id: 'hazmat_product', label: 'Hazmat Product', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'price_stable_over_90days', label: 'Price Stable', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'brand_is_only_seller', label: 'Brand Only Seller', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'product_brand_restricted', label: 'Brand Restricted', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'fbm_controls_buy_box', label: 'FBM Controls Buy Box', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'monthly_revenue', label: 'Monthly Revenue', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'number_of_competitive_fba_sellers', label: 'No of Sellers', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'bsr', label: 'BSR', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'units_in_pack', label: 'Units In Pack', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'category', label: 'Category', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'bsr_stable_over_90days', label: 'BSR Stable', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />
        </div>
    )
}
