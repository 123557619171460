import {
    LOGIN,
    CHANGE_PASSWORD,
    LOGIN_LOADING_ON,
    GET_CURRENT_USER,
    LOGIN_LOADING_OFF,
    CHANGE_PASSWORD_LOADING,
    GET_CURRENT_USER_LOADING_ON,
    GET_CURRENT_USER_LOADING_OFF
} from '../types';

const initState = {
    role: '',
    user: null,
    isAuth: false,
    authLoading: false,
    passwordLoading: false,
    currentUserLoading: false,
}

export default function reducer(state=initState, action) {
    let { payload } = action;

    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuth: true,
                user: action.payload.user,
                role: action.payload.role,
                authLoading: false,
            }

        case LOGIN_LOADING_ON: 
            return {
                ...state,
                authLoading: true
            }

        case LOGIN_LOADING_OFF: 
            return {
                ...state,
                authLoading: false
            }

        case GET_CURRENT_USER:
            return {
                ...state,
                isAuth: true,
                user: action.payload.user,
                role: action.payload.role ? action.payload.role : '',
                currentUserLoading: false,
            }

        case GET_CURRENT_USER_LOADING_ON: 
            return {
                ...state,
                currentUserLoading: true
            }

        case GET_CURRENT_USER_LOADING_OFF: 
            return {
                ...state,
                currentUserLoading: false
            }

        case CHANGE_PASSWORD:
            return {
                ...state,
                passwordLoading: false,
                user: {
                    ...state.user,
                    password: payload
                }
            }

        case CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                passwordLoading: payload
            }
    
        default:
            return state;
    }
}