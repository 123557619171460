import DISCLAMIER from 'assets/images/disclaimer-icon.png'
import { useEffect, useState, useRef } from 'react'
import './style.css'

export default function Disclaimer({ done }) {
    const [agreed, setAGreed] = useState(false)
    const [clicked, setClicked] = useState(false)
    const myRef = useRef(null)

    const executeScroll = () => {
        if(myRef && myRef.current)
            myRef.current.scrollIntoView()
    }

    useEffect(()=>{
        executeScroll()
    }, [])

    return (
        <div className="card rdu-15">
            <div className="card-body text-center  p-3 p-md-5" style={{ height: '90vh', overflowY: 'auto' }}>
                <img src={DISCLAMIER}  ref={myRef} />
                <h3 className="text-uppercase text-danger">Disclaimer</h3>
                <hr />
                <ul className="wr-list pl-0 ">
                    <li className="list-item">I understand Enabler Seller Dashboard: All students can avail ESD for Wholesale FBA. Enablers will provide access but will not be liable for profits on listed products. Students should understand and calculate profitability before ordering products. Enablers will not be responsible for any loss or selling issues.</li>
                    <li className="list-item">I understand Product Hunting and Approval: Every student should do product research and select his product. Enablers will be evaluating and suggest to launch that product thru product approval system.</li>
                    <li className="list-item">I understand APPROVED PRODUCT: doesn’t mean that Enablers is liable for Sales and Ranking</li>
                    <li className="list-item">I understand Enablers will not be responsible if selected product will not get sales, or ranked on first position due to any reason.</li>
                    <li className="list-item">I understand Enablers is not responsible for any financial transactions of students in or outside of Pakistan.</li>
                    <li className="list-item">I understand there may NOT be any profit at all when you start selling.</li>
                    <li className="list-item">I understand there might be a loss in Private Label Business (if I decide to do this).</li>
                    <li className="list-item">I understand there will be Giveaways(Free Samples) at some point too to gain Sales Velocity.</li>
                    <li className="list-item">I understand I might have to sell at breakeven price for some time to gain Sales Velocity.</li>
                    <li className="list-item">I understand there will be Advertising cost involved to rank your products.</li>
                    <li className="list-item">I understand I might have to get Reviews in order to create Social Proof for your listing.</li>
                    <li className="list-item">I understand Product Hunting has a tough criteria and it can be frustrating to hunt a product.</li>
                    <li className="list-item">I understand it’s not a Get Rich Quick Scheme.</li>
                </ul>
                <hr />
                <div className="custom-control custom-checkbox my-4">
                    <input value={agreed} onChange={()=>{setClicked(false); setAGreed(prev => !prev)}} type="checkbox" style={{ height: 20, width: 20, marginBottom: 5, marginRight: 10 }} id="customCheck1" />
                    <label  htmlFor="customCheck1" style={{ fontSize: 24, color: 'black' }} className="noselect">I agree to above mentioned terms and conditions</label>
                </div>
                {/*  className="custom-control-input", className="custom-control-label" */}
                <button 
                    onClick={()=>{
                        setClicked(true)
                        done()
                    }} 
                    type="button" 
                    className="btn aree-btn btn-secondary  text-uppercase text-white rdu-50 px-4 mb-3" 
                    disabled={agreed ? clicked ? true : false : true}
                >
                    Read & Agree
                </button>
            </div>
        </div>
    )
}
