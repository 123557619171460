import Message from 'components/validationMessage/Message'
import React from 'react'

export default function SelectPicker({ value, options, required, setValue, id, label, fillWidth, validation }) {
    return (
        <div  className={`input-group ${fillWidth ? 'col-md-12' : 'col-md-6'}`} style={{ flexDirection: 'column' }}>
            <label htmlFor={id}>{label}{required ? '*' : ''}:</label>
            
            <select id={id} 
                required 
                value={value}
                className="form-control" 
                onChange={e => setValue(e.target.value)}
                style={{ width: '100%', borderRadius: 5 }} 
            >
                <option value=''>Select {label}</option>
                {
                    options.map((option, i) => (
                        <option key={i} value={option.id}>{option.name}</option>
                    ))
                }
            </select>
            {
                validation ?
                    <Message value={validation} />
                :
                    null
            }
        </div>
    )
}
