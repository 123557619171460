import DROPBOX from 'assets/images/dropbox-icon.png'
import PRO from 'assets/images/pro-icon.png'

import NavBar from 'components/navbar'
import Footer from 'components/footer'
import useStudentData from './useStudentData'
import { ScaleLoader } from 'react-spinners'
import Card from 'components/card'
import { useHistory } from 'react-router-dom'
import Memoized from 'components/Memoized'
import Modal from 'components/Modal'
import { trainigID } from 'constants/constants'
import InfoAlert from 'components/infoAlert'

export default function ApprovedProduct() {
    const [
        pending,
        approved,
        loading,
        user,
        canAddProduct,
        archived,
        isPopup,
        setIsPopup
    ] = useStudentData()

    const history = useHistory()
    if(user.training_id ==15 ){
        let route = 'add-walmart-product'
    }

    return (
        <div>
            <Memoized Component={NavBar} />
            <div className="main-information-wrapper">
                <section className="dash-content-wrapper pt-5 pb-md-4">
                    <div className="container">
                        {
                            user.training_id == trainigID ?
                                <div className="row">
                                    <Card
                                        image={DROPBOX}
                                        loading={loading}
                                        value={pending || 0}
                                        title={`Evaluated \n Products`}
                                        onClick={()=>history.push('/student-evaluated-products')}
                                    />
                                    <Card
                                        isSimple
                                        value='04'
                                        image={PRO}
                                        loading={loading}
                                        onClick={()=>history.push('/product-evaluation')}
                                        title='PRODUCT EVALUATION FOR'
                                        title2={user.training_name || 'N/A'}
                                    />
                                </div>
                            :
                            <div className="row">

                                <Card
                                    value={approved || 0}
                                    image={DROPBOX}
                                    loading={loading}
                                    title={`Total Approved \n Products`}
                                    onClick={()=>history.push('/student-approved-product')}
                                />
                                <Card
                                    value={pending || 0}
                                    image={DROPBOX}
                                    loading={loading}
                                    title={`Pending for \n approval`}
                                    onClick={()=>history.push('/student-pending-products')}
                                />
                                <Card
                                    image={DROPBOX}
                                    loading={loading}
                                    value={archived || 0}
                                    title={`Archived Products`}
                                    onClick={()=>history.push('/saved-products')}
                                />
                                {
                                    loading || !canAddProduct ? null
                                    :
                                    <Card
                                        isSimple
                                        value='04'
                                        image={PRO}
                                        onClick={()=>{
                                            if(user.training_slug == 'become-a-seller-on-walmart-pl-ds-wholesale'){
                                                history.push('/add-walmart-product')
                                            }
                                            else if(user.training_slug == 'become-a-seller-on-amazon-gulf-ksa-uae-noon-marketplaces'){
                                                history.push('/add-gulf-product')
                                            }
                                            else{
                                                history.push('/add-product')
                                            }
                                        }}

                                        // onClick={user.training_id == 14 ? ()=>history.push('/add-walmart-product') : ()=>history.push('/add-product')}
                                        title='Submit Product for Approval'
                                        title2={user.training_name || ''}
                                    />
                                }
                            </div>
                        }
                    </div>
                </section>
            </div>

            <Modal show={isPopup} close={()=>setIsPopup(false)}>
                <InfoAlert
                    icon={<i className="fas fa-info-circle fa-fw fa-5x" style={{ color: "#ff7a01" }} ></i>}
                    description="We have moved your pending and old products to the archived tab due to missing data or those were saved as a draft. Please add the missing data and submit those again. You can also delete products that you don’t want to submit."
                />
            </Modal>

            <Memoized
                Component={Footer}
            />
        </div>
    )
}
