import Input from "components/Input"
import InputPicker from "components/Input/InputPicker"
import Memoized from "./Memoized"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Message from 'components/validationMessage/Message'
import countries from "constants/countries"

export default function AddProductBasics({
    onNext,
    name,
    setName,
    market,
    setMarket,
    image,
    setImage,
    formik,
}) {

    return (

        <div className="main-information-wrapper">

            <section className="dash-content-wrapper pro-details pt-5 pb-md-2">
                <div className="container">
                    <h2 className="mb-3 text-white text-uppercase">Product details</h2>
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-5">
                            <div className="form-row ">

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='name'>Product Name <span style={{color: "red"}}>*</span></label>
                                    <input
                                        id="name"
                                        value={name}
                                        type="text"
                                        maxLength={60}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Enter Product Name"
                                        onChange={e=>setName(e.target.value)}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <Message value={formik.errors.name} />
                                    ) : null}
                                </div>

                                <div className={`form-group col-md-6 mb-4`}>
                                    <label htmlFor='market'>Marketplace <span style={{color: "red"}}>*</span></label>
                                    <select value={market} className="form-control" onChange={e=>setMarket(e.target.value)}>
                                        <option value="">Select Marketplace</option>
                                        {
                                            countries.map((country, i) => (
                                                <option key={i} value={country.name}>{country.name}</option>
                                            ))
                                        }
                                    </select>
                                    {formik.touched.market && formik.errors.market ? (
                                        <Message value={formik.errors.market} />
                                    ) : null}
                                </div>

                                <InputPicker
                                    setValue={setImage}
                                    label="Product Image"
                                    id="inputGroupFile03"
                                    name={image ? image.name : 'Choose file'}
                                />
                            </div>
                            <div className="pt-5 text-center">
                                <button type="button" className="btn btn-blue btn-lg orange-btn text-uppercase mb-4 mb-md-0" onClick={onNext}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
