import Footer from 'components/footer'
import Header from '../../components/header'
import AddProductDetails from './AddProductDetails'


export default function ProductApproval() {

    return (
        <div>
            <Header />

            <AddProductDetails />
            
            <Footer />
        </div>
    )
}
