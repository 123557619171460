import MyModal from 'components/Modal';
import React, { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { deleteProductKeyword } from 'redux/actions/studentActions';
import { getKeywordImages } from 'utils/extractProducts';
import ImageView from './ImageView';
import './styles.css';

export default function ProductKeywordsTable({
    show,
    loading,
    productKeywords,
    setProductKeywords
}) {
    const dispatch = useDispatch()
    const [img, setImage] = useState('')
    const [isModal, setModal] = useState(false)
    const {user} = useSelector(({auth}) => auth)
    const {product} = useSelector(({studentRed}) => studentRed)

    const removeItem = (id) => {
        dispatch(deleteProductKeyword(id))
    }

    const updateKeyword = (id, data) => {
        let keywords = []

        keywords = productKeywords.map((keyword, i) => {
            if(keyword.id == id) {
                return {
                    ...keyword,
                    ...data
                }
            } else {
                return keyword
            }
        })

        setProductKeywords(keywords)
    }

    return (
        <div>
               <section className="dash-content-wrapper all-pro-evaulation">
                <div className="container">
                    <div className="card mb-5">
                        <div className="card-body p-3 p-md-4">
                            <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                <h2 className="mb-3 text-uppercase">keywords</h2>
                                {
                                    (loading && !show) ?
                                        <div className="text-center"> <ScaleLoader color="#ee6734" /></div>
                                    :
                                        productKeywords.length ?
                                            <table className="table " id="dataTable">
                                                <thead>
                                                    <tr>
                                                        {/* <th>KEYWORDS</th>
                                                        <th>JS EXACT SEARCH VOLUME</th>
                                                        <th>JS BROAD SEARCH VOLUME</th>
                                                        <th>HELIUM 10 SEARCH VOLUME</th>
                                                        <th>JS GIVEAWAY PER DAY</th>
                                                        <th>HELIUM 10 NO. OF GIVEAWAYS</th>
                                                        <th>PROFIT MARGIN</th>
                                                        <th>JS SCREENSHOT WITHOUT SORTING</th>
                                                        <th>JS SCREENSHOT WITH REVENUE SORTING</th>
                                                        <th>HELIUM 10 SCREENSHOT WITHOUT SORTING</th>
                                                        <th>HELIUM 10 SCREENSHOT WITH REVENUE SORTING</th>
                                                        <th>PAST 5 YEARS GOOGLE TRENDS</th>
                                                        <th>CEREBRO RELEVANT KEYWORDS SCREEN SHOT WITH SEARCH VOLUME SORTING</th>
                                                        <th>HELIUM10 MAIN KEYWORD SEARCH HISTORY SCREEN SHOTS</th> */}

                                                        <th className="keyword-td">KEYWORD</th>
                                                        <th className="keyword-td">Competing Products</th>
                                                        <th className="keyword-td">JS EXACT SV</th>
                                                        <th className="keyword-td">JS BROAD SEARCH VOLUME</th>
                                                        {/* <th className="keyword-td">Helium 10 Exact SV</th> */}
                                                        <th className="keyword-td">HELIUM 10 SEARCH VOLUME</th>
                                                        {/* <th className="keyword-td">JS GIVEAWAY PER DAY</th> */}
                                                        {/* <th className="keyword-td">HELIUM 10 NO. OF GIVEAWAYS</th> */}
                                                        <th className="keyword-td">Average Reviews of Top 20</th>
                                                        <th className="keyword-td">Average Selling Price of Top 20</th>
                                                        <th className="keyword-td">Profit Margin</th>
                                                        <th className="keyword-td">Available Budget</th>
                                                        <th className="keyword-td">Currecncy</th>

                                                        <th className="keyword-td">JS Screenshot of top 10</th>
                                                        <th className="keyword-td">JS Screenshot for 11th to 20th seller</th>
                                                        <th className="keyword-td">H10 Screenshot of top 10</th>
                                                        {/* <th>H10 Screenshot (Revenue Sorted)</th> */}
                                                        <th className="keyword-td">Lifetime BSR Screenshot top 3</th>
                                                        {/* <th className="keyword-td">CEREBRO RELEVANT KEYWORDS SCREEN SHOT WITH SEARCH VOLUME SORTING</th> */}
                                                        <th className="keyword-td">HELIUM 10 MAIN KEYWORD SEARCH HISTORY SCREEN SHOTS</th>
                                                        <th className="keyword-td">H10 Screenshot for 11th to 20th seller</th>
                                                        <th className="keyword-td">H10 Keyword Search History (1 Year)</th>
                                                        <th className="keyword-td">H10 Keyword Search History (All Time)</th>
                                                        {/* <th className="keyword-td">Average Reviews of Top 10</th> */}
                                                        <th className="keyword-td"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        productKeywords.map((key, i) => (
                                                            <tr key={i}>
                                                                <td className="keyword-td">{key.keyword || 'N/A'}</td>
                                                                <td className="keyword-td">{key.competing_products || 'N/A'}</td>
                                                                <td className="keyword-td">{key.js_exact_search_volume || 'N/A'}</td>
                                                                <td className="keyword-td">{key.js_broad_search_volume || 'N/A'}</td>
                                                                {/* <td className="keyword-td">{key.helium_10_exact_search || 'N/A'}</td> */}
                                                                <td className="keyword-td">{key.helium10_search_volume || 'N/A'}</td>
                                                                {/* <td className="keyword-td">{key.js_giveaways_per_day || 'N/A'}</td> */}
                                                                {/* <td className="keyword-td">{key.helium10_giveaways || 'N/A'}</td> */}
                                                                <td className="keyword-td">{key.average_reviews_of_top_10 || 'N/A'}</td>
                                                                <td className="keyword-td">{key.average_selling_price_of_top_10 || 'N/A'}</td>
                                                                <td className="keyword-td">{key.profit_margin || 'N/A'}</td>
                                                                <td className="keyword-td">{key.available_budget || 'N/A'}</td>
                                                                <td className="keyword-td">{key.currency || 'N/A'}</td>

                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={0}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='JS SCREENSHOT WITHOUT SORTING'
                                                                        objectKey='js_screenshot_without_sorting'
                                                                        src={key.js_screenshot_without_sorting}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={1}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='JS SCREENSHOT WITH REVENUE SORTING'
                                                                        objectKey='js_screenshot_with_revenue_sorting'
                                                                        src={key.js_screenshot_with_revenue_sorting}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={2}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='HELIUM 10 SCREENSHOT WITHOUT SORTING'
                                                                        objectKey='helium10_screenshot_without_sorting'
                                                                        src={key.helium10_screenshot_without_sorting}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={3}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='PAST 5 YEARS GOOGLE TRENDS'
                                                                        objectKey='google_trend_past_5_years'
                                                                        src={key.google_trend_past_5_years}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                {/* <td className="keyword-td">
                                                                    <ImageView
                                                                        index={4}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='CEREBRO RELEVANT KEYWORDS SCREEN SHOT WITH SEARCH VOLUME SORTING'
                                                                        objectKey='cerebro_relevant_keywords'
                                                                        src={key.cerebro_relevant_keywords}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td> */}
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={4}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='HELIUM10 MAIN KEYWORD SEARCH HISTORY SCREEN SHOTS'
                                                                        objectKey='helium10_main_keyword'
                                                                        src={key.helium10_main_keyword}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={5}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='Helium 10 Ext Screenshot with revenue sorting'
                                                                        objectKey='helium_10_ext_screenshot_with_revenue_sorting'
                                                                        src={key.helium_10_ext_screenshot_with_revenue_sorting}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={6}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='Helium 10 Magnet Search Volume History 1 Year'
                                                                        objectKey='helium_10_magnet_search_volume_history_1_year'
                                                                        src={key.helium_10_magnet_search_volume_history_1_year}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                <td className="keyword-td">
                                                                    <ImageView
                                                                        index={7}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='H10 Keyword Search History (All Time)'
                                                                        objectKey='helium_10_magnet_search_volume_history_all_time'
                                                                        src={key.helium_10_magnet_search_volume_history_all_time}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td>
                                                                {/* <td className="keyword-td">
                                                                    <ImageView
                                                                        index={9}
                                                                        id={key.id}
                                                                        setImage={setImage}
                                                                        setModal={setModal}
                                                                        updateHandler={updateKeyword}
                                                                        label='Average Reviews of Top 10'
                                                                        objectKey='average_reviews_of_top_10_ss'
                                                                        src={key.average_reviews_of_top_10_ss}
                                                                        srcs={getKeywordImages(key)}
                                                                    />
                                                                </td> */}

                                                                <td className="keyword-td">
                                                                    {
                                                                        show ?
                                                                            loading ?
                                                                                <button type="button" className="approve-btn">
                                                                                    <ScaleLoader color="#fff" height={20} />
                                                                                </button>
                                                                            :
                                                                                <button type="button" className="approve-btn" onClick={()=>removeItem(key.id)}>Remove</button>
                                                                        :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        :
                                            <h5 style={{ textAlign: 'center' }}>No Keywords Found.</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            <MyModal show={isModal} close={()=>setModal(false)}>
                <div className='image-view'>
                    <img src={img} style={{width: '100%', height: 'auto' }} />
                </div>
            </MyModal>
        </div>
    )
}
