import { useDispatch, useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import Memoized from 'components/Memoized'
import Input from 'components/Input'
import { toast } from 'react-toastify'
import { editStudentEVS, getStudentEVS } from 'redux/actions/editorActions'
import { useState, useEffect } from 'react'
import SelectPicker from 'components/Input/SelectPicker'

export default function EditEvs({ student, close }) {
    const dispatch = useDispatch()
    const { loading, moreLoading, evsDetails } = useSelector(({ editorRed }) => editorRed)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
        if(student?.id && student?.id !== '') {
            dispatch(getStudentEVS(student?.id))
        }
    }, [])

    useEffect(() => {
        if(evsDetails) {
            setName(student?.email)
            setEmail(evsDetails?.evs_password)
            setPhone(evsDetails?.status)
        }
    }, [evsDetails])

    const addHandler = () => {
        if(email === '') {
            toast.warn('Please Enter EVS Password.')
        } else if(phone === '') {
            toast.warn("Please Select EVS Status.")
        } else {
            if(student?.id) {
                dispatch(
                    editStudentEVS(
                        {
                            user_id: student?.id,
                            password: email,
                            status: phone
                        },
                        ()=>{
                            setEmail('')
                            setPhone('')
                            close()
                        }
                    )
                )
            } else {
                toast.error('No student found. Please try again.')
                close()
            }
        }
    }

    return (
        <div>

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper all-pro-evaulation pt-5 pb-md-4">
                    <div className="container">

                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                {
                                    moreLoading ?
                                        <ScaleLoader />
                                    :
                                        
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h2 className="text-center mb-4" >EVS Details</h2>
                                            
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <div className={`form-group col-md-12 mb-4`}>
                                                    <label>Email: {name}</label>
                                                </div>    

                                                <Memoized
                                                    required
                                                    fillWidth
                                                    Component={Input}
                                                    value={email}
                                                    setValue={setEmail}
                                                    label="Password"
                                                    placeholder="Enter Password"
                                                />
                                                <Memoized
                                                    required
                                                    fillWidth
                                                    Component={SelectPicker}
                                                    value={phone}
                                                    setValue={setPhone}
                                                    label='Status'
                                                    placeholder='Select Status'
                                                    options={[{id:'active',name:'Active'},{id:'expired',name:'Inactive'}]}
                                                />
                                            </div>    

                                            {
                                                loading ?
                                                    <button 
                                                        type="button" 
                                                        style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                        className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" 
                                                    >
                                                        <ScaleLoader color="#fff" height={15} />
                                                    </button>    
                                                :
                                                    <button 
                                                        type="button" 
                                                        onClick={addHandler}
                                                        style={{ maxWidth: 160, marginLeft: 'auto' }}
                                                        className="btn btn-blue btn-lg text-uppercase mb-4 mb-md-0 mr-0 mr-md-3" 
                                                    >
                                                        Save
                                                    </button>
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            
            </div>
        </div>
    )
}
