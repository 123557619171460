import { useFormik } from "formik";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { addProductForApproval, addProductKeyword, getProductsCount } from 'redux/actions/studentActions';
import { PLTrainigID, trainigID, VATrainingID, OneToOneTrainingID } from "constants/constants";
import ReactDOM from 'react-dom';

let isFirst = 0;

const useAddProduct = (id, close) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        loading,
        pending,
        approved,
    } = useSelector(({ studentRed }) => studentRed)
    const {user} = useSelector(({auth}) => auth)

    useEffect(() => {
        dispatch(getProductsCount())
    }, [])

    useEffect(()=>{
        if(user.training_id == trainigID) {
            history.push('/student-home')
        } else if(pending > 0) {
            toast.warn('You already have pending product in your queue')
            history.push('/student-home')
        } else if(user.training_id == PLTrainigID || user.training_id == VATrainingID || user.training_id == OneToOneTrainingID) {
            if(approved >= 2) {
                toast.warn('You have already 2 approved products in the system')
                history.push('/student-home')
            }
        } else if(approved >= 1) {
            toast.warn('You have already 1 approved product in the system')
            history.push('/student-home')
        }
    }, [pending, approved])

    const [productKeywords, setProductKeywords] = useState([])

    const [jsScreenWS, setJSScreenWS] = useState(null)
    const [jsScreenWRS, setJSScreenWRS] = useState(null)
    const [helium10SWS, setHelium10SWS] = useState(null)
    const [cereboRKSWSVS, setCereboRKSWSVS] = useState(null)
    const [helium10MKSH, setHelium10MKSH] = useState(null)
    const [past5YGT, setPast5YGT] = useState(null)
    const [profitMargin, setProfitMargin] = useState('')

    const [averageSellingPriceOfTop10, setAverageSellingPriceOfTop10] = useState('')
    const [helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting] = useState(null)
    const [helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year] = useState(null)
    const [helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime] = useState(null)
    const [averageReviewsOfTop10SS, setAverageReviewsOfTop10SS] = useState(null)
    const [availableBudget, setAvailableBudget] = useState('')
    const [currency, setCurrency] = useState('usd')

    const [h10MainKWSVScreenshot,                   setH10MainKWSVScreenshot] = useState(null)
    const [h10MainKWSVWITHAMZSVScreenshot,          setH10MainKWSVWITHAMZSVScreenshot] = useState(null)
    const [h10ExtensionCompleteScreenshot,          setH10ExtensionCompleteScreenshot] = useState(null)
    const [googlesheetSrcST10SellerAvgReviewsSales, setGooglesheetSrcST10SellerAvgReviewsSales] = useState(null)
    const [googleTrendScreenshot,                   setGoogleTrendScreenshot] = useState(null)

    const [comment, setComment] = useState('')
    const [isModal, setModal] = useState(false)
    const [isKeywordConfirmation, setKeywordConfirmation] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)

    const bottomViewRef = useRef(null);
    const topViewRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            market: '',
            keywords: '',
            jsExactSV: '',
            jsBroadSV: '',
            inputHelium10Exactsearch: '',
            helium10SV: '',
            jsGiveAPD: '',
            helium10NOGA: '',
            averageReviewsOfTop10: '',
            averageSellingPriceOfTop10: '',
            availableBudget: '',
            profitMargin: ''
        },
        validationSchema: Yup.object({
            keywords: Yup.string()
                .max(255, 'Must be 255 characters or less')
                .required('Product Keywrords are required'),
            jsExactSV: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('JS Exact Search Volume is required'),
            jsBroadSV: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('JS Broad Search Volume is required'),
            // inputHelium10Exactsearch: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('Helium 10 Exact Search Volume is required'),
            helium10SV: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Helium 10 Search Volume is required'),
            // jsGiveAPD: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('JS Giveaways per day is required'),
            // helium10NOGA: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('Helium10 no. of giveaways is required'),
            averageReviewsOfTop10: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Average Reviews of Top 10 is required'),
            averageSellingPriceOfTop10: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Average Selling Price of Top 10 is required'),
            availableBudget: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Available Budget is required'),
            profitMargin: Yup.string()
                .required('Profit Margin is required')
                .test('Must be 100% or less', 'Must be 100% or less', value =>
                    value <= 100
                )
        }),
        onSubmit: values => {
            addKeywordHandler(values)
        },
    });

    useEffect(() => {
        if(isFirst < 2) {
            isFirst++;
            setSubmitted(Math.random())
        }
    }, [Object.keys(formik.errors).length])

    useEffect(() => {
        let elem = ReactDOM.findDOMNode(document.getElementById(Object.keys(formik.errors)[0]));

        if(elem) {
            elem.focus()
        }
    }, [isSubmitted])

    const addToArray = ({
        keywords, jsExactSV, jsBroadSV, helium10SV, jsGiveAPD, helium10NOGA,
        inputHelium10Exactsearch, averageReviewsOfTop10, averageSellingPriceOfTop10,
        availableBudget, profitMargin
    }) => {
        if(keywords === '') {
            toast.warn('Please enter Product Keywords.')
        } else if(jsExactSV === '') {
            toast.warn('Please enter JS Exact Search Volume.')
        } else if(jsBroadSV === '') {
            toast.warn('Please enter JS Broad Search Volume.')
        } else if(helium10SV === '') {
            toast.warn('Please enter Helium 10 Search Volume.')
        }
        // else if(jsGiveAPD === '') {
        //     toast.warn('Please enter JS Giveaways per day.')
        // }
        // else if(helium10NOGA === '') {
        //     toast.warn('Please enter Helium10 no. of giveaways.')
        // }
        else if(!jsScreenWS) {
            toast.warn('Please select JS Screenshot without sorting.')
            ReactDOM.findDOMNode(document.getElementById('jsScreenWS')).focus()
        } else if(!jsScreenWRS) {
            toast.warn('Please select JS screenshot with revenue sorting.')
            ReactDOM.findDOMNode(document.getElementById('jsScreenWRS')).focus()
        } else if(!helium10SWS) {
            toast.warn('Please select Helium 10 Screenshot without sorting.')
            ReactDOM.findDOMNode(document.getElementById('helium10SWS')).focus()
        }
        // else if(inputHelium10Exactsearch === '') {
        //     toast.warn('Please enter Helium 10 Exact Search.')
        // }
        else if(averageReviewsOfTop10 === '') {
            toast.warn('Please enter Average Reviews Of Top 10.')
        } else if(averageSellingPriceOfTop10 === '') {
            toast.warn('Please enter Average Selling Price of Top 10')
        } else if(!helium10ExtScreenshotWithRevenueSorting) {
            toast.warn("Please select Helium 10 Ext Screen With Revenue Sorting")
            ReactDOM.findDOMNode(document.getElementById('helium10ExtScreenshotWithRevenueSorting')).focus()
        } else if(!helium10MagnetSearchVolumeHistory1Year) {
            toast.warn("Please select Helium 10 Magnet Search Volum History 1 Year")
            ReactDOM.findDOMNode(document.getElementById('helium10MagnetSearchVolumeHistory1Year')).focus()
        }
        // else if(!helium10MagnetSearchVolumeHistoryAllTime) {
        //     toast.warn("Please select Helium 10 Magnet Search Volum History All Time")
        // } else if(!averageReviewsOfTop10SS) {
        //     toast.warn("Please select Average Review Of Top 10")
        // } else if(!cereboRKSWSVS) {
        //     toast.warn('Please select Cerebo relevant keywords screenshot with search volume sorting.')
        // }
        else if(!helium10MKSH) {
            toast.warn('Please select Helium 10 main keyword search history screenshots.')
            ReactDOM.findDOMNode(document.getElementById('helium10MKSH')).focus()
        } else if(!past5YGT) {
            toast.warn('Please select Past 5 years google trends.')
            ReactDOM.findDOMNode(document.getElementById('past5YGT')).focus()
        }
        ////
        else if(!h10MainKWSVScreenshot) {
            toast.warn('Please select H10 Main KW SV Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('h10MainKWSVScreenshot')).focus()
        }
        else if(!h10MainKWSVWITHAMZSVScreenshot) {
            toast.warn('Please select H10 Main KW SV WITH AMZ SV Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('h10MainKWSVWITHAMZSVScreenshot')).focus()
        }
        else if(!h10ExtensionCompleteScreenshot) {
            toast.warn('Please select H10 Extension Complete Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('h10ExtensionCompleteScreenshot')).focus()
        }
        else if(!googlesheetSrcST10SellerAvgReviewsSales) {
            toast.warn('Please select Google sheet Screenshot that has Top 10 Seller Average Reviews, Sales.')
            ReactDOM.findDOMNode(document.getElementById('googlesheetSrcST10SellerAvgReviewsSales')).focus()
        }
        else if(!googleTrendScreenshot) {
            toast.warn('Google Trend Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('googleTrendScreenshot')).focus()
        }

        //////
         else if(availableBudget === '') {
            toast.warn('Please anter Available Budget.')
        } else if(profitMargin === '') {
            toast.warn('Please anter Profit Margin.')
        } else {
            let productKWs = [...productKeywords];

            let kwrd = {
                keyword: keywords,
                js_exact_search_volume: jsExactSV,
                js_broad_search_volume: jsBroadSV,
                // helium_10_exact_search: inputHelium10Exactsearch,
                helium10_search_volume: helium10SV,
                // js_giveaways_per_day: jsGiveAPD,
                // helium10_giveaways: helium10NOGA,
                average_reviews_of_top_10: averageReviewsOfTop10,
                average_selling_price_of_top_10: averageSellingPriceOfTop10,
                profit_margin: profitMargin,
                available_budget: availableBudget,
                currency,

                js_screenshot_without_sorting: jsScreenWS,
                js_screenshot_with_revenue_sorting: jsScreenWRS,
                helium10_screenshot_without_sorting: helium10SWS,
                google_trend_past_5_years: past5YGT,
                // cerebro_relevant_keywords: cereboRKSWSVS,
                helium10_main_keyword: helium10MKSH,
                helium_10_ext_screenshot_with_revenue_sorting: helium10ExtScreenshotWithRevenueSorting,
                helium_10_magnet_search_volume_history_1_year: helium10MagnetSearchVolumeHistory1Year,
                // helium_10_magnet_search_volume_history_all_time: helium10MagnetSearchVolumeHistoryAllTime,
                // average_reviews_of_top_10_ss: averageReviewsOfTop10SS,
                h10_main_kw_sv_screenshot: h10MainKWSVScreenshot,
                h10_main_kw_sv_with_amz_sv_screenshot: h10MainKWSVWITHAMZSVScreenshot,
                h10_extension_complete_screenshot: h10ExtensionCompleteScreenshot,
                google_sheet_screenshot_t10_seller_avg_rev_sales: googlesheetSrcST10SellerAvgReviewsSales,
                google_trend_screenshot : googleTrendScreenshot
    }

            productKWs.push(kwrd);
            setProductKeywords(productKWs);
            return true;
        }

        return false;
    }

    const onKeywordConfirmation = () => {
        setKeywordConfirmation(false)
        scrollTop()
    }

    const onKeywordCanelation = () => {
        setKeywordConfirmation(false)
        scrollDown()
    }

    const addKeywordHandler = (values) => {
        let added = addToArray(values);

        if(added) {
            setEmptyDetails()
            setKeywordConfirmation(true)
        }
    }

    const scrollTop = () => {
        setTimeout(() => {
            if(topViewRef.current) {
                topViewRef.current.scrollIntoView()
            }
        }, 500);
    }

    const scrollDown = () => {
        setTimeout(() => {
            if(bottomViewRef.current)
                bottomViewRef.current.scrollIntoView();
        }, 500);
    }

    const setEmptyDetails = () => {
        formik.resetForm();
        setJSScreenWS(null)
        setJSScreenWRS(null)
        setHelium10SWS(null)
        setCereboRKSWSVS(null)
        setHelium10MKSH(null)
        setPast5YGT(null)
        setProfitMargin('')
        setAverageSellingPriceOfTop10('')
        setHelium10ExtScreenshotWithRevenueSorting(null)
        setHelium10MagnetSearchVolumeHistory1Year(null)
        setHelium10MagnetSearchVolumeHistoryAllTime(null)
        setAverageReviewsOfTop10SS(null)
        setAvailableBudget('')
        setCurrency('usd')

        setH10MainKWSVScreenshot(null)
        setH10MainKWSVWITHAMZSVScreenshot(null)
        setH10ExtensionCompleteScreenshot(null)
        setGooglesheetSrcST10SellerAvgReviewsSales(null)
        setGoogleTrendScreenshot(null)
    }

    const doneHandler = () => {
        if(loading) return;

        if(productKeywords && productKeywords.length) {
            if(comment === '') {
                toast.warn('Please add Product Details.')
            } else {
                let formData = new FormData();

                formData.append('product_id', id)
                formData.append('message', comment)
                formData.append('user_id', user.id)
                formData.append('len', productKeywords.length)

                // productKeywords.map((pK, i) => {
                //     Object.keys(pK).map(key => {
                //         formData.append(`keywords.${i}.${key}`, pK[key])
                //     })
                // })
                productKeywords.map((pK, i) => {
                    Object.keys(pK).map(key => {
                        formData.append(`keywords[${i}][${key}]`, pK[key])
                    })
                })

                dispatch(addProductKeyword(formData, () => {

                    setEmptyDetails();
                    close();
                    history.push('/student-all-products')
                }))
            }
        } else {
            toast.warn('Please add at least one keyword.')
        }
    }

    return [
        formik,
        loading,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        comment, setComment,
        doneHandler,
        isModal, setModal,
        averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
        helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
        helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
        helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
        averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
        availableBudget, setAvailableBudget,

        h10MainKWSVScreenshot,                   setH10MainKWSVScreenshot,
        h10MainKWSVWITHAMZSVScreenshot,          setH10MainKWSVWITHAMZSVScreenshot,
        h10ExtensionCompleteScreenshot,          setH10ExtensionCompleteScreenshot,
        googlesheetSrcST10SellerAvgReviewsSales, setGooglesheetSrcST10SellerAvgReviewsSales,
        googleTrendScreenshot,                   setGoogleTrendScreenshot,

        currency, setCurrency,
        topViewRef,
        bottomViewRef,
        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ]
}

export default useAddProduct;
