import { useHistory } from 'react-router-dom'

import DROPBOX from 'assets/images/dropbox-icon.png'
import PENDING from 'assets/images/pending-for-icon.png'
import PRO from 'assets/images/pro-icon.png'

import Card from 'components/card'
import NavBar from 'components/navbar'
import Footer from 'components/footer'
import Memoized from 'components/Memoized'
import useHomeData from './useHomeData'

export default function TrainerHome() {
    const history = useHistory()
    const  [
        loading,
        pendingProductsCount,
        pendingStudentsCount,
    ] = useHomeData()

    return (
        <div>
            <Memoized
                Component={NavBar}
            />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper pt-5 pb-md-4">
                    <div className="container">
                        
                        {
                                <div className="row">

                                    <Card
                                        special
                                        image={PENDING}
                                        loading={loading}
                                        title={`Pending Products`}
                                        value={pendingProductsCount}
                                        onClick={()=>history.push('/trainer-pending-products')}
                                    />

                                    <Card
                                        special
                                        image={PENDING}
                                        loading={loading}
                                        title={`Pending Students`}
                                        value={pendingStudentsCount}
                                        onClick={()=>history.push('/trainer-pending-students')}
                                    />

                                    <Card
                                        value=''
                                        image={DROPBOX}
                                        onClick={()=>history.push('/trainer-all-products')}
                                        title='All Products'
                                    />

                                    <Card
                                        value=''
                                        image={DROPBOX}
                                        onClick={()=>history.push('/trainer-view-students')}
                                        title='View Students'
                                    />
                                    
                                    <Card
                                        value=''
                                        image={PRO}
                                        onClick={()=>history.push('/trainer-add-student')}
                                        title='Add Student'
                                    />

                                </div>
                        }
                    </div>
                </section>
            
            </div>
            
            <Memoized
                Component={Footer}
            />
        </div>
    )
}
