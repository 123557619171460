import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function BreadCrumbs() {
  const classes = useStyles();
  const history = useHistory();

  return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link style={{ color: "white", cursor: 'pointer' }} onClick={()=>history.push("/student-home")} className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    Home
                </Link>
                <Typography style={{ color: "white" }} className={classes.link}>
                    Approved Products
                </Typography>
            </Breadcrumbs>
      </div>
  );
}