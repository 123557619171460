import React from 'react'

export default function Footer() {
    return (
        <footer className="footer sticky-footer bg-off-white pt-0">
            <div className="container">
                <div className="footer-content">
                <div className="d-flex flex-column align-items-center">
                    <p className="mb-0 px-1 text-white">EPAS is operated by Enablers.org </p>
                </div>
                </div>
            </div>
        </footer>
    )
}
