import NavBar from 'components/navbar'
import Footer from 'components/footer'
import { productsGroupbyStatus } from 'utils/extractProducts'
import MaterialTable from 'components/materialTable'
import { getProductStatus } from 'utils/getProductStatus'
import Breadcrumbs from './components/BreadCrumbs'
import { truncate } from 'lodash'
import { useState, useRef } from 'react'
import EditPrivateLabelProduct from 'screens/editPrivateLabelProduct';
import MyModal from 'components/Modal'
import ProductFilterView from 'components/productFilterView'

const URL = `${process.env.REACT_APP_ENDPOINT}/api/products`
let cb = null;

export default function AllProducts() {
    const childRef = useRef();
    const [isModal, setModal] = useState(false)
    const [product, setProduct] = useState(null)

    const [filter, setFilter] = useState({
        trainer: '',
        batch: '',
        status: ''
    })

    const close = () => {
        cb && cb();
        setModal(false);
        setProduct(null);
    }
    
    const actionView = (product, refresh) => {
        return(
            <button 
                type="button" 
                className="btn-org text-uppercase"
                onClick={()=> {
                    cb = refresh;
                    setProduct(product)
                    setModal(true)
                }}
            >
                View
            </button>
        )
    }

    function productsToExcel() {
        
    }

    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
            
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <Breadcrumbs />

                        <div className="pt-3" />

                        <ProductFilterView
                            filter={filter}
                            setFilter={setFilter}
                            applyFilter={()=>childRef.current.refreshData()}
                        />

                        <h3 className="mb-0 text-uppercase text-white pb-4 col-sm-6">All Products</h3>
                        
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    <button onClick={ productsToExcel() }>Excel</button>
                                    <MaterialTable
                                        url={URL}
                                        ref={childRef}
                                        filter={filter}
                                        method={'post'}
                                        sortingIndex={4}
                                        sortingDirection='desc'
                                        columns={[
                                            // { id: 'name', label: 'Product Name', minWidth: 170, searchable: true, sortable: true, index: 0 },
                                            // { id: 'image', image: true, label: 'Product Image', width: 100 },
                                            { id: 'user_name', name: 'users.name', label: 'Student', minWidth: 150, searchable: true, sortable: true },
                                            { id: 'trainer_name', name: 'trainer.name', label: 'Trainer', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'batch_name', name: 'batches.name', label: 'Batch', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: truncate },
                                            // { id: 'history', custom: productsGroupbyStatus, label: 'History', minWidth: 100 },
                                            { id: 'submitted_date', date: true, label: 'Submitted Date', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'trainer_approval_date', date: true, label: 'Trainer Approval Date', minWidth: 100, searchable: true, sortable: true },
                                            { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />

            {
                isModal ?
                    <MyModal fullWidth show={isModal} close={()=>{ setModal(false); setProduct(null); }}>
                        <EditPrivateLabelProduct
                            close={close}
                            id={product.id}
                            show={getProductStatus(product.status)[2]}
                            allow={product.status === 'Approved by trainer' ? true : false}
                        />
                    </MyModal>
                :
                    null
            }
        </div>
    )
}