import { combineReducers } from 'redux';

import auth from './authReducer';
import utilsRed from './utilReducer';
import adminRed from './adminReducer';
import editorRed from './editorReducer';
import studentRed from './studentReducer';
import trainerRed from './trainerReducer';

const rootReducer = combineReducers({
    auth,
    adminRed,
    utilsRed,
    editorRed,
    studentRed,
    trainerRed,
});
  
export default rootReducer;