import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
    addProductKeyword,
    getProductKeywords,
    addProductComment,
} from 'redux/actions/studentActions';
import { checkKeywordImages } from "utils/extractProducts";

const useAddProduct = (id, show, allow) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { user,role } = useSelector(({ auth }) => auth)
    const { loading, keywords: myKeywords, comments: myComments, product } = useSelector(({ studentRed }) => studentRed)

    useEffect(() => {
        setImageAlert(false)
        if(id) {
           dispatch(getProductKeywords(id))
        }
    }, [])

    useEffect(() => {
        if(myKeywords) {
            setProductKeywords(myKeywords)
        }

        if(myComments) {
            setComments(myComments)
        }
    }, [myKeywords, myComments])

    const [productKeywords, setProductKeywords] = useState([])
    const [comments, setComments] = useState([])

    const [isImageAlert, setImageAlert] = useState(false)

    const [keywords, setKeywords] = useState('')
    const [jsExactSV, setJSExactSV] = useState('')
    const [jsBroadSV, setJSBroadSV] = useState('')
    const [helium10SV, setHelium10SV] = useState('')
    const [jsGiveAPD, setJSGiveAPD] = useState('')
    const [helium10NOGA, setHelium10NOGA] = useState('')
    const [jsScreenWS, setJSScreenWS] = useState(null)
    const [jsScreenWRS, setJSScreenWRS] = useState(null)
    const [helium10SWS, setHelium10SWS] = useState(null)
    const [helium10SWRS, setHelium10SWRS] = useState(null)
    const [cereboRKSWSVS, setCereboRKSWSVS] = useState(null)
    const [helium10MKSH, setHelium10MKSH] = useState(null)
    const [past5YGT, setPast5YGT] = useState(null)
    const [profitMargin, setProfitMargin] = useState('')

    const [comment, setComment] = useState('')


    useEffect(()=>{
        if(!product?.image) {
            setImageAlert(true)
        }

        if(myKeywords && myKeywords.length) {
            myKeywords.map(pKeyword => {
                checkKeywordImages(pKeyword,user,  setImageAlert)
            })
        }
    }, [product, myKeywords])

    const addToArray = () => {
        if(keywords === '') {
            toast.warn('Please enter Product Keywords.')
        } else if(jsExactSV === '') {
            toast.warn('Please enter JS Exact Search Volume.')
        } else if(jsBroadSV === '') {
            toast.warn('Please enter JS Broad Search Volume.')
        } else if(helium10SV === '') {
            toast.warn('Please enter Helium 10 Search Volume.')
        } else if(jsGiveAPD === '') {
            toast.warn('Please enter JS Giveaways per day.')
        } else if(helium10NOGA === '') {
            toast.warn('Please enter Helium10 no. of giveaways.')
        } else if(!jsScreenWS) {
            toast.warn('Please select JS Screenshot without sorting.')
        } else if(!jsScreenWRS) {
            toast.warn('Please select JS screenshot with revenue sorting.')
        } else if(!helium10SWS) {
            toast.warn('Please select Helium 10 Screenshot without sorting.')
        } else if(!helium10SWRS) {
            toast.warn('Please select Helium 10 Screenshot with revenue sorting.')
        } else if(!cereboRKSWSVS) {
            toast.warn('Please select Cerebo relevant keywords screenshot with search volume sorting.')
        } else if(!helium10MKSH) {
            toast.warn('Please select Helium 10 main keyword search history screenshots.')
        } else if(!past5YGT) {
            toast.warn('Please select Past 5 years google trends.')
        } else {
            let productKWs = [];

            let kwrd = {
                keyword: keywords,
                js_exact_search_volume: jsExactSV,
                js_broad_search_volume: jsBroadSV,
                helium10_search_volume: helium10SV,
                js_giveaways_per_day: jsGiveAPD,
                helium10_giveaways: helium10NOGA,
                profit_margin: profitMargin,
                js_screenshot_without_sorting: jsScreenWS,
                js_screenshot_with_revenue_sorting: jsScreenWRS,
                helium10_screenshot_without_sorting: helium10SWS,
                helium10_screenshot_with_revenue_sorting: helium10SWRS,
                google_trend_past_5_years: past5YGT,
                cerebro_relevant_keywords: cereboRKSWSVS,
                helium10_main_keyword: helium10MKSH
            }

            productKWs.push(kwrd);
            return productKWs;
        }

        return false;
    }

    const setEmptyDetails = () => {
        setKeywords('')
        setJSExactSV('')
        setJSBroadSV('')
        setHelium10SV('')
        setJSGiveAPD('')
        setHelium10NOGA('')
        setJSScreenWS(null)
        setJSScreenWRS(null)
        setHelium10SWS(null)
        setHelium10SWRS(null)
        setCereboRKSWSVS(null)
        setHelium10MKSH(null)
        setPast5YGT(null)
        setProfitMargin('')
    }

    const addKeywordHandler = (cb) => {
        let formData = new FormData();

        let productKWs = addToArray();

        if(productKWs) {
            if(id) {
                let p = productKWs ? [...productKWs] : [];
                p = p.slice(p.length-1);

                formData.append('product_id', id)
                p.map((pK) => {
                    Object.keys(pK).map(key => {
                        formData.append(key, pK[key])
                    })
                })

                dispatch(addProductKeyword(formData, () => {
                    setEmptyDetails();
                    cb && cb();
                }))
            } else {
                toast.error('Something went wrong. Please refresh.')
            }
        }
    }

    const addComment = () => {
        if(comment === "") {
            toast.warn('Please first add Product Details.')
        } else {
            if(id) {
                dispatch(addProductComment(
                    {
                        id,
                        message: comment
                    },
                    ()=>setComment('')
                ))
            } else {
                toast.error('Something went wrong. Please refresh.')
            }
        }
    }

    return [
        loading,

        keywords, setKeywords,
        jsExactSV, setJSExactSV,
        jsBroadSV, setJSBroadSV,
        helium10SV, setHelium10SV,
        jsGiveAPD, setJSGiveAPD,
        helium10NOGA, setHelium10NOGA,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        helium10SWRS, setHelium10SWRS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        addKeywordHandler,
        comment, setComment,
        addComment,
        show,
        allow,
        comments, setComments,
        role,
        product,
        isImageAlert
    ]
}

export default useAddProduct;
