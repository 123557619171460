import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import ReactDOM from 'react-dom';
import { addProductForApproval, getProductsCount } from 'redux/actions/studentActions';
import { PLTrainigID, trainigID, VATrainingID, OneToOneTrainingID } from "constants/constants";

let isFirst = 0;

const useAddProduct = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        loading,
        pending,
        approved,
    } = useSelector(({ studentRed }) => studentRed)
    const {user} = useSelector(({auth}) => auth)

    useEffect(() => {
        dispatch(getProductsCount())
        setSubmitted(Math.random())
    }, [])

    useEffect(()=>{
        if(user.training_id == trainigID) {
            history.push('/student-home')
        } else if(pending > 0) {
            toast.warn('You already have pending product in your queue')
            history.push('/student-home')
        } else if(user.training_id == PLTrainigID || user.training_id == VATrainingID || user.training_id == OneToOneTrainingID) {
            if(approved >= 2) {
                toast.warn('You have already 2 approved products in the system')
                history.push('/student-home')
            }
        } else if(approved >= 1) {
            toast.warn('You have already 1 approved product in the system')
            history.push('/student-home')
        }
    }, [pending, approved])

    const [productKeywords, setProductKeywords] = useState([])

    const [index, setIndex] = useState(0)
    const [name, setName] = useState('')
    const [market, setMarket] = useState('')
    const [image, setImage] = useState(null)

    const [keywords, setKeywords] = useState('')
    const [jsExactSV, setJSExactSV] = useState('')
    const [jsBroadSV, setJSBroadSV] = useState('')
    const [helium10SV, setHelium10SV] = useState('')
    const [jsGiveAPD, setJSGiveAPD] = useState('')
    const [helium10NOGA, setHelium10NOGA] = useState('')
    const [jsScreenWS, setJSScreenWS] = useState(null)
    const [jsScreenWRS, setJSScreenWRS] = useState(null)
    const [helium10SWS, setHelium10SWS] = useState(null)
    const [helium10SWRS, setHelium10SWRS] = useState(null)
    const [cereboRKSWSVS, setCereboRKSWSVS] = useState(null)
    const [helium10MKSH, setHelium10MKSH] = useState(null)
    const [past5YGT, setPast5YGT] = useState(null)
    const [profitMargin, setProfitMargin] = useState('')

    const [inputHelium10Exactsearch, setInputHelium10Exactsearch] = useState('')
    const [averageReviewsOfTop10, setAverageReviewsOfTop10] = useState('')
    const [averageSellingPriceOfTop10, setAverageSellingPriceOfTop10] = useState('')
    const [helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting] = useState(null)
    const [helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year] = useState(null)
    const [helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime] = useState(null)
    const [averageReviewsOfTop10SS, setAverageReviewsOfTop10SS] = useState(null)
    const [availableBudget, setAvailableBudget] = useState('')
    const [currency, setCurrency] = useState('usd')

    const [helium10MainKWSVScreenshot,setHelium10MainKWSVScreenshot] = useState(null)
    const [helium10MainKWSVWITHAMZSVScreenshot,setHelium10MainKWSVWITHAMZSVScreenshot] = useState(null)
    const [helium10ExtensionCompleteScreenshot,setHelium10ExtensionCompleteScreenshot] = useState(null)
    const [averageSalesSheet,setAverageSalesSheet] = useState(null)
    const [googleTrendScreenshot,setGoogleTrendScreenshot] = useState(null)

    const [comment, setComment] = useState('')
    const [prohibited, setProhibited] = useState(false)
    const [isModal, setModal] = useState(false)
    const [isKeywordConfirmation, setKeywordConfirmation] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)

    const formik = useFormik({
        initialValues: {
            // name: '',
            // market: '',
            keywords: '',
            jsExactSV: '',
            jsBroadSV: '',
            // inputHelium10Exactsearch: '',
            helium10SV: '',
            competing_products: '',
            // jsGiveAPD: '',
            // helium10NOGA: '',
            averageReviewsOfTop10: '',
            averageSellingPriceOfTop10: '',
            availableBudget: '',
            profitMargin: ''
        },
        validationSchema: Yup.object({
            availableBudget: Yup.string()
                .max(15, 'Must be 15 characters or less')
               

        }),
        onSubmit: values => {
            addKeywordHandler(values)
        },
    });

    window.onbeforeunload = function () {return false}

    useEffect(() => {
        if(isFirst < 2) {
            isFirst++;
            setSubmitted(Math.random())
        }
    }, [Object.keys(formik.errors).length])

    useEffect(() => {
        let elem = ReactDOM.findDOMNode(document.getElementById(Object.keys(formik.errors)[0]));

        if(elem) {
            elem.focus()
        }
    }, [isSubmitted])

    const nextHandler = () => {
        if(name === '') {
            toast.warn('Please enter Product Name.')
        } else if(market === '') {
            toast.warn('Please enter Product Market.')
        } else if(!image) {
            toast.warn('Please select Product Image.')
        } else {
            setIndex(1)
            setProhibited(true)
        }
    }

    const onKeywordConfirmation = () => {
        setKeywordConfirmation(false)
        setTimeout(() => {
            window.scrollTo(0, 300)
        }, 500);
    }

    const onKeywordCanelation = () => {
        setKeywordConfirmation(false)
        setTimeout(() => {
            window.scrollTo(0, 2500)
        }, 500);
    }

    const addToArray = ({
        availableBudget
    }) => {
        if(!helium10MainKWSVScreenshot) {
            toast.warn('Please select H10 Main KW SV Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('helium10MainKWSVScreenshot')).focus()
        }
        else if(!helium10MainKWSVWITHAMZSVScreenshot) {
            toast.warn('Please select  H10 Main KW SV WITH AMZ SV Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('helium10MainKWSVWITHAMZSVScreenshot')).focus()
        }
        else if(!helium10ExtensionCompleteScreenshot) {
            toast.warn('Please select H10 Extension Complete Screenshot.')
            ReactDOM.findDOMNode(document.getElementById('helium10ExtensionCompleteScreenshot')).focus()
        }

        else if(!averageSalesSheet) {
            toast.warn('Please select Google sheet Screenshot that has Top 10 Seller Average Reviews, Sales,Revenue of Data Spark')
            ReactDOM.findDOMNode(document.getElementById('averageSalesSheet')).focus()
        }
        else if(!googleTrendScreenshot) {
            toast.warn('Please select Google Trend Screenshot')
            ReactDOM.findDOMNode(document.getElementById('googleTrendScreenshot')).focus()
        } else if(availableBudget === '') {
            toast.warn('Please Enter  Budget.')
        }
        else {
            let productKWs = [...productKeywords];

            let kwrd = {
                available_budget: availableBudget,
                currency,
                wm_helium10_min_kw_sv_screenshot:helium10MainKWSVScreenshot   ,
                wm_helium10_main_kv_sv_with_amz_sv_screenshot:helium10MainKWSVWITHAMZSVScreenshot,
                wm_helium10_extension_complete_screenshot:helium10ExtensionCompleteScreenshot,
                wm_average_sales_sheet:averageSalesSheet,
                wm_google_trend_screenshot:googleTrendScreenshot,

            }

            productKWs.push(kwrd);
            setProductKeywords(productKWs);
            return true;
        }

        return false;
    }

    const addKeywordHandler = (values) => {
        let added = addToArray(values);
        if(added) {
            setEmptyDetails()
            setKeywordConfirmation(true)
        }
    }

    const setEmptyBasics = () => {
        setName('')
        setMarket('')
        setImage(null)
    }

    const setEmptyDetails = () => {
        formik.resetForm();
        setKeywords('')
        setJSExactSV('')
        setJSBroadSV('')
        setHelium10SV('')
        setJSGiveAPD('')
        setHelium10NOGA('')
        setJSScreenWS(null)
        setJSScreenWRS(null)
        setHelium10SWS(null)
        setHelium10SWRS(null)
        setCereboRKSWSVS(null)
        setHelium10MKSH(null)
        setPast5YGT(null)
        setProfitMargin('')
        setInputHelium10Exactsearch('')
        setAverageReviewsOfTop10('')
        setAverageSellingPriceOfTop10('')
        setHelium10ExtScreenshotWithRevenueSorting(null)
        setHelium10MagnetSearchVolumeHistory1Year(null)
        setHelium10MagnetSearchVolumeHistoryAllTime(null)
        setAverageReviewsOfTop10SS(null)


        setHelium10MainKWSVScreenshot(null)
        setHelium10MainKWSVWITHAMZSVScreenshot(null)
        setHelium10ExtensionCompleteScreenshot(null)
        setAverageSalesSheet(null)
        setGoogleTrendScreenshot(null)

        setAvailableBudget('')
        setCurrency('usd')
    }

    const doneHandler = () => {
        if(loading) return;

        if(productKeywords && productKeywords.length) {
            if(comment === '') {
                toast.warn('Please add Product Details.')
            } else {
                let formData = new FormData();

                formData.append('name', name)
                formData.append('image', image)
                formData.append('market', market)
                formData.append('message', comment)
                formData.append('len', productKeywords.length)

                // productKeywords.map((pK, i) => {
                //     Object.keys(pK).map(key => {
                //         formData.append(`keywords${i}.${key}`, pK[key])
                //     })
                // })

                productKeywords.map((pK, i) => {
                    Object.keys(pK).map(key => {
                        formData.append(`keywords[${i}][${key}]`, pK[key])
                    })
                })

                setProhibited(false)
                dispatch(addProductForApproval(formData, () => {
                    setEmptyBasics();
                    setEmptyDetails();
                    history.push('/student-all-products')
                }))
            }
        } else {
            toast.warn('Please add at least one keyword.')
        }
    }

    return [
        formik,
        loading,
        index,
        setIndex,
        name,
        setName,
        market,
        setMarket,
        image,
        setImage,
        nextHandler,

        keywords, setKeywords,
        jsExactSV, setJSExactSV,
        jsBroadSV, setJSBroadSV,
        helium10SV, setHelium10SV,
        jsGiveAPD, setJSGiveAPD,
        helium10NOGA, setHelium10NOGA,
        jsScreenWS, setJSScreenWS,
        jsScreenWRS, setJSScreenWRS,
        helium10SWS, setHelium10SWS,
        helium10SWRS, setHelium10SWRS,
        cereboRKSWSVS, setCereboRKSWSVS,
        helium10MKSH, setHelium10MKSH,
        past5YGT, setPast5YGT,
        profitMargin, setProfitMargin,
        productKeywords, setProductKeywords,
        addKeywordHandler,
        doneHandler,
        comment, setComment,
        doneHandler,
        prohibited, setProhibited.products,
        isModal, setModal,

        inputHelium10Exactsearch, setInputHelium10Exactsearch,
        averageReviewsOfTop10, setAverageReviewsOfTop10,
        averageSellingPriceOfTop10, setAverageSellingPriceOfTop10,
        helium10ExtScreenshotWithRevenueSorting, setHelium10ExtScreenshotWithRevenueSorting,
        helium10MagnetSearchVolumeHistory1Year, setHelium10MagnetSearchVolumeHistory1Year,
        helium10MagnetSearchVolumeHistoryAllTime, setHelium10MagnetSearchVolumeHistoryAllTime,
        averageReviewsOfTop10SS, setAverageReviewsOfTop10SS,
        availableBudget, setAvailableBudget,
        currency, setCurrency,
        helium10MainKWSVScreenshot,setHelium10MainKWSVScreenshot,
        helium10MainKWSVWITHAMZSVScreenshot,setHelium10MainKWSVWITHAMZSVScreenshot,
        helium10ExtensionCompleteScreenshot,setHelium10ExtensionCompleteScreenshot,
        averageSalesSheet,setAverageSalesSheet,
        googleTrendScreenshot,setGoogleTrendScreenshot,
        isKeywordConfirmation,
        onKeywordConfirmation,
        onKeywordCanelation,
        setSubmitted,
    ]
}

export default useAddProduct;
