import React from 'react'
import { ScaleLoader } from 'react-spinners'

export default function Card({ special, isSimple, title, value, image, title2, onClick, loading }) {
    return (
        <div className="col-xl-6 col-md-6 mb-4" style={{ cursor: 'pointer' }} onClick={onClick ? onClick : ()=>{}}>
            <div className={`card ${(isSimple||special) ? 'card-pro' : 'card-total'} h-100 p-2`}>
                <div className="card-body">
                    <div className="row no-gutters align-items-center h-100">
                        <div className={`col-12 d-flex align-items-center ${(isSimple||special) ? '' : 'h-100'}`}>
                            {
                                special ?
                                    <div className="card-icon mr-3 d-none d-md-block" style={{ borderRadius: 5, padding: '12px 10px', backgroundColor: "#FF7D02" }}>
                                        <img src={image} />
                                    </div>
                                :
                                    <div className="card-icon mr-3 d-none d-md-block">
                                        <img src={image} />
                                    </div>    
                            }
                            <div className="card-content d-flex align-items-center flex-row justify-content-between w-100">
                                <h4 className={`text-uppercase mb-0 ${(isSimple || special) ? '' : 'text-white'}`}>
                                    {
                                        (isSimple || special) ?
                                                <>
                                                    <p className="mb-0">{title}</p>
                                                    {title2}
                                                </>
                                            :
                                            title
                                    }
                                </h4>
                                {
                                    loading ?
                                        <ScaleLoader color={(isSimple || special) ? "#324056" : "#fff"} />
                                    :
                                        isSimple ? 
                                            null
                                        :    
                                            <h2 className={` text-righttext-uppercase mb-0 counting ${special ? '' : 'text-white'}`}>{value}</h2>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
