import { useState } from "react";
import { toast } from "react-toastify";
import { validateEmail } from "utils/validation";

const useProductApproval = () => {
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [cnic, setCNIC] = useState('')
    const [trainer, setTrainer] = useState('')
    const [training, setTraining] = useState('')
    const [batch, setBatch] = useState('')
    const [address, setAddress] = useState('')
    const [cnicFront, setCNICFront] = useState(null)
    const [cnicBack, setCNICBack] = useState(null)

    const addProductHandler = (e) => {
        e.preventDefault();

        if(fullName === '') {
            toast.warn('Please enter Full Name.')
        } else if(email === '' || !validateEmail(email)) {
            toast.warn('Please enter valid Email.')
        } else if(phoneNo === '') {
            toast.warn('Please enter Phone Number.')
        } else if(cnic === '') {
            toast.warn('Please enter CNIC Number.')
        } else if(trainer === '') {
            toast.warn('Please select your Trainer .')
        } else if(training === '') {
            toast.warn('Please select your Training.')
        } else if(batch === '') {
            toast.warn('Please select your Batch.')
        } else if(address === '') {
            toast.warn('Please enter your Address.')
        } else if(!cnicFront) {
            toast.warn('Please select CNIC Front Image.')
        } else if(!cnicBack) {
            toast.warn('Please select CNIC Back Image.')
        } else {
            let formData = new FormData();

            formData.append('name', fullName);
            formData.append('email', email);
            formData.append('phone', phoneNo);
            formData.append('cnic', cnic);
            formData.append('trainer', trainer);
            formData.append('training', training);
            formData.append('batch', batch);
            formData.append('address', address);
            formData.append('cnicFront', cnicFront);
            formData.append('cnicBack', cnicBack);

        }
    }

    return [
        fullName,
        setFullName,
        email,
        setEmail,
        phoneNo,
        setPhoneNo,
        cnic,
        setCNIC,
        trainer,
        setTrainer,
        training,
        setTraining,
        batch,
        setBatch,
        address,
        setAddress,
        cnicFront,
        setCNICFront,
        cnicBack,
        setCNICBack,
        addProductHandler
    ];
}

export default useProductApproval;