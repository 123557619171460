import React from 'react'
import { toast } from 'react-toastify'
import { getShortName } from 'utils/strings'

export default function InputPicker({ key, setValue, setTouched, id, label, name, fillWidth }) {
    return (
        <div className={`input-group ${fillWidth ? 'col-md-12' : 'col-md-6'}`} style={{ borderRadius: 5 }}>
            <label htmlFor={id} style={{ width: "100%" }}>{label} <span style={{color: "red"}}>*</span></label>

            <div className="custom-file">
                <input
                    id={id}
                    required
                    type="file"
                    autoComplete="off"
                    key={Math.random()}
                    accept="image/x-png,image/jpg,image/jpeg"
                    className="custom-file-input form-control"
                    onChange={e=>{
                        if(e.target.files[0] && e.target.files[0].size > 1000000) {
                            toast.warn('Image size must be less than 1 MB.')
                        } else {
                            if(setTouched) { setTouched(true) }
                            setValue(e.target.files[0]);
                        }
                    }}
                />
                <label className="custom-file-label " htmlFor={id}>{getShortName(name, 20)}</label>
            </div>
        </div>
    )
}
