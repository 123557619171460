import NavBar from 'components/navbar'
import Footer from 'components/footer'
import MaterialTable from 'components/materialTable'
import { useDispatch, useSelector } from 'react-redux'
import { updateStatus, deleteUser } from "redux/actions/adminActions"
import { ScaleLoader } from 'react-spinners'
import DropMenu from "components/dropMenu";
import MENU_IMG from 'assets/images/menu.png';
import { useHistory } from 'react-router'
import BreadCrumbs from './BreadCrumbs'
import MyModal from 'components/Modal'
import StudentDetails from 'components/StudentDetails'
import { useState, useRef } from 'react'
import DeactivationReason from 'components/deactivationReason'
import { toast } from 'react-toastify'
import ConfirmationAlert from 'components/confimationAlert'
import StudentFilterView from 'components/studentFilterView'

let data = null;

export default function ViewStudents() {
    const childRef = useRef();
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false)
    const [student, setStudent] = useState(null)
    const {loading}=useSelector(({adminRed})=>adminRed)
    const [isReasonModal, setReasonModal] = useState(false)
    const [isConfirmModal, setConfirmModal] = useState(false)
    const [reason, setReason] = useState('')

    const [filter, setFilter] = useState({
        trainer: '',
        training: '',
        batch: '',
        status: ''
    })

    const updateHandler = (id, refresh, status) => {
        dispatch(updateStatus({
            user_id: id,
            role: 'student',
            status
        }))
        refresh()
    }

    const viewDetails = (student) => {
        setStudent(student);
        setModal(true);
    }

    const showReasonModal = (id, refresh, status) => {
        data = {
            id,
            refresh,
            status
        }

        setReasonModal(true)
    }

    const deactivateHandler = () => {
        if(reason === '') {
            toast.warn('Please specify reason.')
        } else {
            if(data && data.id && data.status && data.refresh) {
                dispatch(
                    updateStatus(
                        {
                            user_id: data.id,
                            role: 'student',
                            status: data.status,
                            deactivation_reason: reason
                        },
                        () => {
                            setReasonModal(false)
                            setReason('')
                            data.refresh()
                        }
                    )
                )
            } else {
                toast.warn("Please select a student to deactivate.")
            }
        }
    }

    const deleteHandler = () => {
        if(reason === '') {
            toast.warn('Please specify reason.')
        } else {
            dispatch(
                deleteUser(
                    {
                        id: data?.student?.id || '',
                        comments: reason
                    },
                    () => {
                        data?.refresh()
                        setConfirmModal(false)
                        setReason('')
                    }
                )
            )
        }
    }

    const actionView = (student, refresh) => {
        return(
            <DropMenu
                button={<img src={MENU_IMG} className="cursor" />}
                options={
                    student.status === 'active' ?
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Deactivate', onClick: ()=>showReasonModal(student.id, refresh, 'expired') },
                            { title: 'Delete', onClick: ()=>{data={student, refresh}; setConfirmModal(true)}},
                        ]
                    : student.status === 'expired' ?
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Activate', onClick: ()=>updateHandler(student.id, refresh, 'active') },
                            { title: 'Delete', onClick: ()=>{data={student, refresh}; setConfirmModal(true)}},
                        ]
                    :
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Activate', onClick: ()=>updateHandler(student.id, refresh, 'active') },
                            { title: 'Reject', onClick: ()=>updateHandler(student.id, refresh, 'reject') },
                            { title: 'Delete', onClick: ()=>{data={student, refresh}; setConfirmModal(true)}},
                        ]
                }
            />
        )
    }


    return (
        <div>
            <NavBar />

            <div className="main-information-wrapper">
                <section className="dash-content-wrapper all-pro pt-5 pb-md-4">
                    <div className="container">
                        <BreadCrumbs />

                        <StudentFilterView
                            filter={filter}
                            setFilter={setFilter}
                            applyFilter={()=>childRef.current.refreshData()}
                        />

                        <h3 className="mb-0 text-uppercase text-white pb-4">All Students</h3>
                        <div className="card mb-5">
                            <div className="card-body p-3 p-md-4">
                                <div id="all-pro-evaulation-wrapper" className="table-responsive">
                                    {
                                        // loading ?
                                        //     <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        //         <ScaleLoader color="#4e73df" />
                                        //     </div>
                                        // :
                                            <MaterialTable
                                                method='post'
                                                ref={childRef}
                                                filter={filter}
                                                loading={loading}
                                                placeholder="Search by Name, Email"
                                                url={process.env.REACT_APP_ENDPOINT+'/api/user/Student'}
                                                columns={[
                                                    { id: 'name', label: 'Name', maxWidth: 100, searchable: true, sortable: true, index: 0 },
                                                    { id: 'email', label: 'Email', minWidth: 150, searchable: true, sortable: true, index: 1 },
                                                    // { id: 'phone', label: 'Phone', maxWidth: 150, minWidth: 150, searchable: true, sortable: true, index: 2 },
                                                    // { id: 'cnic', label: 'CNIC', maxWidth: 150, minWidth: 150, searchable: true, sortable: true, index: 3 },
                                                    // { id: 'address', label: 'Address', maxWidth: 150, minWidth: 150, searchable: true, sortable: true, index: 4 },
                                                    // { id: 'cnic_front_image', image: true, label: 'CNIC Front', width: 100 },
                                                    // { id: 'cnic_back_image', image: true, label: 'CNIC Back', width: 100 },
                                                    { id: 'trainer_name', name: 'trainer.name', label: 'Trainer', minWidth: 100, searchable: true, sortable: true, index: 5 },
                                                    { id: 'batch_name', name: 'batches.name', label: 'Batch', minWidth: 100, searchable: true, sortable: true, index: 6 },
                                                    { id: 'training_name', name: 'trainings.name', label: 'Training', minWidth: 100, searchable: true, sortable: true, index: 7 },
                                                    // { id: 'password_s_t_r', label: 'Password', maxWidth: 100, minWidth: 100 },
                                                    { id: 'status', label: 'Status', minWidth: 100, searchable: true, sortable: true, index: 8 },
                                                    { id: 'action', label: 'Action', minWidth: 100, action: actionView },
                                                ]}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />


            <MyModal show={isModal} close={()=>{ setModal(false); setStudent(null); }}>
                <StudentDetails student={student} />
            </MyModal>

            <MyModal show={isReasonModal} close={()=>{ setReasonModal(false); setReason(''); data=null; }}>
                <DeactivationReason
                    reason={reason}
                    loading={loading}
                    setReason={setReason}
                    submitHandler={deactivateHandler}
                />
            </MyModal>

            <MyModal show={isConfirmModal} close={()=>{ setConfirmModal(false); setReason(''); data=null; }}>
                {/* <ConfirmationAlert
                    title="Are you sure you want to Delete this student!"
                    description={`Deleting a user will permanently delete all data of user. \nYou would not be able to revert it.`}
                    onConfirm={deleteHandler}
                    onCancel={()=>setConfirmModal(false)}
                    loading={loading}
                /> */}
                <DeactivationReason
                    reason={reason}
                    loading={loading}
                    setReason={setReason}
                    submitHandler={deleteHandler}
                />
            </MyModal>
        </div>
    )
}
