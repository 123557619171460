import React from 'react'

export default function LogDetails({ activity }) {

    return (
        <div className="card o-hidden border-0 shadow-lg">
            <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-1">Activity Log</h1>
                                <hr />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h6><strong>Performed By:&nbsp;&nbsp;</strong></h6>
                                    <h6 className="h6 text-gray-900 mb-2">{activity?.name || 'N/A'}</h6>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h6><strong>Date:&nbsp;&nbsp;</strong></h6>
                                    <h6 className="h6 text-gray-900 mb-2">{activity?.updated_at || 'N/A'}</h6>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h6><strong>Action:&nbsp;&nbsp;</strong></h6>
                                    <h6 className="h6 text-gray-900 mb-2">{activity?.event || 'N/A'}</h6>
                                </div>
                            </div>

                            <hr />
                            
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h6><strong>Comments:&nbsp;&nbsp;</strong></h6>
                                <h6 className="h6 text-gray-900 mb-2">{activity?.comments || 'N/A'}</h6>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <h6><strong>Description:&nbsp;&nbsp;</strong></h6>
                                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-end' }}>
                                    {
                                        (activity && activity.description) ? 
                                            activity.description.split(',').map(str => (
                                                <>
                                                    <h6 className="h6 text-gray-900 mb-2">{str.trim()}</h6>
                                                </>
                                            )) 
                                        : 
                                            'N/A'
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
