import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingProductsCount } from "redux/actions/adminActions";

export default function useHomeData() {
    const dispatch = useDispatch()

    const {
        loading,
        totalPending: total
    } = useSelector(({ adminRed }) => adminRed)

    useEffect(() => {
        // if(total === 0)
            dispatch(getPendingProductsCount())
    }, [])

    return [
        loading,
        total
    ];
}