import { useState, useRef } from "react";
import { updateStatus, changeAllPendingStatus } from "redux/actions/trainerActions";
import { useDispatch, useSelector } from "react-redux";
import MENU_IMG from 'assets/images/menu.png';
import DropMenu from "components/dropMenu";
import { toast } from "react-toastify";

const useAllStudents = () => {
    const childRef = useRef();
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false)
    const [student, setStudent] = useState(null)
    const [selected, setSelected] = useState([])

    const [filter, setFilter] = useState({
        trainer: '',
        training: '',
        batch: '',
        status: ''
    })

    const {loading}=useSelector(({trainerRed})=>trainerRed)

    const pushOne = (id) => {
        let stds = [...selected]
        stds.push(id)
        setSelected(stds)
    }

    const popOne = (id) => {
        let stds = [...selected]
        stds = stds.filter(s => s != id)
        setSelected(stds)
    }

    const popAll = () => {
        setSelected([])
    }

    const selectOne = (id) => {
        if(selected.length) {
            if(selected.includes(id)) {
                popOne(id)
            } else {
                pushOne(id)
            }
        } else {
            pushOne(id)
        }
    }

    const selectAll = (checked, students) => {
        if(!checked) {
            setSelected([])
        } else {
            let stds = students ? students.map(s => s.id) : [];
            stds = [...stds]

            setSelected(stds)
        }
    }

    const changeStatus = () => {
        if(selected.length) {
            dispatch(changeAllPendingStatus(selected, 'active', ()=>{
                childRef.current.refreshData()
                popAll()
            }))
        } else {
            toast.warn('Please select at least one pending student.')
        }
    }

    const updateHandler = (id, refresh, status) => {
        dispatch(updateStatus({
            user_id: id,
            role: 'student',
            status
        }))
        refresh()
    }

    const viewDetails = (student) => {
        // history.push('/student-details', {...student, isPending: true, isTrainer: true})
        setStudent(student);
        setModal(true);
    }

    const actionView = (student, refresh) => {
        return(
            <DropMenu
                button={<img src={MENU_IMG} className="cursor" />}
                options={
                    student.status === 'active' ?
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Deactivate', onClick: ()=>updateHandler(student.id, refresh, 'expired') },
                        ]
                    :
                        [
                            { title: 'View Details', onClick: ()=>viewDetails(student) },
                            { title: 'Activate', onClick: ()=>updateHandler(student.id, refresh, 'active') },
                            { title: 'Reject', onClick: ()=>updateHandler(student.id, refresh, 'reject') },
                        ]
                }
            />
        )
    }

    return [
        loading,
        selected,
        selectOne,
        selectAll,
        changeStatus,
        actionView,
        isModal,
        setModal,
        student,
        setStudent,
        childRef,
        filter,
        setFilter
    ]
}

export default useAllStudents;
