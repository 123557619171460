import axios from 'axios'
import InputPicker from 'components/Input/InputPicker'
import MyModal from 'components/Modal'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { ScaleLoader } from 'react-spinners'
import { toast } from 'react-toastify'

export default function ImageUploadModal({ label, endURL, objectKey, id, updateHandler }) {
    const history = useHistory()

    const [file, setFile] = useState(false)
    const [isModal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imgKey, setKey1] = useState(Math.random())

    const reset = () => {
        setModal(false)
        setLoading(false)
        setFile(null)
    }

    const submitHandler = () => {
        let token = localStorage.getItem('@token', '');

        if(token && token !== '') {
            if(!file) {
                toast.warn("Please select an image!")
            } else {
                let formData = new FormData()
                formData.append('image', file)
                formData.append('key', objectKey)

                setLoading(true)
                setKey1(Math.random())
                const config = {
                    method: 'post',
                    url: endURL ? `${process.env.REACT_APP_ENDPOINT}/api/${endURL}/${id}` : `${process.env.REACT_APP_ENDPOINT}/api/upload-productkeywords-images/${id}`,
                    headers: { 
                      'Accept': 'application/json', 
                      'Content-Type': 'application/json', 
                      'Authorization': `Bearer ${token}`
                    },
                    data: formData
                };
        
                axios(config)
                    .then(res => {
                        if(res.status === 200) {
                            if(endURL) {
                                updateHandler(res.data.data)
                            } else {
                                updateHandler(id, { [objectKey]: res?.data?.data})
                            }
                            reset()
                        } else {
                            toast.warn(res.data.message || 'Error occured while updating image.')
                            setLoading(false)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        toast.error(
                            err?.response?.data?.message ||
                            'Something went wrong'
                        )
                    })
            }
        } else {
            toast.warn("No authenticated user found. Please login again!")
            history.push("/login")
            reset()
        }
    }

    return (
        <>
            <button onClick={()=>setModal(true)}>Upload</button>

            <MyModal show={isModal} close={reset}>
                <div className="card o-hidden border-0 shadow-lg">
                    <div className="card-body p-0" style={{backgroundColor: "#fff"}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-2">Select Your Image!</h1>
                                    </div>
                                    <hr />
                                    <form className="user">
                                        <div className="form-group">
                                            <InputPicker
                                                fillWidth
                                                key={imgKey}
                                                label={label}
                                                setValue={setFile}
                                                id="inputGroupFile"
                                                name={file ? file.name : 'Choose file'}
                                            />
                                        </div>
                                        <div className="d-flex bd-highlight align-items-center justify-content-center">
                                            {
                                                loading ?
                                                    <a className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight" style={{ opacity: 0.5, color: 'white', width: 160 }}>
                                                        <ScaleLoader color="white" height={15} />
                                                    </a>
                                                :
                                                    <a 
                                                        onClick={submitHandler}
                                                        style={{ color: 'white', width: 160 }} 
                                                        className="btn btn-primary btn-user btn-block forget-btn  flex-shrink-1 bd-highlight cursor"
                                                    >
                                                        Submit
                                                    </a>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MyModal>
        </>    
    )
}
