export const getProductStatus = (status) => {
    if(status === 'Pending') {
        return ["Not Submitted", "Not Submitted", true]
    } else if(status === "Submitted") {
        return ["Pending For Approval From Trainer", "Pending For Approval From Enablers Management", false]
    } else if(status === "Reject by trainer"){
        return ["Rejected by Trainer", "Rejected", false];
    } else if(status === "Reject by admin"){
        return ["Approved by Trainer", "Rejected by Enablers Management", false];
    } else if(status === "Approved by trainer") {
        return ["Approved by Trainer", "Pending For Approval From Enablers Management", false]
    } else if (status === "Approved by admin") {
        return ["Approved", "Approved by Enablers Management", false]
    } else {
        return ["-", "-", false]
    }
}

export const isProductPending = (status) => {
    if(status === 'Pending') {
        return true
    } else if(status === "Submitted") {
        return true
    } else if(status === "Reject by trainer"){
        return false
    } else if(status === "Reject by admin"){
        return false
    } else if(status === "Approved by trainer") {
        return true
    } else if (status === "Approved by admin") {
        return false
    } else {
        return true
    }
}

export const getStudentProductStatusByTrainer = (status) => {
    if(status === 'Pending') {
        return 'pending'
    } else if(status === "Submitted") {
        return 'pending'
    } else if(status === "Reject by trainer"){
        return 'rejected'
    } else if(status === "Reject by admin"){
        return 'approved'
    } else if(status === "Approved by trainer") {
        return 'approved'
    } else if (status === "Approved by admin") {
        return 'approved'
    } else {
        return 'pending'
    }
}

export const getStudentProductStatusByAdmin = (status) => {
    if(status === 'Pending') {
        return 'pending'
    } else if(status === "Submitted") {
        return 'pending'
    } else if(status === "Reject by trainer"){
        return 'rejected'
    } else if(status === "Reject by admin"){
        return 'rejected'
    } else if(status === "Approved by trainer") {
        return 'pending'
    } else if (status === "Approved by admin") {
        return 'approved'
    } else {
        return 'pending'
    }
}